import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Layout, List, Spin } from 'antd';
import Sider from '../../../../components/Sider/Sider';
import { fetchSignificantExposures } from '../../IndustryActions';
import {
  industryIdSelector,
  significantExposuresSelector,
  loadingIndustrySelector,
  loadingSignificantExposuresSelector,
} from '../../IndustrySelectors';
import { countryCodeSelector } from '../../../Account/AccountSelectors';
import { IconSignificantExposures } from '../../../../assets/icons/IndustryFeature';

const { Content } = Layout;

const SignificantExposure = ({
  industryId,
  country,
  loading,
  significantExposureList = [],
  fetchSignificantExposures,
}) => {
  useEffect(() => {
    if (industryId && country) {
      fetchSignificantExposures(industryId, country);
    }
  }, [industryId, country, fetchSignificantExposures]);

  return (
    <Layout>
      {loading ? (
        <Spin className="component-wrapper" />
      ) : (
        <>
          <Sider
            title="Significant Exposures"
            icon={IconSignificantExposures}
            description="This is not a complete and exhaustive list which may be found in businesses falling into this occupation category. It is intended as a general guide only."
          />
          <Content className="component-wrapper">
            <List
              itemLayout="vertical"
              dataSource={significantExposureList}
              renderItem={(item) => (
                <List.Item key={item}>
                  <div>{item}</div>
                </List.Item>
              )}
            />
          </Content>
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  industryId: industryIdSelector(state),
  country: countryCodeSelector(state),
  significantExposureList: significantExposuresSelector(state),
  loading: loadingIndustrySelector(state) || loadingSignificantExposuresSelector(state),
});

const mapDispatchToProps = { fetchSignificantExposures };

export default connect(mapStateToProps, mapDispatchToProps)(SignificantExposure);
