import React, { useEffect } from 'react';
import { Button } from 'antd';
import notes from '../../data/notes';
import lmiLogo from '../../../../../../assets/images/lmi-logo.png';

export default () => {
  useEffect(() => window.print(), []);
  const { type, inputs = [], results = [] } = JSON.parse(sessionStorage.getItem('calculator_printing_items')) || {};
  return (
    <article className="calculator-printing">
      <section>
        <div className="calculator-printing-form">
          <div className="calculator-printing-form-title">Policy with a {type}</div>
          <div className="calculator-printing-form-content">
            <h3>Calculation Inputs</h3>
            {inputs.map((item) => (
              <div key={item.label} className="calculator-printing-form-item">
                <span>{item.label}</span>
                <span>{item.value}</span>
              </div>
            ))}
            <h3>Calculation Results</h3>
            {results.map((item) => (
              <div key={item.label} className="calculator-printing-form-item result">
                <span>{item.label}</span>
                <span>{item.value}</span>
              </div>
            ))}
          </div>
        </div>
        {notes.map((item) => !item.webOnly && <div>{item.content}</div>)}
        <div className="contact-card">
          <img src={lmiLogo} alt="logo" />
          <Button type="link" href={`https://${process.env.REACT_APP_DOMAIN}`} target="_blank">
            {process.env.REACT_APP_DOMAIN}
          </Button>
          <Button type="link" href={`mailto:${process.env.REACT_APP_EMAIL}`}>
            {process.env.REACT_APP_EMAIL}
          </Button>
        </div>
      </section>
    </article>
  );
};
