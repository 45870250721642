import React from 'react';
import { Form, Select } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;
const formItemLayout = { labelCol: { xs: 24, sm: 6, lg: 4 }, wrapperCol: { xs: 24, sm: 18, lg: 20 } };

const INDUSTRY_OPTIONS = [
  { key: 'agricultural', title: 'Agricultural' },
  { key: 'bank', title: 'Bank' },
  { key: 'clubs', title: 'Clubs, Hotels, Motels' },
  { key: 'commercial', title: 'Commercial' },
  { key: 'industrial', title: 'Industrial' },
  { key: 'parking', title: 'Parking' },
  { key: 'residential', title: 'Residential' },
  { key: 'retail', title: 'Retail' },
];

export default ({ onIndustryChange }) => (
  <FormItem label="Industry" {...formItemLayout}>
    <Select size="large" onChange={onIndustryChange}>
      {INDUSTRY_OPTIONS.map((item) => (
        <Option key={item.key}>{item.title}</Option>
      ))}
    </Select>
  </FormItem>
);
