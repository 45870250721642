import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import SearchBox from '../SearchBox/SearchBox';
import { industryInfoSelector, loadingIndustrySelector } from '../../IndustrySelectors';
import { isUnderwriterSelector } from '../../../Account/AccountSelectors';
import './Navigation.scss';

const Navigation = ({ items, selectedKey, industryInfo, loading, underwriter }) => {
  return (
    <div className="industry-navigation">
      <div className="industry-navigation-search">
        {loading ? (
          <h3 className="industry-name">Loading Industry ...</h3>
        ) : (
          industryInfo && (
            <Link to={`/industries/${industryInfo.id}`} className="industry-name">
              {`${industryInfo.code} - ${industryInfo.name}`}
            </Link>
          )
        )}
        <SearchBox />
      </div>
      {items && industryInfo && (
        <Menu
          mode="horizontal"
          selectedKeys={[selectedKey]}
          overflowedIndicator={<Icon type="down" className="industry-navigation-menu-overflow" />}
          className="industry-navigation-menu"
        >
          {items.map((item) => {
            return item.route ? (
              <Menu.Item key={item.route}>
                <Link to={`/industries/${industryInfo.id}/${item.route}`}>
                  <Icon component={item.icon} />
                  {item.title === 'Report' && underwriter ? (
                    <>
                      Underwriter Report <br />
                      <span style={{ fontSize: '0.8em' }}> (coming soon)</span>
                    </>
                  ) : (
                    item.title
                  )}
                </Link>
              </Menu.Item>
            ) : (
              <Menu.Item key={item.url}>
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <Icon component={item.icon} />
                  {item.title}
                </a>
              </Menu.Item>
            );
          })}
        </Menu>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  industryInfo: industryInfoSelector(state),
  loading: loadingIndustrySelector(state),
  underwriter: isUnderwriterSelector(state),
});

export default connect(mapStateToProps)(Navigation);
