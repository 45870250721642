import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { industryNameSelector } from '../../../../../Industry/IndustrySelectors';
import { insuredBusinessNameSelector } from '../../../../ReportSelectors';
import {
  companyLogoSelector,
  dateFormatSelector,
  reportThemeSelector,
  isUnderwriterSelector,
} from '../../../../../Account/AccountSelectors';
import { ThemeContext, TypeContext } from '../../../../ReportContext';
import useTheme from '../../hooks/useTheme';
import Templates from './Templates';

const Cover = ({ reportTheme, ...props }) => {
  const type = useContext(TypeContext);
  const theme = useTheme(ThemeContext);
  const { DefaultCover, MarshCover, AonCover } = Templates;

  if (reportTheme.label === 'Marsh theme') {
    return <MarshCover theme={theme} {...props} />;
  }
  if (reportTheme.label === 'AON theme') {
    return <AonCover theme={theme} {...props} />;
  }
  return <DefaultCover theme={theme} type={type} {...props} />;
};

const mapStateToProps = (state) => ({
  companyLogo: companyLogoSelector(state),
  reportTheme: reportThemeSelector(state),
  industryName: industryNameSelector(state),
  insuredBusinessName: insuredBusinessNameSelector(state),
  dateFormat: dateFormatSelector(state),
  underwriter: isUnderwriterSelector(state),
});

export default connect(mapStateToProps)(Cover);
