import axios from 'axios';

export const REQUEST_QUIZZES = 'quiz/REQUEST_QUIZZES';
export const RECEIVE_QUIZZES = 'quiz/RECEIVE_QUIZZES';

export const REQUEST_QUIZ_QUESTIONS = 'quiz/REQUEST_QUIZ_QUESTIONS';
export const RECEIVE_QUIZ_QUESTIONS = 'quiz/RECEIVE_QUIZ_QUESTIONS';

export const REQUEST_QUIZ_ANSWERS = 'quiz/REQUEST_QUIZ_ANSWERS';
export const RECEIVE_QUIZ_ANSWERS = 'quiz/RECEIVE_QUIZ_ANSWERS';

const API_BASE = process.env.REACT_APP_API_BASE;
const PAGE_SIZE = 100;

const requestQuizzes = () => ({ type: REQUEST_QUIZZES });
const receiveQuizzes = (data) => ({ type: RECEIVE_QUIZZES, quizzes: data });

const requestQuizQuestions = () => ({ type: REQUEST_QUIZ_QUESTIONS });
const receiveQuizQuestions = (data) => ({ type: RECEIVE_QUIZ_QUESTIONS, quiz: data });

const requestQuizAnswers = () => ({ type: REQUEST_QUIZ_ANSWERS });
const receiveQuizAnswers = (data) => ({ type: RECEIVE_QUIZ_ANSWERS, answer: data });

export const fetchQuizzes = (params) => async (dispatch) => {
  dispatch(requestQuizzes());
  const url = `${API_BASE}user/quizzes`;
  try {
    const res = await axios.get(url, { params: { page_size: PAGE_SIZE, ...params } });
    dispatch(receiveQuizzes(res.data));
  } catch (err) {
    dispatch(receiveQuizzes());
  }
};

export const fetchQuizQuestions = (data) => async (dispatch) => {
  dispatch(requestQuizQuestions());
  const url = `${API_BASE}user/quizzes`;

  try {
    const res = await axios.post(url, data);
    dispatch(receiveQuizQuestions(res.data));
  } catch (err) {
    dispatch(receiveQuizQuestions());
  }
};

export const submitAnswers = (quizId, data) => async (dispatch) => {
  dispatch(requestQuizAnswers());
  const url = `${API_BASE}user/quizzes/${quizId}`;
  try {
    const res = await axios.post(url, data);
    dispatch(receiveQuizAnswers(res.data));
  } catch (err) {
    dispatch(receiveQuizAnswers());
  }
};
