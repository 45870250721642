import CoverSilicaAsARisk from './cover-silica-as-a-risk.jpg';
import CoverMouldInTheClaimsEnvironment from './cover-mould-in-the-claims-environment.jpg';
import CoverOccupationalHealthAndSafety from './cover-occupational-health-and-safety.jpg';
import CoverKitchenExhaustSystems from './cover-kitchen-exhaust-systems.jpg';
import CoverDrones from './cover-drones.jpg';
import CoverFirewallBasics from './cover-firewall-basics.jpg';
import CoverNoiseInTheWorkplace from './cover-noise-in-the-workplace.jpg';
import CoverFlood from './cover-flood.jpg';
import CoverInsuranceFraudInvestigation from './cover-insurance-fraud-investigation.jpg';
import CoverPhysicalSecuritySystems from './cover-physical-security-systems.jpg';
import CoverErgonomicDesignOfWorkstations from './cover-ergonomic-design-of-workstations.jpg';
import CoverShippingContainers from './cover-shipping-containers.jpg';
import CoverCraneHealthAndSafety from './cover-crane-health-and-safety.jpg';
import CoverArsonInvestigation from './cover-arson-investigation.jpg';
import CoverAutomaticSprinklerSystems from './cover-automatic-sprinkler-systems.jpg';
import CoverRemovalOfDebris from './cover-removal-of-debris.jpg';
import CoverAsbestosBuildingProducts from './cover-asbestos-building-products.jpg';
import CoverSandwichPanelProducts from './cover-sandwich-panel-products.jpg';
import CoverPortableFireExtinguishers from './cover-portable-fire-extinguishers.jpg';
import CoverInsuringConcreteSlabs from './cover-insuring-concrete-slabs.jpg';
import CoverLithiumBatteries from './cover-lithium-batteries.jpg';

export default {
  'silica as a risk': CoverSilicaAsARisk,
  'mould in the claims environment': CoverMouldInTheClaimsEnvironment,
  'occupational health and safety': CoverOccupationalHealthAndSafety,
  'kitchen exhaust systems': CoverKitchenExhaustSystems,
  drones: CoverDrones,
  'firewall basics': CoverFirewallBasics,
  'noise in the workplace': CoverNoiseInTheWorkplace,
  flood: CoverFlood,
  'insurance fraud investigation': CoverInsuranceFraudInvestigation,
  'physical security systems': CoverPhysicalSecuritySystems,
  'ergonomic design of workstations': CoverErgonomicDesignOfWorkstations,
  'shipping containers': CoverShippingContainers,
  'crane health and safety': CoverCraneHealthAndSafety,
  'arson investigation': CoverArsonInvestigation,
  'automatic sprinkler systems': CoverAutomaticSprinklerSystems,
  'removal of debris': CoverRemovalOfDebris,
  'asbestos building products': CoverAsbestosBuildingProducts,
  'sandwich panel products': CoverSandwichPanelProducts,
  'portable fire extinguishers': CoverPortableFireExtinguishers,
  'insuring concrete slabs': CoverInsuringConcreteSlabs,
  'lithium batteries': CoverLithiumBatteries,
};
