import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card } from 'antd';
import Button from '../../components/Button/Button';
import Layout from '../../components/Layout/Layout';
import SearchBox from '../Industry/components/SearchBox/SearchBox';
import { trialUserSelector } from '../Account/AccountSelectors';

import feature1 from './assets/testing.svg';
import feature2 from './assets/streaming.svg';
import feature3 from './assets/task.svg';
import feature4 from './assets/competition.svg';

import './TestYourKnowledge.scss';

const tykSteps = [
  {
    key: 1,
    step: 'STEP 1',
    heading: 'Select the Industry',
    image: feature1,
    classes: 'tyk-steps-step arrow-next',
    description:
      'Select from over 7,000 industries and business activities, RiskCoach is your one stop shop for risk knowledge.',
  },
  {
    key: 2,
    step: 'STEP 2',
    heading: 'Get the Knowledge and Start',
    classes: 'tyk-steps-step arrow-next',
    image: feature2,
    description: 'Immerse yourself in our detailed industry write ups to better prepare yourself.',
  },
  {
    key: 3,
    step: 'STEP 3',
    heading: 'Answer the Quick Quizzes',
    classes: 'tyk-steps-step arrow-next',
    image: feature3,
    description:
      'Continue your professional development and put your knowledge to the test. Evidence of your completed tests are shown on your CPD certificate ',
  },
  {
    key: 4,
    step: 'STEP 4',
    heading: 'Earn CIP/CPD Points',
    classes: 'tyk-steps-step',
    image: feature4,
    description:
      'On completion, you will earn CIP/CPD Points. Claim up to 15 points per annum with ANZIIF and 20 points every 2 years with NIBA. Simply download your certificate.',
  },
];

const TestYourKnowledge = ({ trialUser }) => (
  <Layout showMenu>
    <div className="component-wrapper tyk-browse">
      <h1>Test Your Industry Knowledge</h1>
      <br />
      <section className="tyk-description">
        <p>
          Welcome to the <b>TEST YOUR KNOWLEDGE</b> page. These Quick Quizzes are designed to provide you with an
          understanding of the key risks and hazards for the selected Industry. Gain knowledge by reading the content
          within this industry write up. Afterwards complete the revision questions that will both test and reinforce
          your understanding of the areas covered.
        </p>
      </section>
      <section className="tyk-industry-search">
        <h2>Industry Search</h2>
        <p className="instruction">Type a keyword or ANZSIC code to selecct the industry</p>
        <SearchBox size="large" mode="vertical" trialUser={trialUser} withQuizOnly />
      </section>
      <section className="tyk-steps">
        <Row type="flex" justify="space-around">
          {tykSteps.map(({ key, heading, image, description, step, classes }) => (
            <Col xs={22} sm={12} xl={6} key={key}>
              <Card className={classes}>
                <div className="tyk-steps-step-sequence">{step}</div>
                <img className="tyk-steps-step-image" src={image} alt={heading} title={heading} />
                <h4>{heading}</h4>
                <p>{description}</p>
              </Card>
            </Col>
          ))}
        </Row>
      </section>
      <section className="tyk-industry-request">
        <Card className="tyk-industry-request-card">
          <div className="request-new-industry-knowledge">
            <h4>Request a test for your industry knowledge</h4>
            <p>We invite you to suggest any industry you&apos;d like to see included.</p>
            <Button type="primary" icon="mail" href={`mailto:${process.env.REACT_APP_EMAIL}`}>
              Request a Test
            </Button>
          </div>
        </Card>
      </section>
    </div>
  </Layout>
);

const mapStateToProps = (state) => ({
  trialUser: trialUserSelector(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TestYourKnowledge);
