import React from 'react';
import { connect } from 'react-redux';
import Quiz from '../../../../../Quiz/Quiz';
import { fetchQuizQuestions } from '../../../../../Quiz/QuizActions';
import { courseIdSelector } from '../../../../CourseSelectors';
import { countryCodeSelector } from '../../../../../Account/AccountSelectors';

const CourseQuiz = ({ courseId, countryCode, fetchQuizQuestions }) => {
  const fetchQuestions = () => {
    if (courseId) {
      const data = { short_course_id: courseId, country: countryCode };
      fetchQuizQuestions(data);
    }
  };

  return (
    <div className="course-detail-content card">
      <div className="course-detail-title">
        <h2>Test Your Knowledge</h2>
      </div>
      <Quiz fetchQuestions={fetchQuestions} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  courseId: courseIdSelector(state),
  countryCode: countryCodeSelector(state),
});

const mapDispatchToProps = { fetchQuizQuestions };

export default connect(mapStateToProps, mapDispatchToProps)(CourseQuiz);
