import React, { useState } from 'react';
import { Form, Select } from 'antd';
import _ from 'lodash';
import regions from '../../../../data/regions.json';

const FormItem = Form.Item;
const { Option } = Select;
const formItemLayout = { labelCol: { xs: 24, sm: 6, lg: 4 }, wrapperCol: { xs: 24, sm: 18, lg: 20 } };

export default ({ onLocationChange }) => {
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);

  return (
    <>
      <FormItem label="Country" {...formItemLayout}>
        <Select size="large" onChange={(value) => setCountry(_.groupBy(regions[value], 'admin'))}>
          {_.keys(regions).map((item) => (
            <Option key={item}>{item}</Option>
          ))}
        </Select>
      </FormItem>
      {country && (
        <FormItem label="State" {...formItemLayout}>
          <Select size="large" onChange={(value) => setState(country[value])}>
            {_.keys(country).map((item) => (
              <Option key={item || 'Not Specified'}>{item || 'Not Specified'}</Option>
            ))}
          </Select>
        </FormItem>
      )}
      {state && (
        <FormItem label="Region" {...formItemLayout}>
          <Select size="large" onChange={onLocationChange}>
            {state.map((item) => (
              <Option key={item.city}>{item.city}</Option>
            ))}
          </Select>
        </FormItem>
      )}
    </>
  );
};
