export default (address) => {
  if (address) {
    const suiteStr = address.suite || '';
    const streetStr = address.street || '';
    const suburbStr = address.suburb ? `${address.suburb} ` : '';
    const stateStr = address.state ? `${address.state} ` : '';
    const postcodeStr = address.postcode ? `${address.postcode} ` : '';
    const middleStr = `${suburbStr}${stateStr}${postcodeStr}`;
    const countryStr = address.country ? address.country.name : '';
    return `${suiteStr ? `${suiteStr}, ` : ''}${streetStr ? `${streetStr}, ` : ''}${
      middleStr ? `${middleStr.trim()}, ` : ''
    }${countryStr}`;
  }
  return '';
};
