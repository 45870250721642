import { LOGIN_REQUEST, LOGIN_RESETTING } from './LoginActions';

const initialState = {
  loginRequest: false,
  isResetting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, loginRequest: action.loginRequest };
    case LOGIN_RESETTING:
      return { ...state, isResetting: action.isResetting };
    default:
      return state;
  }
};
