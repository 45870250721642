import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect, Link } from 'react-router-dom';
import { Layout, Spin, Menu } from 'antd';
import _ from 'lodash';
import Sider from '../../../../components/Sider/Sider';
import Button from '../../../../components/Button/Button';
import Card from '../../components/Card/Card';
import CourseGuide from './scenes/CourseGuide/CourseGuide';
import CourseContent from './scenes/CourseContent/CourseContent';
import CourseQuiz from './scenes/CourseQuiz/CourseQuiz';
import { fetchCourse, fetchUnit } from '../../CourseActions';
import {
  courseIdSelector,
  courseNameSelector,
  courseCoverSelector,
  courseUnitsSelector,
  loadingCourseSelector,
} from '../../CourseSelectors';
import * as icons from '../../assets/icons';
import './CourseDetail.scss';

const { Content } = Layout;

const routes = Object.freeze([
  { route: 'guide', name: 'Course Guide', icon: icons.IconCourseOverview },
  { route: 'content', name: 'Start this Course', icon: icons.IconCourseContent },
]);

const CourseDetail = ({
  match,
  history,
  location,
  loading,
  courseId,
  courseName,
  courseCover,
  courseUnits = [],
  fetchCourse,
  fetchUnit,
}) => {
  const [selectedUnit, setSelectedUnit] = useState();

  useEffect(() => {
    setSelectedUnit();
    fetchCourse(match.params.id);
  }, [match.params.id, fetchCourse]);

  useEffect(() => {
    if (courseUnits.length > 0) {
      setSelectedUnit(courseUnits[0].id);
    }
  }, [courseUnits]);

  useEffect(() => {
    if (courseId && selectedUnit) {
      fetchUnit(courseId, selectedUnit);
    }
  }, [courseId, selectedUnit, fetchUnit]);

  const selectedKey = location.pathname.split('/').pop();
  const showPrev = _.findIndex(courseUnits, ['id', selectedUnit]) > 0;
  const showNext = _.findIndex(courseUnits, ['id', selectedUnit]) < courseUnits.length - 1;

  const prevUnit = () => {
    const index = _.findIndex(courseUnits, ['id', selectedUnit]);
    const prevIndex = Math.max(index - 1, 0);
    setSelectedUnit(courseUnits[prevIndex].id);
  };

  const nextUnit = () => {
    const index = _.findIndex(courseUnits, ['id', selectedUnit]);
    const nextIndex = Math.min(index + 1, courseUnits.length - 1);
    setSelectedUnit(courseUnits[nextIndex].id);
  };

  const onStart = () => {
    history.push(`${match.url}/content`);
  };

  const onTest = () => {
    history.push(`${match.url}/quiz`);
  };

  const props = { prev: prevUnit, next: nextUnit, showPrev, showNext, onTest };

  return (
    <Layout>
      <Sider collapsible menuItems={routes} selectedMenuItem={selectedKey}>
        {selectedKey === 'content' && (
          <Menu selectedKeys={[selectedUnit]} onClick={({ key }) => setSelectedUnit(key)}>
            {courseUnits.map((item) => (
              <Menu.Item key={item.id}>{item.title}</Menu.Item>
            ))}
          </Menu>
        )}
      </Sider>
      <Content className="component-wrapper course-detail">
        {loading ? (
          <Spin />
        ) : (
          <>
            <Card title={courseName} cover={courseCover}>
              <div className="course-detail-btns">
                <Link to="/courses/browse">
                  <Button>See more courses</Button>
                </Link>
                <Button type="primary" onClick={selectedKey === 'content' ? onTest : onStart}>
                  {selectedKey === 'content' ? 'Test your knowledge' : 'Start this course'}
                </Button>
              </div>
            </Card>
            <Switch>
              <Route exact path={match.url} render={() => <Redirect to={`${match.url}/${routes[0].route}`} />} />
              <Route exact path={`${match.url}/guide`} component={CourseGuide} />
              <Route exact path={`${match.url}/content`} render={() => <CourseContent {...props} />} />
              <Route exact path={`${match.url}/quiz`} component={CourseQuiz} />
              <Redirect to="/not-found" />
            </Switch>
          </>
        )}
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  courseId: courseIdSelector(state),
  courseName: courseNameSelector(state),
  courseCover: courseCoverSelector(state),
  courseUnits: courseUnitsSelector(state),
  loading: loadingCourseSelector(state),
});

const mapDispatchToProps = { fetchCourse, fetchUnit };

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CourseDetail);
