import React from 'react';
import { Row, Col, Typography } from 'antd';
import { connect } from 'react-redux';
import Button from '../../../../components/Button/Button';
import Icon from '../../../../components/Icon/Icon';
import { ssoTokenSelector } from '../../../Account/AccountSelectors';
import './KnowledgeCard.scss';
import * as icons from './assets/icons';

const { Paragraph } = Typography;

const LMI_DEFAULT_KEY = process.env.REACT_APP_LMI_DEAFULT_KEY;
const SUPPORT_SERVICES_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://staging.lmisupportservices.com'
    : 'https://www.lmisupportservices.com';

const KnowledgeCard = ({ ssoToken, country }) => {
  // SSO token is missing key value, so need to inject it for now 06/10/22.
  const keyInjectedSsoToken = ssoToken.replace('key=', `key=${LMI_DEFAULT_KEY}`);

  const data = [
    {
      title: 'Help with Claims',
      description:
        'LMI Group are experts in Insurance Claims providing loss management, risk assessment and technical advice to the business and insurance communities.',
      icon: icons.IconHelpWithClaims,
      link: 'https://www.lmigroup.io/contact-us/',
    },
    {
      title: 'Support Services',
      description:
        'Our support services facility brings together many of our knowledge sources to provide you with a simple, searchable resource in the one handy location!',
      icon: icons.IconKnowledgeCentre,
      link: ssoToken ? `${SUPPORT_SERVICES_URL}?${keyInjectedSsoToken}` : SUPPORT_SERVICES_URL,
    },
  ];

  // should return a single row for UK users
  if (country === 'gb') {
    return (
      <Row gutter={20} type="flex" style={{ height: '100%' }}>
        <Col key={data[1].title} xs={24} sm={12} md={24} className="dashboard-item">
          <div className="dashboard-knowledge-card single card">
            <Icon component={data[1].icon} size={50} className="dashboard-knowledge-card-icon" />
            <div className="dashboard-knowledge-card-info">
              <h2>{data[1].title}</h2>
              <Paragraph ellipsis={{ rows: 4 }}>{data[1].description}</Paragraph>
              <Button type="link" href={data[1].link} target="_blank">
                Find out more
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={20} type="flex" style={{ height: '100%' }}>
      {data.map((item) => (
        <Col key={item.title} xs={24} sm={12} md={24} className="dashboard-item">
          <div className="dashboard-knowledge-card card">
            <Icon component={item.icon} size={50} className="dashboard-knowledge-card-icon" />
            <div className="dashboard-knowledge-card-info">
              <h2>{item.title}</h2>
              <Paragraph ellipsis={{ rows: 4 }}>{item.description}</Paragraph>
              <Button type="link" href={item.link} target="_blank">
                Find out more
              </Button>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

const mapStateToProps = (state) => ({
  ssoToken: ssoTokenSelector(state),
});

export default connect(mapStateToProps, null)(KnowledgeCard);
