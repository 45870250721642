/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import axios from 'axios';
import { updatePoints } from '../../scenes/Point/PointActions';
import { userIdSelector } from '../../scenes/Account/AccountSelectors';
import { industryIdSelector, industryNameSelector } from '../../scenes/Industry/IndustrySelectors';
import { courseNameSelector } from '../../scenes/Course/CourseSelectors';

const API_BASE = process.env.REACT_APP_API_BASE;
const GA3_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
const GA4_MEASUREMENT_ID = process.env.REACT_APP_GA4_MEASUREMENT_ID;

const sendEventStatsToBackend = async (data) => {
  try {
    await axios.post(`${API_BASE}user/userstats`, data);
  } catch (error) {
    console.error('Error sending stats to backend:', error);
  }
};

const Tracker = ({ location, userId, industryId, industryName, courseName, updatePoints }) => {
  ReactGA.initialize(GA3_TRACKING_ID, {
    testMode: process.env.NODE_ENV === 'test',
    gaOptions: {
      cookieFlags: 'secure;samesite=none',
    },
  });

  ReactGA4.initialize(GA4_MEASUREMENT_ID, {
    gaOptions: {
      testMode: process.env.NODE_ENV === 'test',
      cookieFlags: 'secure;samesite=none',
      userId,
      clientId: userId,
    },
  });

  useEffect(() => {
    if (userId) {
      ReactGA.set({ dimension1: userId });
      ReactGA4.gtag('set', 'user_properties', { userId });
    }
  }, [userId]);

  useEffect(() => {
    const loggedIn = localStorage.getItem('token');
    if (!loggedIn) {
      return;
    }
    const page = location.pathname;
    updatePoints({ page, industry: industryId });
    sendEventStatsToBackend({ Projectid: 1, Typename: page });

    if (GA3_TRACKING_ID) {
      ReactGA.set({ page });
      ReactGA.pageview(page);
    }
    if (GA4_MEASUREMENT_ID) {
      ReactGA4.send({
        hitType: 'pageview',
        page,
        title: page,
      });
    }
  }, [location.pathname, industryId, updatePoints]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const linkgenToken = localStorage.getItem('linkgen_token');
    const eventParam = {
      category: 'user',
      action: 'login',
    };
    if (linkgenToken) {
      sendEventStatsToBackend({ Projectid: 1, Typename: 'linkgen' });
      ReactGA.event({
        ...eventParam,
        label: 'linkgen',
      });
      ReactGA4.event({
        ...eventParam,
        label: 'linkgen',
      });
    } else if (token) {
      sendEventStatsToBackend({ Projectid: 1, Typename: 'credentials' });
      ReactGA.event({
        ...eventParam,
        label: 'credentials',
      });
      ReactGA4.event({
        ...eventParam,
        label: 'credentials',
      });
    }
  }, []);

  useEffect(() => {
    if (industryName) {
      const eventParam = {
        category: 'user',
        action: 'viewed industry',
        label: industryName,
      };
      ReactGA.event(eventParam);
      ReactGA4.event(eventParam);
    }
  }, [industryName]);

  useEffect(() => {
    if (courseName) {
      const eventParam = {
        category: 'user',
        action: 'viewed short course',
        label: courseName,
      };
      ReactGA.event(eventParam);
      ReactGA4.event(eventParam);
    }
  }, [courseName]);

  return null;
};

export const useAnalyticsEventTracker = (category = 'User') => {
  const eventTracker = (action, label) => {
    ReactGA.event({ category, action, label });
    ReactGA4.event({
      category,
      action,
      label,
    });
  };
  return eventTracker;
};

const mapStateToProps = (state) => ({
  userId: userIdSelector(state),
  industryId: industryIdSelector(state),
  industryName: industryNameSelector(state),
  courseName: courseNameSelector(state),
});

const mapDispatchToProps = { updatePoints };

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Tracker);
