import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import { Icon, Progress, Button } from 'antd';
import { renderColor } from '../../../Industry/utils';
import Modal from '../../../../components/Modal/Modal';
import HazardIndexEdit from '../HazardIndexEdit/HazardIndexEdit';
import UnderwriterEdit from '../UnderwriterEdit/UnderwriterEdit';
import { IconComment, IconEmptyComment, IconEmptyCommentWhite } from '../../assets/icons';
import { hazardIndexCustomizationSelector, underwriterHazardIndexCustomizationSelector } from '../../ReportSelectors';
import './HazardIndexItem.scss';

const HazardIndexItem = ({ data, customization, underwriterCustomization, index, appearance, underwriter }) => {
  let comment;
  let checklistAnswers;
  const commentButtonColor = {
    active: '#007BC3',
    inActive: '#9C9C9C',
  };

  if (underwriter) {
    const foundClass = underwriterCustomization.customizations.find(
      (selectedClasses) => selectedClasses?.id === Number(data.id)
    );
    comment = foundClass?.comments ? foundClass.comments : undefined;
    checklistAnswers = foundClass?.checklist ? foundClass.checklist.length : undefined;
  } else {
    comment = customization.comments
      ? customization.comments.find((comment) => comment.class === Number(data.id))?.comment
      : undefined;
  }

  const HarzardIndexItem = (
    <div className="hazard-index-item">
      <div className="hazard-index-item-info">
        {appearance === 'tile' && (
          <Progress
            type="circle"
            format={(rating) => `${rating}%`}
            status="normal"
            percent={data.rating}
            strokeColor={renderColor(data.rating)}
            className="hazard-index-item-rating"
            width={100}
          />
        )}
        <h3 className="hazard-index-item-title">
          <Icon component={data.icon} />
          <span className="hazard-index-item-name">{data.name}</span>
        </h3>
        {appearance === 'row' && (
          <div className="sider-hazard-index-item-rating" style={{ color: renderColor(data.rating) }}>
            {`${data.rating}%`}
          </div>
        )}
      </div>
      <div className="hazard-index-item-edit">
        <Modal
          toggle={(show) => {
            if (appearance === 'row') {
              return (
                <Icon
                  component={comment || checklistAnswers ? IconComment : IconEmptyComment}
                  className="comment-button"
                  onClick={(e) => {
                    show();
                    e.stopPropagation();
                  }}
                />
              );
            }
            return (
              <Button
                className="tile-comment-button"
                size="small"
                style={{
                  backgroundColor:
                    comment || checklistAnswers ? commentButtonColor.active : commentButtonColor.inActive,
                }}
                onClick={(e) => {
                  show();
                  e.stopPropagation();
                }}
              >
                <Icon component={IconEmptyCommentWhite} /> Comments
              </Button>
            );
          }}
          width="70vw"
        >
          {(hide) =>
            underwriter ? (
              <UnderwriterEdit hazardIndex={data} close={hide} comment={comment} underwriter />
            ) : (
              <HazardIndexEdit hazardIndex={data} close={hide} comment={comment} />
            )
          }
        </Modal>
      </div>
    </div>
  );

  const DraggableHazardIndexItem = (
    <Draggable draggableId={`${data.id}`} index={index}>
      {(provided, snapshot) => (
        <li
          className={classNames(
            'hazard-index-item-container',
            { 'is-dragging': snapshot.isDragging },
            { 'appearance-tile': appearance === 'tile' },
            { 'appearance-row': appearance === 'row' }
          )}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {HarzardIndexItem}
        </li>
      )}
    </Draggable>
  );

  if (underwriter) return HarzardIndexItem;
  return DraggableHazardIndexItem;
};

const mapStateToProps = (state) => ({
  customization: hazardIndexCustomizationSelector(state),
  underwriterCustomization: underwriterHazardIndexCustomizationSelector(state),
});

export default connect(mapStateToProps)(HazardIndexItem);
