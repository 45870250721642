import React, { useState } from 'react';
import { Row, Col, Form, Select, Input } from 'antd';
import _ from 'lodash';
import fencingOptions from '../../../../data/fencingCarparks.json';

const FormItem = Form.Item;
const { Option } = Select;

export default ({ fencingCarparkId, onFencingCarparksChange }) => {
  const [fencing, setFencing] = useState(null);

  return (
    <Row gutter={20}>
      <Col xs={24} sm={8}>
        <FormItem label="Fencing/Carparks">
          <Select
            size="large"
            onChange={(value) => {
              onFencingCarparksChange(fencingCarparkId, 'fencing', value);
              setFencing(value);
            }}
          >
            {_.keys(fencingOptions).map((item) => (
              <Option key={item}>{item}</Option>
            ))}
          </Select>
        </FormItem>
      </Col>
      <Col xs={24} sm={8}>
        <FormItem label="Material">
          <Select size="large" onChange={(value) => onFencingCarparksChange(fencingCarparkId, 'material', value)}>
            {fencing && fencingOptions[fencing].map((item) => <Option key={item}>{item}</Option>)}
          </Select>
        </FormItem>
      </Col>
      <Col xs={24} sm={8}>
        <FormItem
          label={
            <span>
              Gross Area (m<sup>2</sup>)
            </span>
          }
        >
          <Input
            size="large"
            suffix={
              <span>
                m<sup>2</sup>
              </span>
            }
            onChange={(e) => onFencingCarparksChange(fencingCarparkId, 'area', e.target.value)}
          />
        </FormItem>
      </Col>
    </Row>
  );
};
