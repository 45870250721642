import React from 'react';
import Item from '../Item/Item';
import Groups from '../Groups/Groups';

export default ({ list = [], onCheck, onGroupCheck }) => {
  return (
    <div className="report-list">
      <ul>
        {list.map((item) =>
          item?.children?.length ? (
            <Groups key={item.id} item={item} onCheck={onCheck} onGroupCheck={onGroupCheck} />
          ) : (
            <Item
              key={item.id}
              item={item}
              checked={list
                .filter((item) => item.checked)
                .map((item) => item.id)
                .includes(item.id)}
              onCheck={(e) => onCheck(e.target.checked, item.id)}
            />
          )
        )}
      </ul>
    </div>
  );
};
