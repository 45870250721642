import {
  REQUEST_RISK_DESCRIPTION,
  RECEIVE_RISK_DESCRIPTION,
  REQUEST_RISK_ASSESSMENT,
  RECEIVE_RISK_ASSESSMENT,
  REQUEST_INSURANCE_CLASSES,
  RECEIVE_INSURANCE_CLASSES,
  RECEIVE_AVAILABLE_RISK_ASSESSMENT,
  REQUEST_AVAILABLE_RISK_ASSESSMENT,
} from './DocumentActions';

const initialState = {
  loadingRiskDescription: false,
  loadingRiskAssessment: false,
  loadingInsuranceClasses: false,
  loadingAvailableRiskAssessment: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_RISK_DESCRIPTION:
      return { ...state, loadingRiskDescription: true, riskDescription: undefined };
    case RECEIVE_RISK_DESCRIPTION:
      return { ...state, loadingRiskDescription: false, riskDescription: action.riskDescription };
    case REQUEST_RISK_ASSESSMENT:
      return { ...state, loadingRiskAssessment: true, riskAssessment: undefined };
    case RECEIVE_RISK_ASSESSMENT:
      return { ...state, loadingRiskAssessment: false, riskAssessment: action.riskAssessment };
    case REQUEST_INSURANCE_CLASSES:
      return { ...state, loadingInsuranceClasses: true };
    case RECEIVE_INSURANCE_CLASSES:
      return { ...state, loadingInsuranceClasses: false, insuranceClassList: action.insuranceClassList };
    case REQUEST_AVAILABLE_RISK_ASSESSMENT:
      return { ...state, loadingAvailableRiskAssessment: true };
    case RECEIVE_AVAILABLE_RISK_ASSESSMENT:
      return {
        ...state,
        loadingAvailableRiskAssessment: false,
        availableRiskAssessment: action.availableRiskAssessment,
      };
    default:
      return state;
  }
};
