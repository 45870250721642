import React from 'react';
import moment from 'moment';
import { Page } from '../../../../../../../../components/Print/Print';
import './AonCover.scss';
import poweredByLogo from '../../../../assets/powered-by-lmi.png';

const AonCover = ({ theme, insuredBusinessName, industryName, date, dateFormat }) => {
  return (
    <Page>
      <div className="aon-report-preview-cover">
        <div className="aon-report-preview-cover-left">
          <div className="company-logo" />
        </div>
        <div className="aon-report-preview-cover-right">
          <h1
            className="aon-report-title"
            style={{ fontFamily: theme.headings.h1.font, color: theme.headings.h1.color }}
          >
            Insurance Risk Report
          </h1>
          <h2
            className="insured-business-name"
            style={{ color: theme.headings.h2.color, fontFamily: theme.headings.h2.font }}
          >
            {insuredBusinessName}
          </h2>
          <h2
            className="aon-report-title"
            style={{ fontFamily: theme.headings.h2.font, color: theme.headings.h2.color }}
          >
            {industryName}
          </h2>
          <div className="date-issued" style={{ fontFamily: theme.body.font }}>
            {`Date issued:  ${moment(date).format('DD MMMM YYYY')}`}
          </div>
          <div className="powered-by-container">
            <img src={poweredByLogo} height={60} alt="lmi-logo" />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default AonCover;
