import React from 'react';
import { Spin } from 'antd';
import './Card.scss';

export default ({ children, title, loading, edit, footer }) => (
  <div className="account-card card">
    <div className="account-card-header">
      <h3 className="account-card-header-title">{title}</h3>
      {edit}
    </div>
    <Spin spinning={!!loading}>
      <div className="account-card-body">{children}</div>
    </Spin>
    {footer && <div className="account-card-footer">{footer}</div>}
  </div>
);
