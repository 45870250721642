import React from 'react';
import { connect } from 'react-redux';
import { Avatar, Icon } from 'antd';
import { Heading, Content } from '../ReportComponent/ReportComponent';
import { sectionsSelector, reportUserProfileSelector } from '../../../../ReportSelectors';
import { isUnderwriterSelector } from '../../../../../Account/AccountSelectors';
import { ThemeContext } from '../../../../ReportContext';
import useTheme from '../../hooks/useTheme';
import './UserProfile.scss';

const UserProfile = ({ sections, userProfile, underwriter }) => {
  const theme = useTheme(ThemeContext);
  const style = { color: theme.headings.h1.color };
  return userProfile ? (
    <section>
      <Heading seq={sections['user-profile'].seq} anchor="user-profile">
        {underwriter ? 'Underwriter Details' : 'Meet Your Advisor'}
      </Heading>
      <Content printGap="medium">
        <div className="report-preview-user-profile">
          <Avatar icon="user" size={200} src={userProfile.avatar} />
          <div>
            <h3>{userProfile.person_name}</h3>
            <em>{userProfile.job_title}</em>
            <h4>{userProfile.company}</h4>
            <ul>
              <li>
                <Icon type="phone" theme="filled" style={style} />
                <div>{userProfile.phone}</div>
              </li>
              <li>
                <Icon type="mobile" theme="filled" style={style} />
                <div>{userProfile.mobile}</div>
              </li>
              <li>
                <Icon type="mail" theme="filled" style={style} />
                <div>{userProfile.email}</div>
              </li>
              <li>
                <Icon type="environment" theme="filled" style={style} />
                <div>{userProfile.addressStr}</div>
              </li>
            </ul>
          </div>
        </div>
        {userProfile.description && <div dangerouslySetInnerHTML={{ __html: userProfile.description }} />}
      </Content>
    </section>
  ) : null;
};

const mapStateToProps = (state) => ({
  sections: sectionsSelector(state),
  userProfile: reportUserProfileSelector(state),
  underwriter: isUnderwriterSelector(state),
});

export default connect(mapStateToProps)(UserProfile);
