export const COLOR_RATING_HIGH = '#BF0B0B';
export const COLOR_RATING_MEDIUM = '#E26131';
export const COLOR_RATING_LOW = '#FFE135';

export default (rating) => {
  if (rating > 60) return COLOR_RATING_HIGH;
  if (rating > 25) return COLOR_RATING_MEDIUM;
  return COLOR_RATING_LOW;
};

export const renderChartColor = (rating, grade) => {
  if (grade === 'medium' && rating > 60) {
    return COLOR_RATING_MEDIUM;
  }
  if (rating > 60) return COLOR_RATING_HIGH;
  if (rating > 25) return COLOR_RATING_MEDIUM;
  return COLOR_RATING_LOW;
};

export const getChartOffset = (rating, gradientIndex) => {
  if (rating > 60 && gradientIndex === 2) return 0;
  if (rating > 60 && gradientIndex === 1) return (rating - 50) / 100;
  if (rating > 60 && gradientIndex === 0) return 1;
  if (rating > 25 && gradientIndex === 2) return 0;
  if (rating > 25 && gradientIndex === 1) return 0;
  if (rating > 25 && gradientIndex === 0) return 1 - (rating - 25) / 100;
  return 1;
};
