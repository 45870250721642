import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Layout, Spin } from 'antd';
import _ from 'lodash';
import Sider from '../../../../components/Sider/Sider';
import Table from '../../../../components/Table/Table';
import List from './components/List/List';
import { fetchEndorsements } from '../../IndustryActions';
import {
  industryIdSelector,
  endorsementsSelector,
  loadingIndustrySelector,
  loadingEndorsementsSelector,
} from '../../IndustrySelectors';

const { Content } = Layout;
const columns = [
  { key: 'code', title: 'Code', dataIndex: 'code' },
  { key: 'heading', title: 'Heading', dataIndex: 'heading' },
  { key: 'endorsement', title: 'Endorsement', dataIndex: 'text', width: '40%' },
  { key: 'explanation', title: 'Explanation', dataIndex: 'explanation', width: '50%' },
];

const Endorsement = ({ industryId, loading, endorsementList = [], fetchEndorsements }) => {
  const [selectedKey, setSelectedKey] = useState();
  const [mode, setMode] = useState('table');

  useEffect(() => {
    if (industryId) {
      fetchEndorsements(industryId);
    }
  }, [industryId, fetchEndorsements]);

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth <= 768) {
        setMode('list');
      } else {
        setMode('table');
      }
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const menuItems = [
    { id: 'Mark IV', name: 'Mark IV' },
    { id: 'Mark V', name: 'Mark V' },
  ];
  let renderItems = [];
  if (selectedKey) {
    const selectedItem = _.find(endorsementList, { type: selectedKey });
    renderItems = selectedItem ? selectedItem.items : [];
  } else if (endorsementList.length > 0) {
    renderItems = endorsementList[0].items;
  }

  return (
    <Layout>
      {loading ? (
        <Spin className="component-wrapper" />
      ) : (
        <>
          <Sider
            title="Endorsement Lists (ISR)"
            menuItems={menuItems}
            selectedMenuItem={selectedKey}
            onMenuClick={({ key }) => setSelectedKey(key)}
          />
          <Content className="component-wrapper">
            {mode === 'table' ? (
              <Table
                columns={columns}
                dataSource={renderItems}
                vertical="top"
                emptyText="No industry specific endorsements available"
                className="card"
              />
            ) : (
              <List data={renderItems} />
            )}
          </Content>
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  industryId: industryIdSelector(state),
  endorsementList: endorsementsSelector(state),
  loading: loadingIndustrySelector(state) || loadingEndorsementsSelector(state),
});

const mapDispatchToProps = { fetchEndorsements };

export default connect(mapStateToProps, mapDispatchToProps)(Endorsement);
