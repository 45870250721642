import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import _ from 'lodash';
import { useAnalyticsEventTracker } from '../../../../components/Tracker/Tracker';
import Document from '../../../Document/Document';
import * as DocumentActions from '../../../Document/DocumentActions';
import { industryIdSelector, industryNameSelector, loadingIndustrySelector } from '../../IndustrySelectors';
import { countryCodeSelector } from '../../../Account/AccountSelectors';
import {
  riskAssessmentNavItemsSelector,
  riskDescriptionSelector,
  riskAssessmentSelector,
  loadingInsuranceClassesSelector,
  loadingRiskDescriptionSelector,
  loadingRiskAssessmentSelector,
  loadingAvailableRiskAssessmentSelector,
} from '../../../Document/DocumentSelectors';

const RiskAssessment = ({
  industryName,
  industryId,
  country,
  navItems,
  loadingNavItems,
  loadingContent,
  riskDescription,
  riskAssessment,
  fetchInsuranceClasses,
  fetchRiskDescription,
  fetchRiskAssessment,
  fetchAvailableRiskAssessment,
}) => {
  const [selectedNavItem, setSelectedNavItem] = useState();
  const { pathname } = useLocation();
  const eventTracker = useAnalyticsEventTracker('user');

  useEffect(() => {
    if (country) {
      fetchInsuranceClasses(country);
    }
  }, [country, fetchInsuranceClasses]);

  useEffect(() => {
    if (industryId) {
      fetchAvailableRiskAssessment(industryId, country);
    }
  }, [industryId, fetchAvailableRiskAssessment, country]);

  useEffect(() => {
    if (industryId && country) {
      fetchRiskDescription(industryId, country);
    }
  }, [industryId, country, fetchRiskDescription]);

  useEffect(() => {
    if (navItems && navItems.length > 0) {
      const lastSegment = pathname.split('/').pop();
      const ra = _.find(navItems, { id: 'Risk Assessment' });
      switch (lastSegment) {
        case 'risk-assessment': {
          if (ra) {
            setSelectedNavItem(ra.submenu[0]);
          }
          break;
        }
        case 'risk-description':
          setSelectedNavItem(navItems[0]);
          break;
        default: {
          if (ra) {
            const selected = _.find(ra.submenu, { route: lastSegment });
            setSelectedNavItem(selected);
          }
          break;
        }
      }
    }
  }, [navItems, pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (selectedNavItem && selectedNavItem.id !== 'Risk Description' && industryId) {
      fetchRiskAssessment(selectedNavItem.id, industryId);
    }
  }, [selectedNavItem, industryId, fetchRiskAssessment]);

  const onNavSelect = ({ key }) => {
    const search = (items) => {
      let result = _.find(items, { id: key });
      if (result) return result;
      _.forEach(items, (item) => {
        result = search(item.submenu);
      });
      return result;
    };

    const selected = search(navItems);
    setSelectedNavItem(selected);
    eventTracker('selected insurance class of risk assessment', selected.name);
  };

  const content = selectedNavItem && selectedNavItem.id === 'Risk Description' ? riskDescription : riskAssessment;

  return loadingNavItems ? (
    <Spin className="component-wrapper" />
  ) : (
    <>
      <Document
        industryName={industryName}
        navItems={navItems}
        showAllNavItems
        loading={loadingContent}
        content={content}
        selectedNavItem={selectedNavItem}
        onNavSelect={onNavSelect}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  industryId: industryIdSelector(state),
  industryName: industryNameSelector(state),
  country: countryCodeSelector(state),
  navItems: riskAssessmentNavItemsSelector(state),
  riskDescription: riskDescriptionSelector(state),
  riskAssessment: riskAssessmentSelector(state),
  loadingNavItems:
    loadingIndustrySelector(state) ||
    loadingInsuranceClassesSelector(state) ||
    loadingRiskDescriptionSelector(state) ||
    loadingAvailableRiskAssessmentSelector(state),
  loadingContent: loadingRiskAssessmentSelector(state),
});

const mapDispatchToProps = { ...DocumentActions };

export default connect(mapStateToProps, mapDispatchToProps)(RiskAssessment);
