import axios from 'axios';
import qs from 'qs';
import * as HttpStatus from 'http-status-codes';
import { initialize } from '../App/AppActions';

export const LOGIN_REQUEST = 'login/LOGIN_REQUEST';
export const LOGIN_RESETTING = 'login/LOGIN_RESETTING';
export const USER_LOGOUT = 'login/USER_LOGOUT';

const MSG_SUCCESS_RESET_PASSWORD = 'Success! You will receive an email with password reset instructions in a moment.';
const MSG_ERROR = 'Sorry, an error occurred. Please try again later.';
const MSG_ERROR_LOGIN_FAIL = 'The username or password is incorrect.';
const MSG_ERROR_NOT_FOUND = `Sorry, we couldn't find an account with the user name.`;

const API_LMI_AUTH = process.env.REACT_APP_API_LMI_AUTH;
const API_RESET_PASSWORD = process.env.REACT_APP_API_RESET_PASSWORD;
const LMI_PROJECT_ID = 1;

const setLoginRequest = (loginRequest) => ({ type: LOGIN_REQUEST, loginRequest });
const setResetting = (isResetting) => ({ type: LOGIN_RESETTING, isResetting });

export const login = ({ username, password }) => async (dispatch) => {
  const url = API_LMI_AUTH;
  const data = { username, password, grant_type: 'password', client_id: LMI_PROJECT_ID };
  dispatch(setLoginRequest(true));
  try {
    const res = await axios.post(url, qs.stringify(data));
    const token = res.data.access_token;
    localStorage.setItem('token', token);
    dispatch(initialize());
    return Promise.resolve(res);
  } catch (err) {
    const status = err ? err.status : null;
    switch (status) {
      case HttpStatus.BAD_REQUEST:
        return Promise.reject(MSG_ERROR_LOGIN_FAIL);
      default:
        return Promise.reject(MSG_ERROR);
    }
  } finally {
    dispatch(setLoginRequest(false));
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: USER_LOGOUT });
  localStorage.removeItem('token');
  localStorage.removeItem('linkgen_token');
  window.location.replace('/');
};

export const resetPassword = (userName) => async (dispatch) => {
  dispatch(setResetting(true));
  const url = `${API_RESET_PASSWORD}&userName=${userName}`;
  try {
    await axios.post(url);
    return Promise.resolve(MSG_SUCCESS_RESET_PASSWORD);
  } catch (err) {
    const status = err ? err.status : null;
    switch (status) {
      case HttpStatus.NOT_FOUND:
        return Promise.reject(MSG_ERROR_NOT_FOUND);
      default:
        return Promise.reject(MSG_ERROR);
    }
  } finally {
    dispatch(setResetting(false));
  }
};
