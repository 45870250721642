import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

export default ({ children }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > window.innerHeight + 500 && !visible) {
        setVisible(true);
      } else if (window.scrollY <= window.innerHeight + 500 && visible) {
        setVisible(false);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [visible]);

  return <div className={classNames('home-about-parallax', { visible })}>{children}</div>;
};
