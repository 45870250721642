import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import _ from 'lodash';
import Layout from '../../components/Layout/Layout';
import Document from '../Document/Document';
import { useAnalyticsEventTracker } from '../../components/Tracker/Tracker';
import { fetchInsuranceClasses, fetchRiskAssessment } from '../Document/DocumentActions';
import {
  riskAssessmentSelector,
  loadingInsuranceClassesSelector,
  loadingRiskAssessmentSelector,
  generalInformationNavItemsSelector,
} from '../Document/DocumentSelectors';

const GENERAL_ID = '00000';

const GeneralInfo = ({
  navItems = [],
  content,
  loadingNavItems,
  loadingContent,
  fetchInsuranceClasses,
  fetchRiskAssessment,
}) => {
  const [selectedNavItem, setSelectedNavItem] = useState();
  const { pathname } = useLocation();
  const eventTracker = useAnalyticsEventTracker('user');

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchInsuranceClasses('au');
  }, [fetchInsuranceClasses]);

  useEffect(() => {
    if (selectedNavItem && selectedNavItem.id) {
      fetchRiskAssessment(selectedNavItem.id, GENERAL_ID);
    }
  }, [selectedNavItem, fetchRiskAssessment]);

  useEffect(() => {
    if (navItems && navItems.length > 0) {
      const lastSegment = pathname.split('/').pop();

      // When the last segment of the path is not "general-info", a section should be
      // selected. Otherwise, the first section should be selected.
      if (lastSegment !== 'general-info') {
        setSelectedNavItem(_.find(navItems.map((navItem) => navItem.submenu).flat(), { route: lastSegment }));
      } else {
        setSelectedNavItem(navItems[0].submenu[0]);
      }
    }
  }, [navItems, pathname]);

  const onNavItemSelect = ({ key }) => {
    const selectedClass = _.find(navItems, { id: key });
    setSelectedNavItem(selectedClass);
    if (selectedClass) {
      eventTracker('selected insurance class of general information', selectedClass.name);
    }
  };

  return (
    <Layout showMenu showSearch>
      {loadingNavItems ? (
        <Spin className="component-wrapper" />
      ) : (
        <Document
          title="General Information"
          navItems={navItems}
          loading={loadingContent}
          content={content}
          selectedNavItem={selectedNavItem}
          onNavSelect={onNavItemSelect}
        />
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  navItems: generalInformationNavItemsSelector(state),
  content: riskAssessmentSelector(state),
  loadingNavItems: loadingInsuranceClassesSelector(state),
  loadingContent: loadingRiskAssessmentSelector(state),
});

const mapDispatchToProps = { fetchInsuranceClasses, fetchRiskAssessment };

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfo);
