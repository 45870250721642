import React from 'react';
import { connect } from 'react-redux';
import Quiz from '../../../Quiz/Quiz';
import { fetchQuizQuestions } from '../../../Quiz/QuizActions';
import { industryIdSelector } from '../../IndustrySelectors';
import { countryCodeSelector } from '../../../Account/AccountSelectors';

const TestYourKnowledge = ({ industryId, countryCode, fetchQuizQuestions }) => {
  const fetchQuestions = () => {
    if (industryId) {
      const data = { industry_id: industryId, country: countryCode };
      fetchQuizQuestions(data);
    }
  };

  return (
    <div className="component-wrapper">
      <div className="component-wrapper card">
        <Quiz fetchQuestions={fetchQuestions} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  industryId: industryIdSelector(state),
  countryCode: countryCodeSelector(state),
});

const mapDispatchToProps = { fetchQuizQuestions };

export default connect(mapStateToProps, mapDispatchToProps)(TestYourKnowledge);
