import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Home from './scenes/Home/Home';
import Dashboard from './scenes/Dashboard/Dashboard';
import Industry from './scenes/Industry/Industry';
import GeneralInfo from './scenes/GeneralInfo/GeneralInfo';
import Point from './scenes/Point/Point';
import Calculator from './scenes/Calculator/Calculator';
import Account from './scenes/Account/Account';
import Course from './scenes/Course/Course';
import TestYourKnowledge from './scenes/TestYourKnowledge/TestYourKnowledge';
import RiskReport from './scenes/Preview/RiskReport';
import NotFound from './scenes/NotFound/NotFound';
import ResetPassword from './components/Login/components/ResetPassword/ResetPassword';
import Tracker from './components/Tracker/Tracker';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('token') ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

export default () => (
  <Router>
    <Tracker />
    <Switch>
      <Route exact path="/" component={Home} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/industries/browse" component={Industry} />
      <PrivateRoute path="/industries/:id/risk-report/preview" component={RiskReport} />
      <PrivateRoute path="/industries/:id" component={Industry} />
      <PrivateRoute path="/general-info" component={GeneralInfo} />
      <PrivateRoute path="/points" component={Point} />
      <PrivateRoute path="/calculator" component={Calculator} />
      <PrivateRoute path="/account" component={Account} />
      <PrivateRoute path="/courses" component={Course} />
      <PrivateRoute path="/test-your-knowledge" component={TestYourKnowledge} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route component={NotFound} />
    </Switch>
  </Router>
);
