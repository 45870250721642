import {
  REQUEST_COURSES,
  RECEIVE_COURSES,
  REQUEST_COURSE,
  RECEIVE_COURSE,
  REQUEST_UNIT,
  RECEIVE_UNIT,
} from './CourseActions';

const initialState = { loadingCourses: false, loadingCourse: false, loadingUnit: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_COURSES:
      return { ...state, loadingCourses: true };
    case RECEIVE_COURSES:
      return { ...state, loadingCourses: false, courses: action.courses };
    case REQUEST_COURSE:
      return { ...state, loadingCourse: true };
    case RECEIVE_COURSE:
      return { ...state, loadingCourse: false, course: action.course };
    case REQUEST_UNIT:
      return { ...state, loadingUnit: true };
    case RECEIVE_UNIT:
      return { ...state, loadingUnit: false, unit: action.unit };
    default:
      return state;
  }
};
