import React from 'react';
import { Link } from 'react-router-dom';
import { List } from 'antd';
import Button from '../../../../../../components/Button/Button';
import Icon from '../../../../../../components/Icon/Icon';
import { renderColor } from '../../../../utils';
import './Detail.scss';

export default ({ data }) => (
  <div className="component-wrapper card">
    <List
      dataSource={data}
      itemLayout="vertical"
      renderItem={(item) => (
        <List.Item
          key={item.id}
          className="hazard-index-detail-item"
          extra={
            <div className="hazard-index-detail-rating" style={{ color: renderColor(item.rating) }}>
              {`${item.rating}%`}
            </div>
          }
        >
          <List.Item.Meta
            avatar={
              <Icon
                size={48}
                component={item.icon}
                type="setting"
                theme="filled"
                className="hazard-index-detail-icon"
                style={{ backgroundColor: renderColor(item.rating) }}
              />
            }
            title={
              <Link
                to={{ pathname: `risk-assessment/${item.slug.replace(/_/g, '-')}`, state: { id: `${item.id}` } }}
                id={item.slug}
              >
                <Button type="link" secondary className="hazard-index-detail-title">
                  {item.name}
                </Button>
              </Link>
            }
            description={item.classes.join(', ')}
          />
          <div className="hazard-index-detail-comment" dangerouslySetInnerHTML={{ __html: item.comment }} />
        </List.Item>
      )}
    />
  </div>
);
