import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Form, Icon, Input, Button, message } from 'antd';
import { LogoColor } from '../Logo/Logo';
import { login } from './LoginActions';
import './Login.scss';

const FormItem = Form.Item;

const Login = ({ form, history, loginRequest, login }) => {
  const { getFieldDecorator } = form;

  const onSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          await login(values);
          history.push('/dashboard');
        } catch (err) {
          message.error(err);
        }
      }
    });
  };

  return (
    <>
      <div className="login-logo">
        <LogoColor height={45} />
      </div>
      <Form onSubmit={onSubmit} className="login-form">
        <FormItem>
          {getFieldDecorator('username', {
            validateTrigger: 'onBlur',
            rules: [{ required: true, message: 'Please input your username.' }],
          })(<Input prefix={<Icon type="user" />} placeholder="Username" disabled={loginRequest} spellCheck={false} />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your password.' }],
          })(<Input.Password prefix={<Icon type="lock" />} placeholder="Password" disabled={loginRequest} />)}
        </FormItem>
        <FormItem>
          <Link to="/reset-password" className="login-form-link" style={{ float: 'right' }}>
            Forgot password
          </Link>
          <Button
            type="link"
            href="https://lmigroup.io/eservices/riskcoach/#Contact"
            target="_blank"
            className="login-form-link"
            rel="noopener noreferrer"
            style={{ float: 'left' }}
          >
            Enquire about subscribing
          </Button>
          <Button type="primary" htmlType="submit" block loading={loginRequest}>
            {loginRequest ? 'Logging in' : 'Log in'}
          </Button>
        </FormItem>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => ({
  loginRequest: state.login.loginRequest,
});

const mapDispatchToProps = { login };

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Form.create()(Login));
