import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Avatar, Icon } from 'antd';
import Button from '../../../../components/Button/Button';
import { logout } from '../../../../components/Login/LoginActions';
import { userProfileSelector, userAvatarSelector } from '../../AccountSelectors';
import * as icons from '../../../../assets/icons/Account';
import './Dropdown.scss';

const LINKS = Object.freeze([
  { url: '/account/user-profile', icon: icons.IconProfile },
  { url: '/account/company-information', icon: icons.IconCompany },
  { url: '/account/settings', icon: icons.IconSetting },
]);

const Dropdown = ({ userProfile = {}, userAvatar, logout }) => (
  <div className="account-dropdown card">
    <div className="account-dropdown-body">
      <Avatar size={60} icon="user" src={userAvatar} />
      <h3 className="account-dropdown-body-name">{userProfile.person_name}</h3>
      <h5 className="single-line">{userProfile.email}</h5>
      <div className="account-dropdown-body-links">
        {LINKS.map((item) => (
          <Link key={item.url} to={item.url}>
            <Button size="large" shape="circle" type="link" secondary>
              <Icon component={item.icon} />
            </Button>
          </Link>
        ))}
      </div>
    </div>
    <div className="account-dropdown-footer">
      <Button size="large" type="link" ghost block onClick={logout}>
        Logout
      </Button>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  userProfile: userProfileSelector(state),
  userAvatar: userAvatarSelector(state),
});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
