import React from 'react';
import { Progress, Button, Icon } from 'antd';
import { renderColor } from '../../../../utils';
import './Card.scss';

export default ({ rating, name, icon, comment, openModal }) => (
  <div className="hazard-index-card card">
    <Progress
      type="circle"
      format={(rating) => `${rating}%`}
      status="normal"
      percent={rating}
      strokeColor={renderColor(rating)}
      className="hazard-index-card-rating"
    />
    <div className="hazard-index-card-content">
      {icon ? (
        <Icon component={icon} className="hazard-index-card-icon" />
      ) : (
        <Icon type="setting" theme="filled" className="hazard-index-card-icon" />
      )}
      <div className="hazard-index-card-name single-line">{name}</div>
      <div className="hazard-index-card-divider" />
      <Button
        type="link"
        size="small"
        onClick={() => openModal({ rating, name, icon, comment })}
        className="hazard-index-card-btn"
      >
        Read Summary
      </Button>
      <Icon
        type="info-circle"
        onClick={() => openModal({ rating, name, icon, comment })}
        className="hazard-index-card-more"
      />
    </div>
  </div>
);
