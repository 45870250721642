import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';
import Table from '../../../../components/Table/Table';
import Button from '../../../../components/Button/Button';
import { relatedIndustriesSelector, loadingIndustrySelector } from '../../IndustrySelectors';
import { trialUserSelector } from '../../../Account/AccountSelectors';

const RelatedIndustry = ({ history, loading, relatedIndustries, trial }) => {
  const columns = [
    {
      key: 'industry',
      title: 'Industry',
      dataIndex: 'name',
      width: '75%',
      render: (item) => (
        <Button type="link" secondary onClick={() => history.push(`/industries/${item.id}`)} disabled={trial}>
          {item.name}
        </Button>
      ),
    },
    { key: 'code', title: 'Code', dataIndex: 'code', width: '25%' },
  ];
  return (
    <div className="component-wrapper">
      {loading ? (
        <Spin />
      ) : (
        <Table columns={columns} dataSource={relatedIndustries} emptyText="No Related Industries" className="card" />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  relatedIndustries: relatedIndustriesSelector(state),
  loading: loadingIndustrySelector(state),
  trial: trialUserSelector(state),
});

export default compose(withRouter, connect(mapStateToProps))(RelatedIndustry);
