import React, { Fragment } from 'react';
import { Layout, Spin, Empty, Breadcrumb, Row, Col, Anchor } from 'antd';
import IndicativeRate from '../../../Industry/components/IndicativeRate/IndicativeRate';
import './Content.scss';

const { Content } = Layout;
const { Link } = Anchor;

const getLink = (value) => encodeURIComponent(value.replace(/\s+/g, '-').toLowerCase());

const getKey = (item) => `${item?.id}_${item.seq}_${item.title}`;

export default ({ loading, content, industry, insuranceClass }) => {
  const renderSection = (section) => (
    <>
      {section.title.toUpperCase() === 'Indicative Rates of Insurable Gross Profit'.toUpperCase() && industry && (
        <>
          <div className="indicative-rate-desc">
            The rates shown below are based on historic data and do not reflect one off special circumstances such as
            COVID-19 but reflect the typical rate during “normal” periods in the economy.
          </div>
          <IndicativeRate />
        </>
      )}
      {(section.paragraphs || []).map((p) => (
        <section key={getKey(p)} dangerouslySetInnerHTML={{ __html: p.content }} />
      ))}
      {(section.sections || []).map((s) => (
        <Fragment key={getKey(s)}>
          <h2 id={getLink(getKey(s))}>{s.title}</h2>
          {renderSection(s)}
        </Fragment>
      ))}
    </>
  );

  return (
    <Content className="document-content component-wrapper">
      {loading ? (
        <Spin />
      ) : (
        <>
          {content ? (
            <>
              <Breadcrumb separator="|" className="document-breadcrumb">
                <Breadcrumb.Item>{industry || 'General Information'}</Breadcrumb.Item>
                {insuranceClass && <Breadcrumb.Item>{insuranceClass}</Breadcrumb.Item>}
              </Breadcrumb>
              <Row gutter={40}>
                <Col xs={24} lg={18}>
                  <article>
                    {content.sections.map((section) => (
                      <Fragment key={getKey(section)}>
                        <h1 id={getLink(getKey(section))}>{section.title}</h1>
                        {renderSection(section)}
                      </Fragment>
                    ))}
                  </article>
                </Col>
                <Col xs={0} lg={6}>
                  <Anchor className="document-anchor">
                    {content.sections.map((section) => (
                      <Link key={getKey(section)} href={`#${getLink(getKey(section))}`} title={section.title}>
                        {section.sections.map((subsection) => (
                          <Link
                            key={getKey(subsection)}
                            href={`#${getLink(getKey(subsection))}`}
                            title={subsection.title}
                          />
                        ))}
                      </Link>
                    ))}
                  </Anchor>
                </Col>
              </Row>
            </>
          ) : (
            <Empty description="Currently we don't have any content for this section." />
          )}
        </>
      )}
    </Content>
  );
};
