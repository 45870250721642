import {
  REQUEST_TAXONOMIES,
  RECEIVE_TAXONOMIES,
  REQUEST_USER,
  RECEIVE_USER,
  REQUEST_USER_SETTINGS,
  RECEIVE_USER_SETTINGS,
  REQUEST_USER_PROFILE,
  RECEIVE_USER_PROFILE,
  REQUEST_USER_AVATAR,
  RECEIVE_USER_AVATAR,
  REQUEST_COMPANIES,
  RECEIVE_COMPANIES,
  REQUEST_THEMES,
  RECEIVE_THEMES,
  REQUEST_SEARCH_HISTORY,
  RECEIVE_SEARCH_HISTORY,
  REQUEST_USER_REPORTS,
  RECEIVE_USER_REPORTS,
} from './AccountActions';
import { USER_LOGOUT } from '../../components/Login/LoginActions';

const initialState = {
  loadingTaxonomies: false,
  loadingUser: false,
  loadingUserSettings: false,
  loadingUserProfile: false,
  loadingUserReports: false,
  loadingUserAvatar: false,
  loadingCompanies: false,
  loadingThemes: false,
  loadingSearchHistory: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return { ...initialState };
    case REQUEST_TAXONOMIES:
      return { ...state, loadingTaxonomies: true };
    case RECEIVE_TAXONOMIES:
      return { ...state, loadingTaxonomies: false, taxonomies: action.taxonomies };
    case REQUEST_USER:
      return { ...state, loadingUser: true };
    case RECEIVE_USER:
      return { ...state, loadingUser: false, user: action.user || state.user };
    case REQUEST_USER_SETTINGS:
      return { ...state, loadingUserSettings: true };
    case RECEIVE_USER_SETTINGS:
      return { ...state, loadingUserSettings: false, userSettings: action.userSettings || state.userSettings };
    case REQUEST_USER_PROFILE:
      return { ...state, loadingUserProfile: true };
    case RECEIVE_USER_PROFILE:
      return { ...state, loadingUserProfile: false, userProfile: action.userProfile || state.userProfile };
    case REQUEST_USER_REPORTS:
      return { ...state, loadingUserReports: true };
    case RECEIVE_USER_REPORTS:
      return { ...state, loadingUserReports: false, userReports: action.userReports || state.userReports };
    case REQUEST_USER_AVATAR:
      return { ...state, loadingUserAvatar: true };
    case RECEIVE_USER_AVATAR:
      return { ...state, loadingUserAvatar: false, userAvatar: action.userAvatar || state.userAvatar };
    case REQUEST_COMPANIES:
      return { ...state, loadingCompanies: true };
    case RECEIVE_COMPANIES:
      return { ...state, loadingCompanies: false, companies: action.companies || state.companies };
    case REQUEST_THEMES:
      return { ...state, loadingThemes: true };
    case RECEIVE_THEMES:
      return { ...state, loadingThemes: false, themes: action.themes };
    case REQUEST_SEARCH_HISTORY:
      return { ...state, loadingSearchHistory: true };
    case RECEIVE_SEARCH_HISTORY:
      return { ...state, loadingSearchHistory: false, searchHistory: action.searchHistory };
    default:
      return state;
  }
};
