import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import './NotFound.scss';

export default () => (
  <Layout>
    <div className="not-found">
      <h1>Something Just Went Wrong !</h1>
      <h2>404 Error</h2>
      <p>For some reason the page you requested could not be found on our server.</p>
      <p>
        Go <Link to="/">Home</Link>
      </p>
    </div>
  </Layout>
);
