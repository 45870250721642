import React from 'react';
import { connect } from 'react-redux';
import { Form, message, icon as AntIcon } from 'antd';
import classNames from 'classnames';
import { renderColor } from '../../../Industry/utils';
import Icon from '../../../../components/Icon/Icon';
import Button from '../../../../components/Button/Button';
import { industryIdSelector } from '../../../Industry/IndustrySelectors';
import { hazardIndexCustomizationSelector, underwriterHazardIndexCustomizationSelector } from '../../ReportSelectors';
import { updateReportCustomizations } from '../../ReportActions';
import TextEditor from '../../../../components/TextEditor/TextEditor';
import './HazardIndexEdit.scss';

const HazardIndexEdit = ({
  hazardIndex,
  industryId,
  form,
  customization,
  underwriterCustomization,
  updateReportCustomizations,
  close,
  comment,
  underwriter,
  onAddChecklist,
}) => {
  const { getFieldDecorator } = form;

  const onSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const underwriterParam = () => {
          const classToUpdate = underwriterCustomization?.customizations?.find(
            (hazardClass) => hazardClass?.id === +hazardIndex.id
          );
          const customizations = underwriterCustomization?.customizations?.filter(
            (hazardClass) => hazardClass?.id !== +hazardIndex.id
          );
          return {
            section: 'hazard_index_underwriter',
            customization: JSON.stringify({
              classes: [
                ...customizations,
                {
                  ...classToUpdate,
                  id: +hazardIndex.id,
                  comments: values.comment,
                },
              ],
            }),
          };
        };

        const tailoredParam = () => {
          const updated = { class: Number(hazardIndex.id), comment: values.comment };
          const index = customization.comments.findIndex((c) => c.class === Number(hazardIndex.id));
          const comments = Array.from(customization.comments);

          if (index > -1) {
            comments.splice(index, 1, updated);
          } else {
            comments.push(updated);
          }
          return {
            section: 'hazard_index',
            customization: JSON.stringify({
              grouping: customization.grouping.map((group) => ({
                id: group.id,
                title: group.title,
                classes: group.classes.map(Number),
                seq: group.seq,
              })),
              comments,
            }),
          };
        };

        try {
          await updateReportCustomizations(industryId, customization?.id, customization?.name, [
            underwriter ? underwriterParam() : tailoredParam(),
          ]);
          message.success('Saved');
        } catch (err) {
          message.error(err.message);
        }
      }
    });
    close();
  };

  return (
    <Form onSubmit={onSubmit}>
      <div className="hazard-index-edit">
        <div className="heading">
          <div className="hazard-index-icon">
            <Icon
              size={40}
              component={hazardIndex.icon}
              theme="filled"
              style={{ backgroundColor: renderColor(hazardIndex.rating) }}
            />
          </div>
          <div className="info">
            <div className="title">
              <h2 className="name">{hazardIndex.name}</h2>
              <div className="rating" style={{ color: renderColor(hazardIndex.rating) }}>
                {`${hazardIndex.rating}%`}
              </div>
            </div>
            <div className="classes">{hazardIndex.classes.join(', ')}</div>
          </div>
        </div>
        <div className="content">
          <div className="sub-section">
            <div className="content-heading">Description</div>
            <div className="description" dangerouslySetInnerHTML={{ __html: hazardIndex.comment }} />
          </div>
          <div className={classNames('sub-section', { comments: underwriter })}>
            <div className="comments-wrapper">
              <div className="content-heading">{underwriter ? 'UNDERWRITER' : 'ADVISOR'} COMMENTS</div>
              <Form.Item>
                {getFieldDecorator('comment', {
                  initialValue: comment,
                })(<TextEditor placeholder="Add your comments" onKeyDown={(e) => e.stopPropagation()} />)}
              </Form.Item>
            </div>
            <div className="button-wrapper">
              <Button secondary type="primary" htmlType="submit">
                Save
              </Button>
              {underwriter && (
                <Button secondary className="add-checklist-button" onClick={onAddChecklist}>
                  Add Risk Checklist <AntIcon type="right" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  industryId: industryIdSelector(state),
  customization: hazardIndexCustomizationSelector(state),
  underwriterCustomization: underwriterHazardIndexCustomizationSelector(state),
});

const mapDispatchToProps = { updateReportCustomizations };

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(HazardIndexEdit));
