import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Layout, List, Spin } from 'antd';
import Sider from '../../../../components/Sider/Sider';
import { fetchMaterialsEquipments } from '../../IndustryActions';
import {
  industryIdSelector,
  materialEquipmentListSelector,
  loadingIndustrySelector,
  loadingMaterialsEquipmentsSelector,
} from '../../IndustrySelectors';
import { countryCodeSelector } from '../../../Account/AccountSelectors';
import { IconMaterialsEquipments } from '../../../../assets/icons/IndustryFeature';

const { Content } = Layout;

const MaterialEquipment = ({ industryId, country, loading, materialEquipmentList = [], fetchMaterialsEquipments }) => {
  useEffect(() => {
    if (industryId && country) {
      fetchMaterialsEquipments(industryId, country);
    }
  }, [industryId, country, fetchMaterialsEquipments]);

  return (
    <Layout>
      {loading ? (
        <Spin className="component-wrapper" />
      ) : (
        <>
          <Sider
            title="Materials &amp; Equipment"
            icon={IconMaterialsEquipments}
            description="This is not a complete and exhaustive list which may be found in businesses falling into this occupation category. It is intended as a general guide only."
          />
          <Content className="component-wrapper">
            <List
              itemLayout="vertical"
              dataSource={materialEquipmentList}
              renderItem={(item) => (
                <List.Item key={item}>
                  <div>{item}</div>
                </List.Item>
              )}
            />
          </Content>
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  industryId: industryIdSelector(state),
  country: countryCodeSelector(state),
  materialEquipmentList: materialEquipmentListSelector(state),
  loading: loadingIndustrySelector(state) || loadingMaterialsEquipmentsSelector(state),
});

const mapDispatchToProps = { fetchMaterialsEquipments };

export default connect(mapStateToProps, mapDispatchToProps)(MaterialEquipment);
