import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ErrorBoundary from '../../components/Error/ErrorBoundary';
import Layout from '../../components/Layout/Layout';
import CourseBrowse from './scenes/CourseBrowse/CourseBrowse';
import CourseDetail from './scenes/CourseDetail/CourseDetail';

const routes = Object.freeze([
  { route: 'browse', component: <CourseBrowse /> },
  { route: ':id', component: <CourseDetail /> },
]);

export default ({ match }) => (
  <Layout showMenu showSearch>
    <Switch>
      <Route exact path={match.url} render={() => <Redirect to={`${match.url}/${routes[0].route}`} />} />
      {routes.map((item) => (
        <Route
          key={item.route}
          path={`${match.url}/${item.route}`}
          render={() => <ErrorBoundary>{item.component}</ErrorBoundary>}
        />
      ))}
      <Redirect to="/not-found" />
    </Switch>
  </Layout>
);
