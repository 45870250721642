import React from 'react';
import { Row, Col } from 'antd';
import Form from './components/Form/Form';
import Notepad from '../../../Note/components/Notepad/Notepad';
import notes from './data/notes';

export default () => (
  <div className="calculator component-wrapper">
    <Row gutter={20} type="flex">
      <Col span={24} className="calculator-item">
        <Form />
      </Col>
      <Col span={24} className="calculator-item">
        <Notepad notes={notes} />
      </Col>
    </Row>
  </div>
);
