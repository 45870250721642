import _ from 'lodash';
import axios from 'axios';

const API_BASE = process.env.REACT_APP_API_BASE;

export const SET_REPORT_SECTIONS = 'report/SET_REPORT_SECTIONS';
export const SET_HAZARD_INDEX_CHART_DATA_URL = 'report/SET_HAZARD_INDEX_CHART_DATA_URL';
export const SET_HAZARD_INDEX_GROUP_CHART_DATA_URL = 'report/SET_HAZARD_INDEX_GROUP_CHART_DATA_URL';
export const REQUEST_REPORT_CUSTOMIZATION = 'report/REQUEST_REPORT_CUSTOMIZATION';
export const RECEIVE_REPORT_CUSTOMIZATION = 'report/RECEIVE_REPORT_CUSTOMIZATION';
export const REQUEST_UPDATE_REPORT_CUSTOMIZATION = 'report/REQUEST_UPDATE_REPORT_CUSTOMIZATION';
export const RECEIVE_UPDATE_REPORT_CUSTOMIZATION = 'report/RECEIVE_UPDATE_REPORT_CUSTOMIZATION';
export const CLEAR_REPORT_CUSTOMIZATION = 'report/CLEAR_REPORT_CUSTOMIZATION';
export const SET_REPORT_PAGE_BREAK_REQUIRED = 'report/SET_REPORT_PAGE_BREAK_REQUIRED';
export const REMOVE_REPORT_PAGE_BREAK_REQUIRED = 'report/REMOVE_REPORT_PAGE_BREAK_REQUIRED';

const requestReportCustomization = () => ({ type: REQUEST_REPORT_CUSTOMIZATION });
const receiveReportCustomization = (data) => ({
  type: RECEIVE_REPORT_CUSTOMIZATION,
  data,
});

const requestUpdateReportCustomizations = () => ({ type: REQUEST_UPDATE_REPORT_CUSTOMIZATION });
const receiveUpdateReportCustomizations = (customization) => ({
  type: RECEIVE_UPDATE_REPORT_CUSTOMIZATION,
  customization,
});

export const setSections = (value) => {
  let seq = 0;
  const sections = _.mapValues(value, (item) => {
    if (item?.checked && !item?.skipSequencing) {
      seq += 1;
      return { ...item, seq };
    }
    return item;
  });
  return { type: SET_REPORT_SECTIONS, sections };
};

export const setHazardIndexChartDataUrl = (hazardIndexChartDataUrl) => ({
  type: SET_HAZARD_INDEX_CHART_DATA_URL,
  hazardIndexChartDataUrl,
});

export const setHazardIndexGroupChartDataUrl = (hazardIndexChartDataUrl) => ({
  type: SET_HAZARD_INDEX_GROUP_CHART_DATA_URL,
  hazardIndexChartDataUrl,
});

export const clearReportCustomization = () => ({ type: CLEAR_REPORT_CUSTOMIZATION });

export const resetHazardIndexChartDataUrl = () => (dispatch) => {
  dispatch(setHazardIndexChartDataUrl(undefined));
};

export const fetchReportCustomization = (reportId) => async (dispatch) => {
  dispatch(requestReportCustomization());
  const url = `${API_BASE}user/reports/${reportId}`;
  try {
    const res = await axios.get(url);
    dispatch(receiveReportCustomization(res.data));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveReportCustomization());
    return Promise.reject(err);
  }
};

export const updateReportCustomizations = (industryId, reportId, name, customizations) => async (dispatch) => {
  dispatch(requestUpdateReportCustomizations());
  const url = `${API_BASE}user/reports`;
  try {
    const res = await axios({
      method: 'post',
      url,
      data: { industry_id: industryId, report_id: reportId, name, customizations },
      headers: { 'Content-Type': 'application/json;v=2.0' },
    });
    dispatch(receiveUpdateReportCustomizations(res.data));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveUpdateReportCustomizations());
    return Promise.reject(err);
  }
};
