import React from 'react';
import { Layout } from 'antd';
import Sider from '../../components/Sider/Sider';
import Content from './components/Content/Content';

export default ({
  title = 'Risk & Assessment',
  industryName,
  loading,
  content,
  navItems,
  showAllNavItems,
  selectedNavItem,
  onNavSelect,
}) => (
  <Layout>
    {selectedNavItem && (
      <Sider
        collapsible
        title={title}
        menuItems={navItems}
        selectedMenuItem={selectedNavItem.id}
        onMenuClick={onNavSelect}
        showAllNavItems={showAllNavItems}
      />
    )}
    <Content
      loading={loading}
      content={content}
      industry={industryName}
      insuranceClass={selectedNavItem ? selectedNavItem.name : null}
    />
  </Layout>
);
