import React from 'react';
import { Avatar } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import { userAvatarSelector, userProfileSelector } from '../../../Account/AccountSelectors';
import './UserProfile.scss';

const UserProfile = ({ userAvatar, userProfile = {} }) => (
  <div className="dashboard-user-profile card">
    <h1>Your Profile</h1>
    <div className="dashboard-user-profile-inner">
      <Avatar icon="user" size={160} src={userAvatar} />
      <h3>{userProfile.person_name}</h3>
      <p>{userProfile.email}</p>
    </div>
    <Link to="/account/user-profile">
      <Button type="primary" size="large" block>
        Edit
      </Button>
    </Link>
  </div>
);

const mapStateToProps = (state) => ({
  userAvatar: userAvatarSelector(state),
  userProfile: userProfileSelector(state),
});

export default connect(mapStateToProps)(UserProfile);
