import React from 'react';
import moment from 'moment';
import { Icon } from 'antd';
import { Page } from '../../../../../../../../components/Print/Print';
import './DefaultCover.scss';
import poweredByLogo from '../../../../assets/powered-by-lmi.png';

const DefaultCover = ({ theme, companyLogo, insuredBusinessName, industryName, date, dateFormat, underwriter }) => {
  return (
    <Page className="report-preview-cover">
      <div className="report-preview-cover-header">
        {companyLogo ? (
          <img src={companyLogo} height={60} alt="company-logo" />
        ) : (
          <div className="company-logo">
            <Icon type="home" />
          </div>
        )}
        <hr style={{ backgroundColor: theme.visuals.divider.color, height: theme.visuals.divider.width }} />
      </div>
      <div className="report-preview-cover-banner">
        <h1 style={{ color: theme.headings.h1.color, fontFamily: theme.headings.h1.font }}>{insuredBusinessName}</h1>
      </div>
      <div
        className="report-preview-cover-center"
        style={{
          backgroundImage: `linear-gradient(to right, ${theme.covers[0].bgColor[0]}, ${theme.covers[0].bgColor[1]})`,
        }}
      >
        <div className="report-preview-cover-center">
          <h2 style={{ fontFamily: theme.headings.h2.font }}>{industryName}</h2>
          <h1 style={{ fontFamily: theme.headings.h1.font }}>
            {underwriter ? (
              <>
                Risk
                <br />
                <strong>
                  Consideration
                  <br />
                  Report.
                </strong>
              </>
            ) : (
              <>
                Insurance
                <br />
                <strong>
                  Risk
                  <br />
                  Report.
                </strong>
              </>
            )}
          </h1>
        </div>
      </div>
      <div className="report-preview-cover-footer">
        <img src={poweredByLogo} height={60} alt="lmi-logo" />
        <div style={{ fontFamily: theme.body.font }}>{`DATE ISSUED ${moment(date).format(dateFormat)}`}</div>
      </div>
    </Page>
  );
};

export default DefaultCover;
