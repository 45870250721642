import React from 'react';
import { connect } from 'react-redux';
import { Heading, Content } from '../ReportComponent/ReportComponent';
import UnderInsuranceExample from '../../../../../Calculator/scenes/UnderInsuranceCalculator/components/Example/Example';
import { sectionsSelector } from '../../../../ReportSelectors';

const General = ({ sections }) => (
  <>
    {sections && sections.about && sections.about.checked && (
      <div className="report-preview-no-break">
        <Heading seq={sections.about.seq} anchor="about">
          About LMI Group
        </Heading>
        <Content printGap="medium">
          <p>
            LMI Group is an independent company specialising in risk assessment for the general insurance and wider
            business communities. The LMI RiskCoach research team has over 1,000 years combined experience in assisting
            businesses following losses. Using this enormous bank of knowledge the team has developed RiskCoach to
            assist businesses and their insurance advisers understand the specific risks in up to 14 classes of general
            insurance for their industry / occupation before a claim occurs. LMI believe that insurance should be
            considered as protection and not a cost. The cost of insurance, called a premium, is the cost of
            transferring the risk from the shareholders and their families to an insurer.
          </p>
          <p>
            Should you have any questions about this report please discuss them with your insurance adviser or email{' '}
            <a href="mailto:expert@LMIGroup.com">expert@LMIGroup.com</a> enclosing a copy of your report. If you need
            claims assistance, please email <a href="mailto:claims@LMIGroup.com">claims@LMIGroup.com</a> for expert
            assistance.
          </p>
        </Content>
      </div>
    )}
    {sections && sections.purpose && sections.purpose.checked && (
      <div className="report-preview-no-break">
        <Heading seq={sections.purpose.seq} anchor="purpose">
          Purpose of This Report
        </Heading>
        <Content printGap="medium">
          <p>
            The Insurance Risk Report has been designed to identify significant exposures for a business operating in
            your industry. Contained within this report is a Hazard Index graph which considers both the likelihood and
            severity of losses over 14 insurable classes of insurance for your industry and rates them accordingly. This
            graph is complemented with a number of explanations giving rise to the factors considered when providing the
            rating. If it is not already included in this report, you may request your Insurance Advisor to provide you
            with a list of significant exposures relevant to your industry. These exposures are useful to highlight the
            most likely and severe risks to your industry.
          </p>
          <p>
            <strong>Please note:</strong> These ratings have been developed using historical claims and loss data for a
            general business operating in this field. It is recommended that additional factors specific to your
            business be taken into account when assessing this information such as location and crime rates.
          </p>
        </Content>
      </div>
    )}
    {sections && sections['under-insurance'] && sections['under-insurance'].checked && (
      <div className="report-preview-no-break">
        <Heading seq={sections['under-insurance'].seq} anchor="under-insurance">
          Under Insurance
        </Heading>
        <Content printGap="medium">
          <p>
            It is important to understand that two of the key coverages available in your business insurance, namely;
            Material Damage &amp; Business Interruption operate differently than many other policies such as your home
            and contents policy for example.
          </p>
          <p>
            The most notable difference is due to what is called the Average clause sometime referred to as the
            Co-Insurance clause. This clause effectively works out the proportion of the risk you have insured for and
            will pay only that proportion of any valid claim considering you to personally be the insurer of the other
            portion ‘the co-insurer’, even in a partial loss. It is vitally important that you understand this, as being
            under insured even in very large businesses can quickly lead to business failure or, at best, financial
            stress.
          </p>
          <p>
            The following example shows the full impact of being under-insured on a partial loss. The formula on a
            Material Damage or Business Interruption policy with 80% co-insurance/average (most insurance policies will
            give you a 20% buffer so as not to penalise you for accidentally underestimating the true value at risk,
            they understand that it is not an easy task and have provided this tolerance because of this.)
          </p>
          <p>
            <strong>Please note:</strong> This is not the hard and fast rule however and you should always do your best
            to insure fully
          </p>
          <UnderInsuranceExample />
        </Content>
      </div>
    )}
  </>
);

const mapStateToProps = (state) => ({
  sections: sectionsSelector(state),
});

export default connect(mapStateToProps)(General);
