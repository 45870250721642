export default (header) => {
  if (!header || header.length === 0) {
    return {};
  }
  const parts = header.split(',');
  const links = {};
  parts.forEach((p) => {
    const section = p.split(';');
    if (section.length === 2) {
      const url = section[0].replace(/<(.*)>/, '$1').trim();
      const name = section[1].replace(/rel="(.*)"/, '$1').trim();
      links[name] = url;
    }
  });
  return links;
};
