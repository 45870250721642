import { createSelector } from 'reselect';
import _ from 'lodash';
import icons from '../../assets/icons/InsuranceClass';
import { IconRiskDescription, IconReport } from '../../assets/icons/IndustryFeature';
import { countryCodeSelector, trialUserSelector, trialUserOptions } from '../Account/AccountSelectors';

export const loadingInsuranceClassesSelector = (state) => state.document.loadingInsuranceClasses;

// These classes should be excluded in report pages except for AU users.
export const CLASSES_TO_EXCLUDE_FROM_REPORT = ['core', 'employer_liability', 'employer_liability_workers_compensation'];

export const insuranceClassesSelector = createSelector(
  (state) => state.document.insuranceClassList,
  countryCodeSelector,
  trialUserSelector,
  (insuranceClassList, countryCode, trial) =>
    insuranceClassList
      ? insuranceClassList
          .filter((item) => {
            if (countryCode.toLowerCase() !== 'au') {
              return !CLASSES_TO_EXCLUDE_FROM_REPORT.includes(item.slug);
            }
            return item.slug !== 'core';
          })
          .map((item) => ({
            id: `${item.id}`,
            name: item.name,
            slug: item.slug,
            route: item.slug.replace(/_/g, '-'),
            icon: icons[item.slug],
            fallbackId: item.fallback_id,
            grouping: item.grouping,
            active: !trial || trialUserOptions.documents_assessments.includes(`${item.id}`),
          }))
      : undefined
);

export const riskChecklistNavItemsSelector = createSelector(insuranceClassesSelector, (insuranceClasses = []) =>
  insuranceClasses.filter((insuranceClass) => insuranceClass.grouping !== 'Personal Lines')
);

export const generalInformationNavItemsSelector = createSelector(insuranceClassesSelector, (insuranceClasses = []) =>
  _.chain(insuranceClasses)
    .groupBy('grouping')
    .map((value, key) => ({
      id: key,
      name: key,
      submenu: value,
      icon: icons[key.toLowerCase().replace(' ', '_')],
    }))
    .value()
);

export const loadingRiskDescriptionSelector = (state) => state.document.loadingRiskDescription;

export const riskDescriptionSelector = (state) => state.document.riskDescription;

export const loadingRiskAssessmentSelector = (state) => state.document.loadingRiskAssessment;

export const riskAssessmentSelector = (state) => state.document.riskAssessment;

export const loadingAvailableRiskAssessmentSelector = (state) => state.document.loadingAvaliableRiskAssessment;

export const availableRiskAssessmentSelector = (state) => state.document.availableRiskAssessment;

export const riskAssessmentNavItemsSelector = createSelector(
  riskDescriptionSelector,
  insuranceClassesSelector,
  availableRiskAssessmentSelector,
  countryCodeSelector,
  (riskDescription, insuranceClassList, availableRiskAssessment, countryCode) => {
    const getAvailableClassList = () => {
      const availableSections = Object.values(availableRiskAssessment)
        .map((country) => country)
        .flat()
        .map((section) => ({ ...section, id: `${section.id}` }));
      return _.intersectionBy(insuranceClassList, availableSections, 'id');
    };

    const navItemList = () => {
      const riskDescriptionValue = riskDescription
        ? [
            {
              id: 'Risk Description',
              name: 'Risk Description & Service or Process',
              route: 'risk-description',
              icon: IconRiskDescription,
            },
          ]
        : [];

      const insuranceClassListValue = () => {
        if (
          insuranceClassList &&
          insuranceClassList.length > 0 &&
          availableRiskAssessment &&
          countryCode.toLowerCase() === 'au'
        ) {
          return [
            {
              id: 'Risk Assessment',
              name: 'Risk Assessment',
              icon: IconReport,
              submenu: [...getAvailableClassList()],
            },
          ];
        }

        if (insuranceClassList && insuranceClassList.length > 0 && countryCode.toLowerCase() !== 'au') {
          return [
            {
              id: 'Risk Assessment',
              name: 'Risk Assessment',
              icon: IconReport,
              submenu: [...insuranceClassList],
            },
          ];
        }

        return [];
      };

      return [...riskDescriptionValue, ...insuranceClassListValue()];
    };

    return navItemList();
  }
);
