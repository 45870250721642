import React from 'react';
import { List } from 'antd';
import './List.scss';

export default ({ data }) => (
  <div className="endorsement-list card">
    <List
      dataSource={data}
      itemLayout="vertical"
      renderItem={(item) => (
        <List.Item className="endorsement-list-item">
          <List.Item.Meta
            title={
              <div className="endorsement-list-heading">
                <h4>{item.code}</h4>
                <h4>{item.heading}</h4>
              </div>
            }
          />
          <h4>ENDORSEMENT:</h4>
          <div dangerouslySetInnerHTML={{ __html: item.text }} />
          <h4>EXPLANATION:</h4>
          <div dangerouslySetInnerHTML={{ __html: item.explanation }} />
        </List.Item>
      )}
    />
  </div>
);
