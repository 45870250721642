/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Provider, connect } from 'react-redux';
import { Row, Col, Select, message } from 'antd';
import moment from 'moment';
import store from '../../store';
import Layout from '../../components/Layout/Layout';
import Button from '../../components/Button/Button';
import Download from '../../components/Download/Download';
import Theme from '../../components/Theme/Theme';
import { PointCardAnziif, PointCardNiba } from './components/Card/Card';
import Certificate from './components/Certificate/Certificate';
import { download } from '../../components/App/AppActions';
import { fetchCurrentPeriodPoints, fetchLastPeriodPoints, updatePeriod } from './PointActions';
import { fetchSearchHistory } from '../Account/AccountActions';
import { fetchQuizzes } from '../Quiz/QuizActions';
import { periodPointsSelector, loadingPointsSelector } from './PointSelectors';
import { loadingSearchHistorySelector, countryCodeSelector } from '../Account/AccountSelectors';
import Cycle from './Cycle';
import './Point.scss';

const { Option } = Select;

const Point = ({
  points = {},
  loading,
  country,
  download,
  fetchCurrentPeriodPoints,
  fetchLastPeriodPoints,
  updatePeriod,
  fetchSearchHistory,
  fetchQuizzes,
}) => {
  const parsedUrl = new URL(window.location);
  const type = parsedUrl.searchParams.get('type');
  const dateFormat = 'DD/MM/YYYY';
  const cpdCycle =
    moment().year() % 2 === 1
      ? new Cycle(moment().startOf('year'), moment().add(1, 'year').endOf('year'), null, 2)
      : new Cycle(moment().add(-1, 'year').startOf('year'), moment().endOf('year'), null, 2);
  const cipCycle = new Cycle(moment().startOf('year'), moment().endOf('year'));

  const [certificate, setCertificate] = useState(type === 'cpd' ? 'cpd' : 'cip');
  const [cycle, setCycle] = useState(type === 'cpd' ? cpdCycle : cipCycle);
  const [period, setPeriod] = useState('current');

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        await fetchSearchHistory({ start: cycle[period].startDate, end: cycle[period].endDate });
      } catch (err) {
        message.error(err.message);
      }
    };
    fetchHistory();
    fetchQuizzes({ status: 'completed', country });
    if (period === 'current') {
      fetchCurrentPeriodPoints();
    } else {
      fetchLastPeriodPoints({
        cipEnddate: cipCycle.last.endDate,
        cpdEnddate: cpdCycle.last.endDate,
      });
    }

    updatePeriod({ start: cycle[period].startDate, end: cycle[period].endDate });
  }, [
    fetchQuizzes,
    fetchCurrentPeriodPoints,
    fetchLastPeriodPoints,
    fetchSearchHistory,
    updatePeriod,
    cycle,
    period,
    country,
  ]);

  const onDownloadCertificate = () => {
    const content = renderToStaticMarkup(
      <Provider store={store}>
        <Theme>
          <Certificate
            certificate={certificate}
            dateRange={[
              moment(cycle[period].startDate).format(dateFormat),
              moment(cycle[period].endDate).format(dateFormat),
            ]}
          />
        </Theme>
      </Provider>
    );
    return download(content);
  };

  const onCertificateChange = (selected) => {
    setCertificate(selected);
    setCycle(selected === 'cpd' ? cpdCycle : cipCycle);
  };

  const onPeriodChange = (selected) => {
    setPeriod(selected);
  };

  return (
    <Layout showMenu>
      <div className="point component-wrapper">
        <p>
          LMI RiskCoach subscribers are able to claim time spent using LMI RiskCoach as CIP or CPD credit points under
          current activity rules. Up to maximum 15 points claimable per annum. The CIP certificate can be printed off by
          selecting the period time.
        </p>
        <Row type="flex" gutter={40} className="point-card-list">
          <Col xs={24} sm={12} md={{ offset: 4, span: 8 }} className="point-card-list-item">
            <PointCardAnziif
              point={points.cip}
              active={certificate === 'cip'}
              mask
              onClick={() => onCertificateChange('cip')}
            />
          </Col>
          <Col xs={24} sm={12} md={8} className="point-card-list-item">
            <PointCardNiba
              point={points.cpd}
              active={certificate === 'cpd'}
              mask
              onClick={() => onCertificateChange('cpd')}
            />
          </Col>
        </Row>
        <h1 className="point-title">Get your Certificate</h1>
        <h2 className="point-subtitle">{certificate === 'cip' ? 'ANZIIF CIP Points' : 'NIBA CPD Points'}</h2>
        {certificate === 'cip' ? (
          <p>
            The Certified Insurance Professional (CIP) program requires that Institute CIP members complete and record a
            minimum of 25 CIP points of professional development activities each calendar year (January to December). Up
            to 15 points can be claimed from using LMI RiskCoach.
          </p>
        ) : (
          <p>
            Brokers, insurance advisers and managers are required to keep well informed and up to date on the latest
            technical, product and regulatory changes affecting the professional by undertaking relevant continuing
            professional development (CPD). Like all professional bodies, NIBA requires all practising members to obtain
            a minimum of 50 CPD points over each two-year period. Up to 20 points over 2 years can be claimed from using
            LMI RiskCoach.
          </p>
        )}
        <Select
          placeholder="Select period"
          size="large"
          className="point-period"
          onChange={onPeriodChange}
          defaultValue="current"
        >
          <Option value="current">{`Current period: ${moment(cycle.current.startDate).format(dateFormat)} - ${moment(
            cycle.current.endDate
          ).format(dateFormat)}`}</Option>
          <Option value="last">{`Last period: ${moment(cycle.last.startDate).format(dateFormat)} - ${moment(
            cycle.last.endDate
          ).format(dateFormat)}`}</Option>
        </Select>
        {certificate === 'cip' ? (
          <p>LMI RiskCoach certifies the eligibility for 15 CIP points for online usage</p>
        ) : (
          <p>LMI RiskCoach certifies the eligibility for 20 CPD points for online usage</p>
        )}
        <Download download={onDownloadCertificate} fileName="Certificate of Eligibility.pdf">
          {(onDownload, downloading) => (
            <Button
              type="primary"
              size="large"
              icon="download"
              loading={downloading}
              disabled={!cycle.current.startDate || !cycle.current.endDate || loading}
              onClick={onDownload}
            >
              Download
            </Button>
          )}
        </Download>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  points: periodPointsSelector(state),
  loading: loadingPointsSelector(state) && loadingSearchHistorySelector(state),
  country: countryCodeSelector(state),
});

const mapDispatchToProps = {
  download,
  fetchCurrentPeriodPoints,
  updatePeriod,
  fetchLastPeriodPoints,
  fetchSearchHistory,
  fetchQuizzes,
};

export default connect(mapStateToProps, mapDispatchToProps)(Point);
