import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import Button from '../../../../components/Button/Button';
import Card from '../../components/Card/Card';
import { fetchCourses } from '../../CourseActions';
import { coursesSelector, loadingCoursesSelector } from '../../CourseSelectors';
import { trialUserSelector } from '../../../Account/AccountSelectors';
import { ReactComponent as PowerByLmiCollege } from '../../../../assets/images/lmi-college.svg';

import './CourseBrowse.scss';

const CourseBrowse = ({ courses = [], loading, fetchCourses, trial }) => {
  useEffect(() => {
    fetchCourses(trial);
  }, [fetchCourses, trial]);

  return (
    <div className="component-wrapper course-browse">
      <p>
        Welcome to the short courses page. These courses are designed to provide users with a basic understanding of
        topics that are generally misunderstood in the insurance industry. After reading the course content, there are
        revision questions that will both test and reinforce your understanding of the areas covered.
      </p>
      <div className="course-browse-list">
        {loading ? (
          <Spin />
        ) : (
          courses.map((item) => (
            <Card key={item.id} title={item.name} description={item.description} cover={item.cover}>
              <PowerByLmiCollege className="powered-by" />
              <Link to={{ pathname: item.id }}>
                <Button type="primary">Start this course</Button>
              </Link>
            </Card>
          ))
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  courses: coursesSelector(state),
  trial: trialUserSelector(state),
  loading: loadingCoursesSelector(state),
});

const mapDispatchToProps = { fetchCourses };

export default connect(mapStateToProps, mapDispatchToProps)(CourseBrowse);
