import React from 'react';
import { connect } from 'react-redux';
import { Spin, Empty } from 'antd';
import Table from '../../../../components/Table/Table';
import { industryCodesSelector, loadingIndustrySelector } from '../../IndustrySelectors';

const IndustryCode = ({ loading, industryCodes = [] }) => (
  <div className="component-wrapper">
    {loading ? (
      <Spin />
    ) : (
      <>
        {industryCodes.length > 0 ? (
          industryCodes.map((item) => (
            <Table
              key={item.taxonomy}
              columns={[
                { key: 'name', title: item.taxonomy, dataIndex: 'name', width: '75%' },
                { key: 'code', title: 'Code', dataIndex: 'code', width: '25%' },
              ]}
              dataSource={item.codes}
              rowKey="code"
              className="card"
            />
          ))
        ) : (
          <Empty />
        )}
      </>
    )}
  </div>
);

const mapStateToProps = (state) => ({
  industryCodes: industryCodesSelector(state),
  loading: loadingIndustrySelector(state),
});

export default connect(mapStateToProps)(IndustryCode);
