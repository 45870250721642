import {
  SET_REPORT_SECTIONS,
  SET_HAZARD_INDEX_CHART_DATA_URL,
  SET_HAZARD_INDEX_GROUP_CHART_DATA_URL,
  REQUEST_REPORT_CUSTOMIZATION,
  RECEIVE_REPORT_CUSTOMIZATION,
  REQUEST_UPDATE_REPORT_CUSTOMIZATION,
  RECEIVE_UPDATE_REPORT_CUSTOMIZATION,
  CLEAR_REPORT_CUSTOMIZATION,
  SET_REPORT_PAGE_BREAK_REQUIRED,
  REMOVE_REPORT_PAGE_BREAK_REQUIRED,
} from './ReportActions';
import { RECEIVE_INDUSTRY } from '../Industry/IndustryActions';

const initialState = {
  loadingCustomizations: false,
  customization: { isPristine: true },
  reportPageBreakRequired: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORT_SECTIONS: {
      const customization = { ...state.customization };
      if (action.sections.cover?.insuredBusinessName) {
        customization.name = action.sections.cover?.insuredBusinessName;
      }
      return {
        ...state,
        sections: action.sections,
        customization,
      };
    }
    case SET_HAZARD_INDEX_CHART_DATA_URL:
      return { ...state, hazardIndexChartDataUrl: action.hazardIndexChartDataUrl };
    case SET_HAZARD_INDEX_GROUP_CHART_DATA_URL:
      return {
        ...state,
        hazardIndexGroupChartDataUrl: state.hazardIndexGroupChartDataUrl
          ? [...state.hazardIndexGroupChartDataUrl, action.hazardIndexChartDataUrl]
          : [action.hazardIndexChartDataUrl],
      };
    case CLEAR_REPORT_CUSTOMIZATION:
      return { ...initialState };
    case RECEIVE_INDUSTRY:
      return { ...state, hazardIndexChartDataUrl: undefined };
    case REQUEST_REPORT_CUSTOMIZATION:
      return { ...state, loadingCustomizations: true };
    case RECEIVE_REPORT_CUSTOMIZATION:
      return {
        ...state,
        loadingCustomizations: false,
        customization: {
          ...action.data,
          isPristine: false,
        },
      };
    case REQUEST_UPDATE_REPORT_CUSTOMIZATION:
      return { ...state, loadingCustomizations: true };
    case RECEIVE_UPDATE_REPORT_CUSTOMIZATION:
      return {
        ...state,
        loadingCustomizations: false,
        customization: {
          ...action.customization,
          isPristine: false,
        },
        hazardIndexGroupChartDataUrl: undefined,
      };
    case SET_REPORT_PAGE_BREAK_REQUIRED:
      return {
        ...state,
        reportPageBreakRequired: [
          ...state.reportPageBreakRequired.filter((section) => section !== action.section),
          action.section,
        ],
      };
    case REMOVE_REPORT_PAGE_BREAK_REQUIRED:
      return {
        ...state,
        reportPageBreakRequired: [],
      };
    default:
      return state;
  }
};
