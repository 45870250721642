/* eslint-disable consistent-return */
import { useEffect } from 'react';

const useHashFragment = (offset = 0, trigger = true) => {
  useEffect(() => {
    const scrollToHashElement = () => {
      const { hash } = window.location;
      const elementToScroll = document.getElementById(hash?.replace('#', ''));
      if (!elementToScroll) return;
      elementToScroll.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    };

    if (!trigger) return;

    setTimeout(scrollToHashElement, 1000);

    window.addEventListener('hashchange', scrollToHashElement);
    return window.removeEventListener('hashchange', scrollToHashElement);
  }, [offset, trigger]);
};

export default useHashFragment;
