import React from 'react';
import { connect } from 'react-redux';
import Table from '../../../../components/Table/Table';
import { indicativeRateSelector } from '../../IndustrySelectors';

const columns = [
  { key: 'country', title: 'Country', dataIndex: 'country' },
  { key: 'value', title: 'IRGP (%)', dataIndex: 'value' },
];

const IndicativeRate = ({ indicativeRates = [] }) =>
  indicativeRates.length > 0 ? (
    <Table
      title="Indicative Rates of Gross Profit by Country"
      columns={columns}
      dataSource={indicativeRates}
      align="center"
    />
  ) : null;

const mapStateToProps = (state) => ({ indicativeRates: indicativeRateSelector(state) });

export default connect(mapStateToProps)(IndicativeRate);
