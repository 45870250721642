import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Select, Spin, Icon, Alert } from 'antd';
import { debounce } from 'lodash';
import Button from '../../../../components/Button/Button';
import { fetchIndustries } from '../../IndustryActions';
import { parseLinkHeader } from '../../utils';
import './SearchBox.scss';
import { trialUserSelector } from '../../../Account/AccountSelectors';

const { Option } = Select;

class SearchBox extends PureComponent {
  constructor() {
    super();
    this.onSearch = debounce(this.onSearch, 300);
    this.state = {
      industryList: [],
      nextUrl: undefined,
      value: undefined,
      fetching: false,
      searching: false,
      notification: '',
    };
  }

  loadMore = async () => {
    const { fetchIndustries } = this.props;
    const { fetching, nextUrl } = this.state;
    if (fetching || typeof nextUrl === 'undefined') {
      return;
    }
    this.setState({ fetching: true });
    try {
      const res = await fetchIndustries(null, nextUrl);
      const {
        data,
        headers: { link },
      } = res;
      const parsedLink = parseLinkHeader(link);
      this.setState(({ industryList }) => ({ industryList: [...industryList, ...data], nextUrl: parsedLink.next }));
    } catch (err) {
      this.setState({ industryList: [], nextUrl: null });
    }
    this.setState({ fetching: false });
  };

  onSearch = async (value) => {
    if (value === '') {
      this.setState({ industryList: [] });
      return;
    }
    const { fetchIndustries, trialUser, withQuizOnly } = this.props;
    this.setState({ industryList: [], searching: true });
    try {
      const params = Number.isNaN(parseInt(value, 10)) ? { q: value, withQuizOnly } : { code: value };
      const res = await fetchIndustries(params, null, trialUser);
      const {
        data,
        headers: { link },
      } = res;
      const parsedLink = parseLinkHeader(link);
      if (data.length) {
        this.setState({ industryList: data, nextUrl: parsedLink.next, notification: null });
      } else {
        this.setState({
          industryList: data,
          nextUrl: parsedLink.next,
          notification: withQuizOnly
            ? `Sorry but this Knowledge Test is not currently available - try another keyword 
            or click here to request a new Test for this Industry.`
            : `No industry found matching the provided keyword.`,
        });
      }
    } catch (err) {
      this.setState({ nextUrl: null });
    }
    this.setState({ searching: false });
  };

  onChange = (value) => {
    this.setState({ value });
  };

  onSelect = (value) => {
    const { location, history, withQuizOnly } = this.props;
    const redirect = withQuizOnly ? `/industries/${value}/quiz` : `/industries/${value}`;
    if (redirect !== location.pathname) {
      history.push(redirect);
    }
  };

  onPopupScroll = async (e) => {
    const { fetching } = this.state;
    if (!fetching) {
      const element = e.target;
      if (element.scrollHeight - element.scrollTop < element.clientHeight + 10) {
        this.loadMore();
      }
    }
  };

  render() {
    const { size, mode, placeholder, trialUser, withQuizOnly } = this.props;
    const { industryList, value, searching, notification } = this.state;

    const options = industryList
      .filter((item) => !item.alias)
      .map((item) => <Option key={item.id}>{item.name}</Option>);

    return (
      <>
        <div className={classNames('industry-search-box', { vertical: mode === 'vertical' })}>
          <Icon type="search" className={classNames('industry-search-box-icon', { large: size === 'large' })} />
          <Select
            showSearch
            size={size}
            value={value}
            placeholder={placeholder}
            showArrow={false}
            filterOption={false}
            notFoundContent={searching ? <Spin /> : null}
            onSearch={this.onSearch}
            onChange={this.onChange}
            onSelect={this.onSelect}
            onPopupScroll={this.onPopupScroll}
            className="industry-search-box-select"
            dropdownClassName="industry-search-box-select-dropdown"
          >
            {options}
          </Select>

          {!withQuizOnly && (
            <Link to="/industries/browse">
              <Button
                type="primary"
                size={size}
                secondary
                className={classNames('industry-search-box-btn')}
                disabled={trialUser}
              >
                Browse Industries
              </Button>
            </Link>
          )}
        </div>
        {withQuizOnly && notification && (
          <>
            <Alert showIcon={false} message={notification} type="info" banner />
            <br />
            <Button type="primary" icon="mail" href={`mailto:${process.env.REACT_APP_EMAIL}`}>
              Request a Test
            </Button>
          </>
        )}
      </>
    );
  }
}

SearchBox.propTypes = {
  size: PropTypes.oneOf(['large', 'default']),
  mode: PropTypes.oneOf(['horizontal', 'vertical']),
  placeholder: PropTypes.string,
  withQuizOnly: PropTypes.bool,
};

SearchBox.defaultProps = {
  size: 'default',
  mode: 'horizontal',
  placeholder: 'Search Industry',
  withQuizOnly: false,
};

const mapStateToProps = (state) => ({
  trialUser: trialUserSelector(state),
});

const mapDispatchToProps = { fetchIndustries };

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SearchBox);
