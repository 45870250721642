import { combineReducers } from 'redux';
import appReducer from './components/App/AppReducer';
import loginReducer from './components/Login/LoginReducer';
import industryReducer from './scenes/Industry/IndustryReducer';
import documentReducer from './scenes/Document/DocumentReducer';
import reportReducer from './scenes/Report/ReportReducer';
import accountReducer from './scenes/Account/AccountReducer';
import courseReducer from './scenes/Course/CourseReducer';
import quizReducer from './scenes/Quiz/QuizReducer';
import pointReducer from './scenes/Point/PointReducer';

export default combineReducers({
  app: appReducer,
  login: loginReducer,
  industry: industryReducer,
  document: documentReducer,
  report: reportReducer,
  account: accountReducer,
  course: courseReducer,
  quiz: quizReducer,
  point: pointReducer,
});
