import React from 'react';
import { Row, Col, Form, Select, Input } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;

const BUILDING_TYPES = [
  { key: 'bulk-storage-shed', title: 'Bulk Storage Shed' },
  { key: 'hay-shed-open', title: 'Hay Shed (open)' },
  { key: 'hay-shed-partially-enclosed', title: 'Hay Shed (partially enclosed)' },
  { key: 'shearing-shed', title: 'Shearing Shed' },
];

export default ({ buildingId, onBuildingsChange }) => (
  <Row gutter={20}>
    <Col xs={24} sm={12}>
      <FormItem label="Building type/Construction">
        <Select size="large" onChange={(value) => onBuildingsChange(buildingId, 'type', value)}>
          {BUILDING_TYPES.map((item) => (
            <Option key={item.key}>{item.title}</Option>
          ))}
        </Select>
      </FormItem>
    </Col>
    <Col xs={24} sm={12}>
      <FormItem
        label={
          <span>
            Gross Floor Area (m<sup>2</sup>)
          </span>
        }
      >
        <Input
          size="large"
          suffix={
            <span>
              m<sup>2</sup>
            </span>
          }
          onChange={(e) => onBuildingsChange(buildingId, 'area', e.target.value)}
        />
      </FormItem>
    </Col>
  </Row>
);
