import * as icons from '../../../assets/icons/IndustryFeature';

export default [
  {
    title: 'Quick and Tailored Report',
    description:
      'Quickly tailor a personalised insurance risk report for your client. Designed to assist with client meetings and proposals that are sure to give you the edge.',
    icon: icons.IconTailoredReport,
  },
  {
    title: 'Hazard Index',
    description:
      'For each class of insurance, a risk grade known as the LMI Hazard Index is provided as an indication of hazard for that industry.',
    icon: icons.IconHazardIndex,
  },
  {
    title: 'Significant Exposures',
    description:
      'Significant Exposures are a list of the common hazards for the industry under review flagging those which should be considered in evaluating a particular risk within that industry.',
    icon: icons.IconSignificantExposures,
  },
  {
    title: 'Risk Checklist',
    description:
      'The Risk Checklist identifies the likely risks, provides initial analysis and recommended loss control measures.',
    icon: icons.IconRiskChecklist,
  },
  {
    title: 'Industry Codes',
    description:
      'The Australian & New Zealand Standard Industrial Classification (“ANZSIC”) has been produced by the Australian Bureau of Statistics and the New Zealand Department of Statistics for use in the collection and publication of statistics in the two countries. Risk Coach classifies the industries by this code.',
    icon: icons.IconIndustryCodes,
  },
  {
    title: 'Endorsement Lists',
    description:
      'A comprehensive list of endorsements that should be considered for an Insured in the industry category.',
    icon: icons.IconEndorsements,
  },
  {
    title: 'The Market',
    description: 'A listing of insurance providers/policies developed for or suited to this particular industry.',
    icon: icons.IconMarket,
  },
];
