import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Icon, message } from 'antd';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import Card from '../../components/Card/Card';
import Item from '../../components/Item/Item';
import Avatar from '../../components/Avatar/Avatar';
import ProfileEdit from './components/ProfileEdit/ProfileEdit';
import { updateUserProfile, updateUserAvatar } from '../../AccountActions';
import { resetPassword } from '../../../../components/Login/LoginActions';
import {
  userProfileSelector,
  userNameSelector,
  userAvatarSelector,
  loadingUserProfileSelector,
} from '../../AccountSelectors';

const Profile = ({
  userProfile = {},
  userAvatar,
  userName,
  loading,
  sending,
  resetPassword,
  updateUserProfile,
  updateUserAvatar,
}) => {
  const uploadAvatar = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const res = await updateUserAvatar(formData);
      onSuccess(null, file);
      message.success(res);
    } catch (err) {
      onError();
      message.error(err.message);
    }
  };

  const updateProfile = async (data) => {
    try {
      const res = await updateUserProfile(data);
      message.success(res);
    } catch (err) {
      message.error(err.message);
    }
  };

  const sendVerificationEmail = async (e) => {
    e.preventDefault();
    try {
      const res = await resetPassword(userName);
      message.success(res);
    } catch (err) {
      message.error(err);
    }
  };

  return (
    <div className="component-wrapper">
      <Card
        title="User Profile"
        loading={loading}
        edit={
          <Modal toggle={(show) => <Icon type="edit" onClick={show} className="account-card-header-icon" />}>
            {(hide) => <ProfileEdit userProfile={userProfile} update={updateProfile} close={hide} />}
          </Modal>
        }
        footer={
          <>
            <h3 className="account-card-footer-title">About Me</h3>
            <div
              className="account-card-footer-inner"
              dangerouslySetInnerHTML={{ __html: userProfile.description || 'No Content' }}
            />
          </>
        }
      >
        <Row type="flex">
          <Col xs={24} lg={9} xl={8} xxl={6}>
            <Item size="large" layout="vertical">
              <Avatar icon="user" src={userAvatar} editable upload={uploadAvatar} />
            </Item>
          </Col>
          <Col xs={24} lg={15} xl={16} xxl={14}>
            <Item label={<Icon type="user" />}>
              <div>{userProfile.person_name}</div>
            </Item>
            <Item label={<Icon type="environment" />}>
              <div>{userProfile.addressStr}</div>
            </Item>
            <Item label={<Icon type="mail" />}>
              <div>{userProfile.email}</div>
            </Item>
            <Item label={<Icon type="phone" />}>
              <div>{userProfile.phone}</div>
            </Item>
            <Item label={<Icon type="mobile" />}>
              <div>{userProfile.mobile}</div>
            </Item>
            <Item label={<Icon type="idcard" />}>
              <div>{userProfile.job_title}</div>
            </Item>
          </Col>
        </Row>
      </Card>
      <Card title="Account">
        <div>
          <h2 style={{ marginBottom: '.8em' }}>Reset Password</h2>
          <p>We will send an email with further instructions to the address associated with your account.</p>
          <Button type="primary" secondary onClick={sendVerificationEmail} loading={sending}>
            Send reset email
          </Button>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProfile: userProfileSelector(state),
  userAvatar: userAvatarSelector(state),
  userName: userNameSelector(state),
  loading: loadingUserProfileSelector(state),
  sending: state.login.isResetting,
});

const mapDispatchToProps = { resetPassword, updateUserProfile, updateUserAvatar };

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
