import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Icon, Tag } from 'antd';
import { CountryContext } from '../../contexts';
import './Menu.scss';
import * as icons from '../../assets/icons/Menu';

const { SubMenu } = Menu;

export default withRouter(({ location }) => {
  const selectedKeys = location.pathname.split('/');
  const subMenuAllowedCountries = ['au', 'nz'];

  return (
    <CountryContext.Consumer>
      {({ country }) => {
        return (
          <Menu mode="horizontal" selectedKeys={selectedKeys} className="main-menu">
            {subMenuAllowedCountries.includes(country) && (
              <Menu.Item key="general-info">
                <Link to="/general-info">
                  <Icon component={icons.IconInfo} />
                  General Information
                </Link>
              </Menu.Item>
            )}

            <Menu.Item key="courses">
              <Link to="/courses">
                <Icon component={icons.IconTraining} />
                Short Courses
              </Link>
            </Menu.Item>

            {subMenuAllowedCountries.includes(country) && (
              <Menu.Item key="test-your-knowledge" className="tyk">
                <Tag color="#019cae" className="highlight-tag">
                  NEW
                </Tag>
                <Link to="/test-your-knowledge">
                  <Icon component={icons.IconKnowledge} />
                  Test Your Knowledge
                </Link>
              </Menu.Item>
            )}

            <SubMenu
              key="calculators"
              title={
                <span>
                  <Icon component={icons.IconCalculator} />
                  Calculators
                </span>
              }
            >
              <Menu.Item key="bi">
                <a href="https://www.bicalculator.com/" target="_blank" rel="noopener noreferrer">
                  LMI BICalculator
                </a>
              </Menu.Item>
              {subMenuAllowedCountries.includes(country) && (
                <Menu.Item key="building-cost">
                  <a
                    href="https://www.lmigroup.com/RiskCoachCalculators/building-cost-calculator"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Building Cost Calculator
                  </a>
                  {/* <Link to="/calculator/building-cost">Building Cost Calculator</Link> */}
                </Menu.Item>
              )}
              {subMenuAllowedCountries.includes(country) && (
                <Menu.Item key="under-insurance">
                  <a
                    href="https://www.lmigroup.com/RiskCoachCalculators/under-insurance-calculator"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Under-insurance Calculator
                  </a>
                  {/* <Link to="/calculator/under-insurance">Under-insurance Calculator</Link> */}
                </Menu.Item>
              )}
              {subMenuAllowedCountries.includes(country) && (
                <Menu.Item key="limit-of-liability">
                  <a
                    href="https://classic.lmiriskcoach.com/Calculators/ISRLiabilityCalc.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Limit of Liability Calculator
                  </a>
                  {/* <Link to="/calculator/limit-of-liability">Limit of Liability Calculator</Link> */}
                </Menu.Item>
              )}
            </SubMenu>
          </Menu>
        );
      }}
    </CountryContext.Consumer>
  );
});
