import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List, message } from 'antd';
import classNames from 'classnames';
import Button from '../../../../components/Button/Button';
import { fetchSearchHistory } from '../../../Account/AccountActions';
import { recentSearchSelector, loadingSearchHistorySelector } from '../../../Account/AccountSelectors';

const RecentSearch = ({ history, recentSearch, loading, fetchSearchHistory }) => {
  const [scrolled, setScrolled] = useState();
  const listContainer = useRef();

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        await fetchSearchHistory();
      } catch (err) {
        message.error(err.message);
      }
    };
    fetchHistory();

    const handleScroll = (e) => {
      const containerTop = e.srcElement.getClientRects()[0].top;
      const listEl = e.srcElement.querySelector('.ant-list');
      const listTop = listEl.getClientRects()[0].top;
      setScrolled(containerTop > listTop);
    };
    const listContainerRef = listContainer.current;
    if (listContainerRef) {
      listContainerRef.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (listContainerRef) {
        listContainerRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [fetchSearchHistory]);

  return (
    <div className="recent-activities-list-container">
      <div className={classNames('recent-activities-list', { scrolled })} ref={listContainer}>
        <List
          loading={loading}
          dataSource={recentSearch}
          renderItem={(item) => (
            <List.Item extra={item.code}>
              <List.Item.Meta
                title={
                  <Button type="link" secondary onClick={() => history.push(`/industries/${item.id}`)}>
                    {item.name}
                  </Button>
                }
                description={item.date}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  recentSearch: recentSearchSelector(state),
  loading: loadingSearchHistorySelector(state),
});

const mapDispatchToProps = { fetchSearchHistory };

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(RecentSearch);
