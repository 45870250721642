import axios from 'axios';

export const REQUEST_RISK_DESCRIPTION = 'document/REQUEST_RISK_DESCRIPTION';
export const RECEIVE_RISK_DESCRIPTION = 'document/RECEIVE_RISK_DESCRIPTION';
export const REQUEST_RISK_ASSESSMENT = 'document/REQUEST_RISK_ASSESSMENT';
export const RECEIVE_RISK_ASSESSMENT = 'document/RECEIVE_RISK_ASSESSMENT';
export const REQUEST_INSURANCE_CLASSES = 'document/REQUEST_INSURANCE_CLASSES';
export const RECEIVE_INSURANCE_CLASSES = 'document/RECEIVE_INSURANCE_CLASSES';
export const REQUEST_AVAILABLE_RISK_ASSESSMENT = 'document/REQUEST_AVAILABlE_RISK_ASSESSMENT';
export const RECEIVE_AVAILABLE_RISK_ASSESSMENT = 'document/RECEIVE_AVAILABlE_RISK_ASSESSMENT';

const API_BASE = process.env.REACT_APP_API_BASE;

const requestRiskDescription = () => ({ type: REQUEST_RISK_DESCRIPTION });

const receiveRiskDescription = (data) => ({ type: RECEIVE_RISK_DESCRIPTION, riskDescription: data });

const requestRiskAssessment = () => ({ type: REQUEST_RISK_ASSESSMENT });

const receiveRiskAssessment = (data) => ({ type: RECEIVE_RISK_ASSESSMENT, riskAssessment: data });

const requestInsuranceClasses = () => ({ type: REQUEST_INSURANCE_CLASSES });

const receiveInsuranceClasses = (data) => ({ type: RECEIVE_INSURANCE_CLASSES, insuranceClassList: data });

const requestAvaliableRiskAssessment = () => ({ type: REQUEST_AVAILABLE_RISK_ASSESSMENT });

const receiveAvaliableRiskAssessment = (data) => ({
  type: RECEIVE_AVAILABLE_RISK_ASSESSMENT,
  availableRiskAssessment: data,
});

export const fetchRiskDescription = (industryId, country) => async (dispatch) => {
  dispatch(requestRiskDescription());
  const url = `${API_BASE}documents/insights/${country}.${industryId}`;
  try {
    const res = await axios.get(url);
    dispatch(receiveRiskDescription(res.data));
  } catch (err) {
    dispatch(receiveRiskDescription());
  }
};

export const fetchRiskAssessment = (insuranceClassId, industryId) => async (dispatch) => {
  if (!insuranceClassId || !industryId) {
    dispatch(receiveRiskAssessment());
    return;
  }
  dispatch(requestRiskAssessment());
  const url = `${API_BASE}documents/assessments/${insuranceClassId.padStart(3, '0')}.${industryId}`;
  try {
    const res = await axios.get(url);
    dispatch(receiveRiskAssessment(res.data));
  } catch (err) {
    dispatch(receiveRiskAssessment());
  }
};

export const fetchInsuranceClasses = (country) => async (dispatch) => {
  dispatch(requestInsuranceClasses());
  const url = `${API_BASE}insurance_classes?country=${country}`;
  try {
    const res = await axios.get(url);
    dispatch(receiveInsuranceClasses(res.data));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveInsuranceClasses());
    return Promise.reject(err);
  }
};

export const fetchAvailableRiskAssessment = (industryId, country) => async (dispatch) => {
  dispatch(requestAvaliableRiskAssessment());
  const url = `${API_BASE}documents/assessments/outlines?industry=${industryId}&country=${country}`;
  try {
    const res = await axios.get(url);
    dispatch(receiveAvaliableRiskAssessment(res.data));
  } catch (err) {
    dispatch(receiveAvaliableRiskAssessment());
  }
};
