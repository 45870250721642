import React, { useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch, Provider } from 'react-redux';
import store from '../../store';
import ReportPreview, { Main, FrontMatter } from '../Report/scenes/ReportPreview/ReportPreview';
import { ThemeContext } from '../Report/ReportContext';
import { htmlTemplate } from '../../components/App/AppActions';
import {
  fetchIndustry,
  fetchHazardIndex,
  fetchSignificantExposures,
  fetchRiskChecklist,
} from '../Industry/IndustryActions';
import { setSections } from '../Report/ReportActions';
import { reportThemeSelector, countryCodeSelector } from '../Account/AccountSelectors';
import { reportDownloadParamsSelector, sectionsSelector } from '../Report/ReportSelectors';

const RiskReport = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { id: industryId } = useParams();
  const dispatch = useDispatch();
  const reportTheme = useSelector(reportThemeSelector);
  const country = useSelector(countryCodeSelector);
  const reportDownloadParams = useSelector(reportDownloadParamsSelector);
  const reportSections = useSelector(sectionsSelector);

  const parsedUrl = new URL(window.location);
  const insuredBusinessName = parsedUrl.searchParams.get('insuredBusinessName');

  let sections = [];
  try {
    sections = JSON.parse(parsedUrl.searchParams.get('sections'));
  } catch (error) {
    sections = parsedUrl.searchParams.get('sections')?.split(',');
    const adapted = {};
    sections.forEach((s) => {
      adapted[s] = { checked: true };
    });
    sections = adapted;
  }

  useEffect(() => {
    const loadData = async () => {
      const promises = [
        dispatch(fetchIndustry(industryId)),
        dispatch(fetchHazardIndex(industryId, country)),
        dispatch(fetchSignificantExposures(industryId, country)),
      ];
      if (sections) {
        if (sections['risk-checklist']?.children) {
          promises.push(
            dispatch(fetchRiskChecklist(industryId, { categories: sections['risk-checklist'].children, type: 'Key' }))
          );
        } else {
          promises.push(dispatch(fetchRiskChecklist(industryId, { type: 'Key' })));
        }
      }
      await Promise.all(promises);
      setDataLoaded(true);
    };
    loadData();
    // eslint-disable-next-line
  }, [industryId, country, dispatch]);

  useEffect(() => {
    const mappedSections = {
      cover: { insuredBusinessName },
      'user-profile': sections['user-profile'],
      'company-profile': sections['company-profile'],
      about: sections.about,
      purpose: sections.purpose,
      'under-insurance': sections['under-insurance'],
      'report-hazard-assessment': sections['hazard-index'],
      'report-significant-exposures': sections.exposures,
      'report-risk-checklist': sections['risk-checklist'],
    };
    dispatch(setSections(mappedSections));
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (reportSections && dataLoaded && window.ReactNativeWebView) {
      const content = renderToStaticMarkup(
        <Provider store={store}>
          <ThemeContext.Provider value={reportTheme.value}>
            <Main />
          </ThemeContext.Provider>
        </Provider>
      );
      const cover = renderToStaticMarkup(
        <Provider store={store}>
          <ThemeContext.Provider value={reportTheme.value}>
            <FrontMatter />
          </ThemeContext.Provider>
        </Provider>
      );
      const message = JSON.stringify({
        content: htmlTemplate(content),
        cover: htmlTemplate(cover),
        params: reportDownloadParams,
      });
      window.ReactNativeWebView.postMessage(message);
    }
    // eslint-disable-next-line
  }, [reportSections, dataLoaded]);

  return (
    <ThemeContext.Provider value={reportTheme.value}>
      <ReportPreview />
    </ThemeContext.Provider>
  );
};

export default RiskReport;
