import React from 'react';
import HazardIndexGrouping from '../HazardIndexGrouping/HazardIndexGrouping';
import Item from '../Item/Item';
import Groups from '../Groups/Groups';

const TailoredList = ({ list = [], onCheck, onGroupCheck }) => {
  return (
    <div className="report-list">
      <ul>
        {list.map((item) => {
          if (item?.children?.length) {
            if (item?.id === 'report-hazard-assessment') {
              return (
                <Item key={item.id} item={item}>
                  <HazardIndexGrouping appearance="list" />
                </Item>
              );
            }
            return <Groups key={item.id} item={item} onCheck={onCheck} onGroupCheck={onGroupCheck} />;
          }
          return (
            <Item
              key={item.id}
              item={item}
              checked={list
                .filter((item) => item.checked)
                .map((item) => item.id)
                .includes(item.id)}
              onCheck={(e) => onCheck(e.target.checked, item.id)}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default TailoredList;
