import React from 'react';
import { Button, Icon } from 'antd';
import { Page } from '../../../../../../components/Print/Print';
import { LogoColor } from '../../../../../../components/Logo/Logo';
import { ThemeContext } from '../../../../ReportContext';
import useTheme from '../../hooks/useTheme';
import './BackCover.scss';

export default () => {
  const theme = useTheme(ThemeContext);
  return (
    <Page>
      <div className="report-preview-back-cover">
        <div className="report-preview-back-cover-info">
          <div
            className="report-preview-back-cover-contact"
            style={{
              // eslint-disable-next-line max-len
              backgroundImage: `linear-gradient(to right, ${theme.covers[0].bgColor[0]}, ${theme.covers[0].bgColor[1]})`,
            }}
          >
            <Button type="link" href={`https://${process.env.REACT_APP_DOMAIN}`} target="_blank">
              {process.env.REACT_APP_DOMAIN}
              <Icon type="home" />
            </Button>
            <Button type="link" href={`mailto:${process.env.REACT_APP_EMAIL}`}>
              {process.env.REACT_APP_EMAIL}
              <Icon type="mail" />
            </Button>
          </div>
          <LogoColor width={200} />
        </div>
      </div>
    </Page>
  );
};
