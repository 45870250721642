import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import ErrorBoundary from '../../components/Error/ErrorBoundary';
import BuildingCostCalculator from './scenes/BuildingCostCalculator/BuildingCostCalculator';
import BuildingCostCalculatorPrinting from './scenes/BuildingCostCalculator/scenes/Printing/Printing';
import UnderInsuranceCalculator from './scenes/UnderInsuranceCalculator/UnderInsuranceCalculator';
import UnderInsuranceCalculatorPrinting from './scenes/UnderInsuranceCalculator/scenes/Printing/Printing';
import './Calculator.scss';

const routes = Object.freeze([
  { route: 'building-cost', component: <BuildingCostCalculator /> },
  { route: 'under-insurance', component: <UnderInsuranceCalculator /> },
]);

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/building-cost/printing`} component={BuildingCostCalculatorPrinting} />
    <Route exact path={`${match.url}/under-insurance/printing`} component={UnderInsuranceCalculatorPrinting} />
    <Layout showMenu>
      <Switch>
        {routes.map((item) => (
          <Route
            key={item.route}
            exact
            path={`${match.url}/${item.route}`}
            render={() => <ErrorBoundary>{item.component}</ErrorBoundary>}
          />
        ))}
        <Redirect to="/not-found" />
      </Switch>
    </Layout>
  </Switch>
);
