import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Carousel, Spin } from 'antd';
import Icon from '../../../../components/Icon/Icon';
import Button from '../../../../components/Button/Button';
import { fetchInsuranceClasses } from '../../../Document/DocumentActions';
import { insuranceClassesSelector, loadingInsuranceClassesSelector } from '../../../Document/DocumentSelectors';
import { countryCodeSelector } from '../../../Account/AccountSelectors';
import './GeneralInfo.scss';

const GeneralInfo = ({ country, insuranceClasses = [], loading, fetchInsuranceClasses }) => {
  const slider = useRef();

  useEffect(() => {
    fetchInsuranceClasses(country);
  }, [country, fetchInsuranceClasses]);

  return (
    <div className="general-info card">
      <h1>GENERAL INFORMATION</h1>
      <div className="general-info-carousel">
        {loading ? (
          <Spin />
        ) : (
          insuranceClasses.length > 0 && (
            <>
              <Button shape="circle" icon="left" onClick={() => slider.current.prev()} />
              <div className="slides">
                <Carousel dots={false} ref={slider}>
                  {insuranceClasses.map((item) => (
                    <div key={item.name} className="slide">
                      <Icon size={48} component={item.icon} type="setting" theme="filled" />
                      <h3>{item.name}</h3>
                    </div>
                  ))}
                </Carousel>
              </div>
              <Button shape="circle" icon="right" onClick={() => slider.current.next()} />
            </>
          )
        )}
      </div>
      <p className="general-info-description">
        Go through the General Information and find a multitude of information on all things risk, the information is
        broken down into the relevant class of insurance ranging from Property Damage and Business Interruption through
        to Marine and Cyber.
      </p>
      <Link to="/general-info" className="general-info-btn">
        <Button size="large" type="primary">
          View General Information
        </Button>
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => ({
  insuranceClasses: insuranceClassesSelector(state),
  country: countryCodeSelector(state),
  loading: loadingInsuranceClassesSelector(state),
});

const mapDispatchToProps = { fetchInsuranceClasses };

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfo);
