import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import classNames from 'classnames';
import { Article, Page, Paragraph } from '../../../../components/Print/Print';
import { LogoColor } from '../../../../components/Logo/Logo';
import { periodPointsSelector } from '../../PointSelectors';
import { personNameSelector, companyNameSelector, searchHistorySelector } from '../../../Account/AccountSelectors';
import { shortCourseQuizzesSelector, industryQuizzesSelector } from '../../../Quiz/QuizSelectors';
import './Certificate.scss';
import signature from '../../assets/signature.png';

const shortCourseQuizColumns = [
  { key: 'course', title: 'Course', dataIndex: 'shortCourseName' },
  { key: 'duration', title: 'Time', dataIndex: 'duration' },
  { key: 'date', title: 'Date', dataIndex: 'date' },
  { key: 'result', title: 'Result', dataIndex: 'result' },
];

const industryQuizColumns = [
  { key: 'course', title: 'Course', dataIndex: 'industryName' },
  { key: 'industry', title: 'Industry Code', dataIndex: 'industryCode' },
  { key: 'duration', title: 'Time', dataIndex: 'duration' },
  { key: 'result', title: 'Result', dataIndex: 'result' },
];

const CertificatePage = ({ children }) => {
  return (
    <Page>
      <div className="page-logo">
        <LogoColor width={200} />
      </div>
      <div className="yellow-bar" />
      <div className="black-bar" />
      <div className="page-content">{children}</div>
    </Page>
  );
};

const Certificate = ({
  certificate,
  dateRange,
  points = {},
  personName,
  companyName,
  searchHistory,
  shortCourseQuizzes,
  industryQuizzes,
}) => {
  const type = certificate === 'cip' ? 'CIP' : 'CPD';
  const point = certificate === 'cip' ? points.cip : points.cpd;
  const [startDate, endDate] = dateRange;

  return (
    <Article className="certificate">
      <CertificatePage>
        <h1>CERTIFICATE</h1>
        <h3>of Eligibility</h3>
        <Paragraph>
          <p>
            This is to certify the eligibility for {type} points for online usage of
            <br />
            <strong>LMI RiskCoach</strong>
          </p>
          <p>BY</p>
          <h2>{personName}</h2>
          <p>
            For the period {startDate} to {endDate}
            <br />
            As at {moment().format('dddd, DD MMMM YYYY')}
          </p>
        </Paragraph>
        <Paragraph>
          <em>
            Industries visited and short courses completed are detailed in the Statement of Completion. This certificate
            is valid as evidence of usage for the purposes of claiming {type} points only when submitted in conjunction
            with the Statement.
          </em>
        </Paragraph>
        <Paragraph>
          <div className="certificate-points">
            <h2>{point}</h2>
            <h4>Total {type} points</h4>
            <div className={classNames('certificate-logo', certificate)} />
          </div>
        </Paragraph>
        <div className="certificate-signature">
          <img src={signature} alt="signature" />
          <hr />
          <h4>Steve Manning</h4>
        </div>
      </CertificatePage>
      <CertificatePage>
        <Paragraph>
          <div className={classNames('certificate-logo', certificate)} />
          <h1>STATEMENT OF COMPLETION</h1>
          <h3>LMI RiskCoach</h3>
        </Paragraph>
        <Paragraph>
          {certificate === 'cip' ? (
            <em>
              This document confirms that, for the period indicated, the party named below has utilised LMI RiskCoach
              for the purposes of research into the specified industries for the duration identified, completed the
              listed short courses, and is eligible for the listed CIP points under the current Australian &amp; New
              Zealand Institute of Insurance &amp; Finance professional development activity rules*. For further
              information, please refer to{' '}
              <a href="http://www.anziif.com" target="_blank" rel="noopener noreferrer">
                www.anziif.com
              </a>
              .
            </em>
          ) : (
            <em>
              This document confirms that, for the period indicated, the party named below has utilised LMI RiskCoach
              for the purposes of research into the specified in dustries for the duration identified, completed the
              listed short courses, and is eligible for the listed CPD points. Refer to NIBA website:{' '}
              <a href="http://www.niba.com.au" target="_blank" rel="noopener noreferrer">
                www.niba.com.au
              </a>{' '}
              for further details.
            </em>
          )}
        </Paragraph>
        <Paragraph>
          <h4>Name: {personName}</h4>
          <h4>Company: {companyName}</h4>
          <h4>
            Period: {startDate} to {endDate}
          </h4>
        </Paragraph>
        {/* <Paragraph>
          <h4>Total Hours Recorded of Online Usage for this Period</h4>
          <p>(Up to maximum 15 hours claimable per annum)</p>
          <p>09:59</p>
        </Paragraph> */}
        <Paragraph>
          <h4>
            Total {type} Points: {point}
          </h4>
        </Paragraph>
        {certificate === 'cip' && (
          <Paragraph>
            <em>
              *The CIP points allocation is subject to regular review and may change from time to time. In the event of
              a change the Institute will honour the most generous point allocation applicable from either the current
              version, or the version immediately prior.
            </em>
          </Paragraph>
        )}
      </CertificatePage>
      {searchHistory &&
        searchHistory.length > 0 &&
        _.chunk(searchHistory, 32).map((list) => (
          <CertificatePage>
            <h4>LMI RiskCoach Industries Researched</h4>
            <ul>
              {list.map((item) => (
                <li key={item.id}>{item.name}</li>
              ))}
            </ul>
          </CertificatePage>
        ))}
      {shortCourseQuizzes &&
        shortCourseQuizzes.length > 0 &&
        _.chunk(shortCourseQuizzes, 20).map((quizzes) => (
          <CertificatePage>
            <h4>Short Courses Completed</h4>
            <Table columns={shortCourseQuizColumns} dataSource={quizzes} rowKey="id" pagination={false} />
          </CertificatePage>
        ))}
      {industryQuizzes &&
        industryQuizzes.length > 0 &&
        _.chunk(industryQuizzes, 20).map((quizzes) => (
          <CertificatePage>
            <h4>Industry Courses Completed</h4>
            <Table columns={industryQuizColumns} dataSource={quizzes} rowKey="id" pagination={false} />
          </CertificatePage>
        ))}
    </Article>
  );
};

const mapStateToProps = (state) => ({
  points: periodPointsSelector(state),
  personName: personNameSelector(state),
  companyName: companyNameSelector(state),
  searchHistory: searchHistorySelector(state),
  shortCourseQuizzes: shortCourseQuizzesSelector(state),
  industryQuizzes: industryQuizzesSelector(state),
});

export default connect(mapStateToProps)(Certificate);
