import React from 'react';
import { connect } from 'react-redux';
import { currencySymbolSelector } from '../../../../../Account/AccountSelectors';
import './Example.scss';

const Example = ({ currency }) => (
  <div className="under-insurance-calculator-example">
    <div className="under-insurance-calculator-example-section">
      <p>
        <strong>Amount paid</strong>
      </p>
      <table>
        <tbody>
          <tr>
            <td rowSpan={3}>=</td>
            <td colSpan={5}>Insured Values</td>
          </tr>
          <tr>
            <td colSpan={5}>
              <hr className="divider" />
            </td>
            <td>x</td>
            <td>Amount of Loss</td>
          </tr>
          <tr>
            <td colSpan={2}>Co-insurance Percentage</td>
            <td>x</td>
            <td colSpan={2}>Replacement Values</td>
          </tr>
          <tr className="blank" />
          <tr>
            <td rowSpan={3}>=</td>
            <td colSpan={5}>{currency}500,000</td>
          </tr>
          <tr>
            <td colSpan={5}>
              <hr className="divider" />
            </td>
            <td>x</td>
            <td>{currency}250,000</td>
          </tr>
          <tr>
            <td colSpan={2}>80%</td>
            <td>x</td>
            <td colSpan={2}>{currency}1,000,000</td>
          </tr>
          <tr className="blank" />
          <tr>
            <td>=</td>
            <td colSpan={5}>62.50%</td>
            <td>x</td>
            <td>{currency}250,000</td>
          </tr>
          <tr className="blank" />
          <tr>
            <td>=</td>
            <td colSpan={5}>{currency}156,250</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="under-insurance-calculator-example-section">
      <div>
        <strong>Amount payable per Insurer = {currency}156,250 less any Policy Excess</strong>
      </div>
      <div>
        <strong>Amount borne by (YOU) the Insured = {currency}93,750 plus any Policy Excess</strong>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  currency: currencySymbolSelector(state),
});

export default connect(mapStateToProps)(Example);
