import React from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Row, Col, Card } from 'antd';
import CountUp from 'react-countup';
import classNames from 'classnames';
import Layout from '../../components/Layout/Layout';
import Icon from '../../components/Icon/Icon';
import Carousel from './components/Carousel/Carousel';
import Parallax from './components/Parallax/Parallax';
import './Home.scss';
import { IconTraining, IconCalculator } from '../../assets/icons/Menu';
import { ReactComponent as HomeBannerGraphic } from './assets/graphic.svg';
import homeAboutImage from './assets/home-about.png';
import tailoredReportFeatures from './data/tailoredReport';
import dashboardImage from './assets/dashboard.png';

const STATISTICS = Object.freeze([
  { title: 'Industries', count: 7000 },
  { title: 'Insurance Classes', count: 14 },
]);

export default () => {
  const loggedIn = localStorage.getItem('token');
  return loggedIn ? (
    <Redirect to="/dashboard" />
  ) : (
    <Layout isHome>
      <div className="home">
        <div className="home-banner">
          <div className="home-banner-graphic">
            <HomeBannerGraphic />
          </div>
          <div className="home-banner-inner">
            <div className="home-banner-text">
              <h1>LMI</h1>
              <h2>RiskCoach</h2>
              <h3>A valuable resource for Insurance Professionals</h3>
            </div>
            <div>
              <Button
                type="primary"
                href="https://lmigroup.io/eservices/riskcoach/#Contact"
                target="_blank"
                className="home-banner-btn primary"
                rel="noopener noreferrer"
              >
                Contact Us / Enquire
              </Button>
            </div>
          </div>
        </div>
        <div className="home-features">
          <Row type="flex" justify="center" gutter={20}>
            <Col xs={24} sm={22} md={8} xl={6} xxl={4}>
              <h5>RiskCoach Features</h5>
              <h3>Understanding the Risk</h3>
              <p>
                The system boasts a wide range of features to assist the experienced professional through to the new
                staff members understand new industries and continue with their professional development.
              </p>
            </Col>
            <Col xs={24} sm={22} md={16} xl={14} xxl={14}>
              <Carousel />
            </Col>
          </Row>
        </div>
        <div className="home-statistic">
          <Row type="flex" justify="center" gutter={20}>
            {STATISTICS.map((item) => (
              <Col key={item.title} xs={24} sm={22} md={8}>
                <div className="home-statistic-item">
                  <h1 className={classNames({ industries: item.title === 'Industries' })}>
                    <CountUp end={item.count} duration={3} separator="," />
                  </h1>
                  <h4>{item.title}</h4>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <div className="home-about">
          <Row type="flex" justify="center" gutter={20}>
            <Col xs={24} sm={22} lg={14} xl={10} xxl={9}>
              <Parallax>
                <img src={homeAboutImage} alt="about" />
              </Parallax>
            </Col>
            <Col xs={24} sm={22} lg={10} xl={10} xxl={9}>
              <h5>About RiskCoach</h5>
              <h3>Insurable risks for each completed industry</h3>
              <p>
                LMI RiskCoach has been designed to enable insurance professionals to better understand the risks
                associated with various industries. This online tool systematically analyses the insurance risks
                associated with a particular industry for each major insurance product line, providing it to you in a
                format you can share with your customers.
              </p>
              <Col span={12} className="home-about-item">
                <Icon component={IconTraining} size={70} />
                <h4>Training Tools</h4>
                <p>
                  Facilitate both the up-skilling of new staff members and support for the more experienced
                  professionals.
                </p>
              </Col>
              <Col span={12} className="home-about-item">
                <Icon component={IconCalculator} size={70} />
                <h4>Calculator Tools</h4>
                <p>
                  Including a building value estimator, under-insurance calculator, and limit of liability calculator.
                </p>
              </Col>
            </Col>
          </Row>
        </div>
        <div className="home-tailored-report">
          <Row type="flex" justify="center">
            <h3>Tailor your Risk Report</h3>
            <Row type="flex" justify="center">
              {tailoredReportFeatures.map(({ key, heading, image, description, stepImage }) => (
                <Col xs={22} sm={12} xl={6} xxl={5} key={key}>
                  <Card className="home-tailored-report-feature">
                    <div className="home-tailored-report-step" style={{ backgroundImage: `url(${stepImage})` }} />
                    <img src={image} alt={heading} title={heading} />
                    <h4>{heading}</h4>
                    <p>{description}</p>
                  </Card>
                </Col>
              ))}
            </Row>
          </Row>
        </div>
        <div className="home-dashboard">
          <Row type="flex" justify="center" gutter={24} align="middle">
            <Col xs={24} sm={22} lg={18} xl={6} xxl={6}>
              <h5>Dashboard</h5>
              <h3>Keep track of your overall reports and account</h3>
              <p>
                Riskcoach dashboard is an information management tool that tracks gathers and displays your activity and
                enables you to monitor your tailored reports, recently viewed browses or your CIP points.
              </p>
              <p>You can easily connect to your account or find general information, help and many more.</p>
            </Col>

            <Col xs={24} sm={22} lg={18} xl={13} xxl={12}>
              <div className="home-dashboard-image">
                <img src={dashboardImage} alt="Riskcoach Dashboard" title="Riskcoach Dashboard" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};
