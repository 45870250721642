import {
  FETCH_INDUSTRIES,
  REQUEST_INDUSTRY,
  RECEIVE_INDUSTRY,
  REQUEST_INDUSTRY_CATEGORIES,
  RECEIVE_INDUSTRY_CATEGORIES,
  REQUEST_HAZARD_INDEX,
  RECEIVE_HAZARD_INDEX,
  REQUEST_SIGNIFICANT_EXPOSURES,
  RECEIVE_SIGNIFICANT_EXPOSURES,
  REQUEST_MATERIALS_EQUIPMENTS,
  RECEIVE_MATERIALS_EQUIPMENTS,
  REQUEST_RISK_CHECKLIST,
  RECEIVE_RISK_CHECKLIST,
  REQUEST_ENDORSEMENTS,
  RECEIVE_ENDORSEMENTS,
  REQUEST_MEDIA,
  RECEIVE_MEDIA,
} from './IndustryActions';

const initialState = {
  loadingIndustry: false,
  loadingIndustryCategories: false,
  loadingHazardIndex: false,
  loadingSignificantExposures: false,
  loadingMaterialsEquipments: false,
  loadingRiskChecklist: false,
  loadingEndorsements: false,
  loadingMedia: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INDUSTRIES:
      return { ...state, industryList: action.industryList };
    case REQUEST_INDUSTRY:
      return { ...state, loadingIndustry: true, industryInfo: undefined };
    case RECEIVE_INDUSTRY:
      return { ...state, loadingIndustry: false, industryInfo: action.industryInfo };
    case REQUEST_INDUSTRY_CATEGORIES:
      return { ...state, loadingIndustryCategories: true };
    case RECEIVE_INDUSTRY_CATEGORIES:
      return { ...state, loadingIndustryCategories: false, industryCategoryList: action.industryCategoryList };
    case REQUEST_HAZARD_INDEX:
      return { ...state, loadingHazardIndex: true };
    case RECEIVE_HAZARD_INDEX:
      return {
        ...state,
        loadingHazardIndex: false,
        hazardIndex: action.hazardIndex,
      };
    case REQUEST_SIGNIFICANT_EXPOSURES:
      return { ...state, loadingSignificantExposures: true, significantExposureList: undefined };
    case RECEIVE_SIGNIFICANT_EXPOSURES:
      return { ...state, loadingSignificantExposures: false, significantExposureList: action.significantExposureList };
    case REQUEST_MATERIALS_EQUIPMENTS:
      return { ...state, loadingMaterialsEquipments: true, materialEquipmentList: undefined };
    case RECEIVE_MATERIALS_EQUIPMENTS:
      return { ...state, loadingMaterialsEquipments: false, materialEquipmentList: action.materialEquipmentList };
    case REQUEST_RISK_CHECKLIST:
      return { ...state, loadingRiskChecklist: true, riskChecklist: undefined };
    case RECEIVE_RISK_CHECKLIST:
      return { ...state, loadingRiskChecklist: false, riskChecklist: action.riskChecklist };
    case REQUEST_ENDORSEMENTS:
      return { ...state, loadingEndorsements: true, endorsementList: undefined };
    case RECEIVE_ENDORSEMENTS:
      return { ...state, loadingEndorsements: false, endorsementList: action.endorsementList };
    case REQUEST_MEDIA:
      return { ...state, loadingMedia: true, media: undefined };
    case RECEIVE_MEDIA:
      return { ...state, loadingMedia: false, media: action.media };
    default:
      return state;
  }
};
