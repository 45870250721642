import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { pointsSelector } from '../../PointSelectors';
import logoAnziif from '../../assets/logo-cip.png';
import logoNiba from '../../assets/logo-niba-color.png';
import { CountryContext } from '../../../../contexts';
import './Badge.scss';

const Badge = ({ points = {} }) => (
  <CountryContext.Consumer>
    {({ country }) => {
      return (
        country === 'au' && (
          <div className="point-badge">
            <Link to="/points" className="point-badge-logo">
              <img src={logoAnziif} alt="logo-anziif" />
              <span className="point-badge-number">{points.cip}</span>
            </Link>
            <Link to="/points" className="point-badge-logo">
              <img src={logoNiba} alt="logo-niba" />
              <span className="point-badge-number">{points.cpd}</span>
            </Link>
          </div>
        )
      );
    }}
  </CountryContext.Consumer>
);

const mapStateToProps = (state) => ({ points: pointsSelector(state) });

export default connect(mapStateToProps)(Badge);
