import React from 'react';
import classNames from 'classnames';
import Hamburger from '../../../../components/Hamburger/Hamburger';
import './Drawer.scss';

export default ({ children, visible, setVisible }) => (
  <div className={classNames('drawer', { visible })}>
    <Hamburger active={visible} onClick={() => setVisible(!visible)} className="drawer-toggle-btn" />
    <div className="drawer-content">{children}</div>
  </div>
);
