import React from 'react';
import classNames from 'classnames';
import { Icon as AntIcon } from 'antd';
import './Icon.scss';

export default ({ component = null, type = null, size = 28, shape = 'circle', className, style, ...rest }) => {
  const defaultStyle = { width: size, height: size, fontSize: size / 2 };
  return component ? (
    <AntIcon
      component={component}
      className={classNames('icon', shape, className)}
      style={{ ...defaultStyle, ...style }}
      {...rest}
    />
  ) : (
    <AntIcon
      type={type}
      className={classNames('icon', shape, className)}
      style={{ ...defaultStyle, ...style }}
      {...rest}
    />
  );
};
