import React from 'react';
import { Button } from 'antd';
import logo from '../../assets/andrewnock.png';
import './Contact.scss';

export default () => (
  <div className="building-cost-calculator-contact card" style={{ backgroundColor: '#0f6cb6' }}>
    <img src={logo} alt="logo" height={140} />
    <h4>The Building Cost Calculator is powered by Andrew Nock Valuers</h4>
    <p>Contact Andrew Nock Valuers for any obligation and cost free quotations and assistance throughout Australia</p>
    <Button size="large" href="http://www.lmigroup.com/contactform.aspx?emailTo=andrewnock" target="_blank">
      Contact
    </Button>
  </div>
);
