/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Layout, Icon, Popconfirm, message } from 'antd';
import Button from '../../../../components/Button/Button';
import Report from '../../components/Report/Report';
import HazardIndexItem from '../../components/HazardIndexItem/HazardIndexItem';
import { hazardIndexSelector, industryIdSelector } from '../../../Industry/IndustrySelectors';
import { underwriterHazardIndexCustomizationSelector, sectionsSelector } from '../../ReportSelectors';
import {
  updateReportCustomizations,
  clearReportCustomization,
  fetchReportCustomization,
  resetHazardIndexChartDataUrl,
} from '../../ReportActions';
import { fetchRiskChecklist } from '../../../Industry/IndustryActions';
import './UnderwriterReport.scss';

const { Content, Header } = Layout;

const UnderwriterReport = ({
  fullscreen,
  setFullscreen,
  industryId,
  sections,
  customization,
  hazardIndexes = [],
  clearReportCustomization,
  fetchReportCustomization,
  fetchRiskChecklist,
  updateReportCustomizations,
  loadingCustomizations,
  resetHazardIndexChartDataUrl,
}) => {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState('underwriter');

  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get('tmpl')) {
      fetchReportCustomization(query.get('tmpl'));
    }
  }, [location]);

  useEffect(() => {
    fetchRiskChecklist(industryId, { type: 'Key' });
  }, [industryId]);

  const save = async (selectedClasses) => {
    if (loadingCustomizations) return null;

    const selectedClassesToSave = selectedClasses.map((selectedClassId) => {
      const classFound = customization.customizations.find((hazardIndex) => hazardIndex?.id === selectedClassId);
      return {
        ...classFound,
        id: selectedClassId,
        checked: true,
      };
    });

    const hazardIndexWithComments = customization.customizations
      .filter(
        (hazardIndex) =>
          !selectedClasses.includes(hazardIndex?.id) && (hazardIndex.comments || hazardIndex.checklist?.length)
      )
      .map((hazardIndex) => ({
        ...hazardIndex,
        checked: false,
      }));

    try {
      await updateReportCustomizations(industryId, customization.id, customization.name, [
        {
          section: 'hazard_index_underwriter',
          customization: JSON.stringify({ classes: [...selectedClassesToSave, ...hazardIndexWithComments] }),
        },
      ]);
      resetHazardIndexChartDataUrl();
      message.success('Saved');
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    if (customization?.customizations && customization?.customizations.length > 0) {
      const preSelectedClasses = customization.customizations
        .filter((hazardIndex) => hazardIndex?.checked)
        .map((hazardIndex) => hazardIndex?.id);
      setSelected(preSelectedClasses);
    }
  }, [customization]);

  useEffect(() => {
    if (
      sections &&
      sections['report-hazard-assessment'] &&
      sections['report-hazard-assessment'].children &&
      sections['report-hazard-assessment'].children.length
    ) {
      setSelected(sections['report-hazard-assessment'].children.map((hazardId) => +hazardId));
    }
  }, [sections]);

  const handleClick = (id) => {
    if (selected.includes(id)) {
      const filtered = selected.filter((item) => item !== id);
      setSelected(filtered);
      save(filtered);
    } else {
      setSelected([...selected, id]);
      save([...selected, id]);
    }
  };

  switch (page) {
    case 'underwriter':
      return (
        <Content>
          <Header className="report-header">
            <h3>Underwriter Report</h3>
            <Popconfirm
              title="Are you sure to start over?"
              onConfirm={() => {
                clearReportCustomization();
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button secondary style={{ marginLeft: 'auto', marginRight: '20px' }}>
                <Icon type="reload" /> Reset
              </Button>
            </Popconfirm>
            <Button secondary type="primary" onClick={() => setPage('report')}>
              Next <Icon type="right" />
            </Button>
          </Header>
          <main className="underwriter-main">
            <h3>REPORT / HAZARD ASSESSMENT</h3>
            <div className="underwriter-description">
              To tailor this report, <strong>select the insurance classes</strong> for consideration and{' '}
              <strong>add your comments</strong> to each.
            </div>
            <div className="hazard-index-container">
              {hazardIndexes.map((data, index) => (
                <div
                  className={classNames('hazard-index-item-container', { selected: selected.includes(+data.id) })}
                  key={data.id}
                  onClick={() => handleClick(+data.id)}
                >
                  <div className="icon-container">
                    <Icon type="check-circle" theme="filled" style={{ color: '#019cae' }} />
                  </div>
                  <HazardIndexItem appearance="tile" data={data} index={index} underwriter />
                </div>
              ))}
            </div>
          </main>
        </Content>
      );

    case 'report': {
      return (
        <Report
          handleBack={() => {
            setPage('underwriter');
          }}
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
          type="Underwriter Report"
          underwriter
        />
      );
    }
    default:
      return <div />;
  }
};

const mapStateToProps = (state) => ({
  customization: underwriterHazardIndexCustomizationSelector(state),
  hazardIndexes: hazardIndexSelector(state),
  industryId: industryIdSelector(state),
  loadingCustomizations: state.report.loadingCustomizations,
  sections: sectionsSelector(state),
});

const mapDispatchToProps = {
  updateReportCustomizations,
  clearReportCustomization,
  fetchReportCustomization,
  resetHazardIndexChartDataUrl,
  fetchRiskChecklist,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnderwriterReport);
