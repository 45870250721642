import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import { courseGuideSelector } from '../../../../CourseSelectors';

const CourseGuide = ({ contents = [] }) => (
  <div className="course-detail-content card">
    {contents.map((item) => (
      <section key={item.id}>
        <div className="course-detail-title">
          <Icon component={item.icon} className="course-detail-icon" />
          <h2>{item.title}</h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: item.content }} />
      </section>
    ))}
  </div>
);

const mapStateToProps = (state) => ({
  contents: courseGuideSelector(state),
});

export default connect(mapStateToProps)(CourseGuide);
