import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Button, Input, Form, message } from 'antd';
import Layout from '../../../Layout/Layout';
import { resetPassword } from '../../LoginActions';
import './ResetPassword.scss';

const FormItem = Form.Item;

const ResetPassword = ({ form, isResetting, resetPassword }) => {
  const { getFieldDecorator } = form;

  const onSendVerificationEmail = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const { userName } = values;
        try {
          const res = await resetPassword(userName);
          message.success(res);
        } catch (err) {
          message.error(err);
        }
      }
    });
  };

  return (
    <Layout>
      <div className="reset-password card">
        <Row gutter={40}>
          <Col xs={24} sm={12}>
            <p>Please provide the user name of your RiskCoach account.</p>
            <p>We will send you an email that will allow you to reset your password.</p>
          </Col>
          <Col xs={24} sm={12}>
            <Form onSubmit={onSendVerificationEmail}>
              <FormItem hasFeedback>
                {getFieldDecorator('userName', {
                  validateTrigger: 'onBlur',
                  rules: [{ required: true, message: 'Please input your user name.' }],
                })(<Input placeholder="User Name" spellCheck={false} />)}
              </FormItem>
              <FormItem>
                <Button type="primary" htmlType="submit" block loading={isResetting}>
                  {`${isResetting ? `Sending` : `Send`} verification email`}
                </Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  isResetting: state.login.isResetting,
});

const mapDispatchToProps = { resetPassword };

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ResetPassword));
