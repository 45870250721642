import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { DragDropContext } from 'react-beautiful-dnd';
import { message, Spin } from 'antd';
import HazardIndexGroupHeadings from './HazardIndexGroupHeadings';
import HazardIndexGroup from '../HazardIndexGroup/HazardIndexGroup';
import HazardIndexItem from '../HazardIndexItem/HazardIndexItem';
import { hazardIndexSelector, industryIdSelector } from '../../../Industry/IndustrySelectors';
import { hazardIndexCustomizationSelector } from '../../ReportSelectors';
import { updateReportCustomizations } from '../../ReportActions';

const HazardIndexGrouping = ({
  appearance,
  customization,
  hazardIndexes,
  updateReportCustomizations,
  loadingCustomizations,
  industryId,
}) => {
  const [grouping, setGrouping] = useState([]);
  const [placeholderProps, setPlaceholderProps] = useState({});

  useEffect(() => {
    if (customization) {
      setGrouping(customization.grouping);
    }
  }, [customization]);

  const save = async (value) => {
    try {
      await updateReportCustomizations(industryId, customization.id, customization.name, [
        {
          section: 'hazard_index',
          customization: JSON.stringify({
            grouping: value.map((group) => ({
              id: group.id,
              title: group.title,
              classes: group.classes.map(Number),
              seq: group.seq,
            })),
            comments: customization.comments,
          }),
        },
      ]);
      message.success('Saved');
    } catch (err) {
      message.error(err.message);
    }
  };

  const getDraggedDom = (draggableId) => {
    const domQuery = `[data-rbd-drag-handle-draggable-id='${draggableId}']`;
    return document.querySelector(domQuery);
  };

  const handleDragStart = (event) => {
    if (loadingCustomizations) return;

    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index;
    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children].slice(0, sourceIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const handleDragUpdate = (event) => {
    if (!event.destination || loadingCustomizations) {
      return;
    }

    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const handleDragEnd = ({ destination, source, draggableId }) => {
    setPlaceholderProps({});

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const fromGroup = grouping.find((group) => group.id === source.droppableId);
    const toGroup = grouping.find((group) => group.id === destination.droppableId);
    const target = fromGroup.classes.find((itemId) => itemId === draggableId);

    fromGroup.classes.splice(source.index, 1);
    toGroup.classes.splice(destination.index, 0, target);

    save(grouping);
    setGrouping(grouping);
  };

  return (
    <>
      {!customization ? (
        <Spin className="spinner" />
      ) : (
        <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} onDragUpdate={handleDragUpdate}>
          {grouping.map((group) => {
            const hazardIndexItems = group.classes
              .map((itemId) => hazardIndexes.find((hazardIndex) => hazardIndex.id === itemId))
              .filter((i) => i);
            return (
              <HazardIndexGroup
                key={group.id}
                className={classNames({
                  'report-right-sider': appearance === 'list',
                  tailor: appearance === 'page',
                })}
                placeholderProps={placeholderProps}
                droppableId={group.id}
                title={HazardIndexGroupHeadings[group.id]?.title}
                description={HazardIndexGroupHeadings[group.id]?.abstract}
              >
                {hazardIndexItems.map((data, index) => (
                  <HazardIndexItem
                    key={data.id}
                    appearance={appearance === 'list' ? 'row' : 'tile'}
                    data={data}
                    index={index}
                  />
                ))}
              </HazardIndexGroup>
            );
          })}
        </DragDropContext>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  customization: hazardIndexCustomizationSelector(state),
  hazardIndexes: hazardIndexSelector(state),
  industryId: industryIdSelector(state),
  loadingCustomizations: state.report.loadingCustomizations,
});

const mapDispatchToProps = { updateReportCustomizations };

export default connect(mapStateToProps, mapDispatchToProps)(HazardIndexGrouping);
