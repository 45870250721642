import React from 'react';
import { Page } from '../../../../../../../../components/Print/Print';
import { ThemeContext } from '../../../../../../ReportContext';
import useTheme from '../../../../hooks/useTheme';
import './Disclaimer.scss';

export default () => {
  const theme = useTheme(ThemeContext);

  return (
    <Page>
      <div className="report-preview-aon-disclaimer">
        <div
          className="report-preview-disclaimer-content"
          style={{
            fontFamily: theme.body.font,
            fontSize: theme.body.size,
            color: theme.body.color,
          }}
        >
          <h2
            style={{
              fontFamily: theme.headings.h2.font,
              fontSize: theme.headings.h2.size,
              color: theme.headings.h2.color,
            }}
          >
            Usage Notice
          </h2>
          <p>
            The materials contained within this report constitute general information only and should be considered with
            personal financial advice from an Australian Financial Services license holder. It is highly recommended
            that you speak to an insurance professional when setting your insurance program as each policy should be
            tailored to your specific needs.
          </p>
          <p>
            The information provided in this publication is current as at the date of publication and subject to any
            qualifications expressed. Whilst Aon has taken care in the production of this publication and the
            information contained it has been obtained from sources that Aon believes to be reliable, Aon does not make
            any representation as to the accuracy of information received from third parties and is unable to accept
            liability for any loss incurred by anyone who relies on it. The information contained herein is intended to
            provide general insurance related information only. It is not intended to be comprehensive, nor should it
            under any circumstances, be construed as constituting legal or professional advice. You should seek
            independent legal or other professional advice before acting or relying on the content of this information.
            Aon will not be responsible for any loss, damage, cost or expense you or anyone else incurs in reliance on
            or use of any information in this publication.
          </p>
          <p>
            The information contained within the document is the property of The LMI Group and is intended for the
            addressee only. The contents of this document are not to be reproduced, modified or disclosed by its
            recipient without the express permission of the LMI Group. For a copy of our full terms of use, please visit
            our website:{' '}
            <a
              href="https://www.lmigroup.com"
              style={{ color: theme.link.color }}
              target="_blank"
              rel="noopener noreferrer"
            >
              www.lmigroup.com
            </a>{' '}
            AFS License No: 283777.
          </p>
        </div>
      </div>
    </Page>
  );
};
