import React, { useState } from 'react';
import classNames from 'classnames';
import HazardIndexEdit from '../HazardIndexEdit/HazardIndexEdit';
import RiskCheckListEdit from '../RiskCheckListEdit/RiskCheckListEdit';
import './UnderwriterEdit.scss';

const UnderwriterEdit = ({ hazardIndex, close, comment, answers }) => {
  const [tab, setTab] = useState('hazardIndex');

  const handleNext = () => {
    setTab('riskChecklist');
  };

  const handleBack = () => {
    setTab('hazardIndex');
  };

  const isHazardIndexTab = () => tab === 'hazardIndex';

  return (
    <div className="underwirter-edit">
      <div className="content">
        {tab === 'hazardIndex' ? (
          <HazardIndexEdit
            hazardIndex={hazardIndex}
            close={close}
            comment={comment}
            onAddChecklist={handleNext}
            underwriter
          />
        ) : (
          <RiskCheckListEdit hazardIndex={hazardIndex} answers={answers} onUnderwriterComments={handleBack} />
        )}
      </div>
      <div className="dots-container">
        <span className={classNames('dot', { selected: isHazardIndexTab() })} />
        <span className={classNames('dot', { selected: !isHazardIndexTab() })} />
      </div>
    </div>
  );
};

export default UnderwriterEdit;
