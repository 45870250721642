import { REQUEST_POINTS, RECEIVE_POINTS, RECEIVE_PERIOD } from './PointActions';

const initialState = { loadingPoints: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_POINTS:
      return { ...state, loadingPoints: true };
    case RECEIVE_POINTS:
      return { ...state, loadingPoints: false, points: action.points };
    case RECEIVE_PERIOD:
      return { ...state, period: action.period };
    default:
      return state;
  }
};
