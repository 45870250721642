import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List, message } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import Button from '../../../../components/Button/Button';
import { fetchUserReports } from '../../../Account/AccountActions';
import { loadingUserReportsSelector, recentReportsSelector } from '../../../Account/AccountSelectors';

const RecentReports = ({ history, recentReports, loading, fetchUserReports, underwriter }) => {
  const [scrolled, setScrolled] = useState();
  const listContainer = useRef();

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        await fetchUserReports();
      } catch (err) {
        message.error(err.message);
      }
    };
    fetchHistory();

    const handleScroll = (e) => {
      const containerTop = e.srcElement.getClientRects()[0].top;
      const listEl = e.srcElement.querySelector('.ant-list');
      const listTop = listEl.getClientRects()[0].top;
      setScrolled(containerTop > listTop);
    };
    const listContainerRef = listContainer.current;
    if (listContainerRef) {
      listContainerRef.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (listContainerRef) {
        listContainerRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [fetchUserReports]);

  const getDateString = (report) => {
    const localDate = moment(moment.utc(report.rawDate).valueOf());
    return moment().diff(localDate, 'days') > 6 ? report.date : moment(localDate).fromNow();
  };

  return (
    <div className="recent-activities-list-container">
      <div className={classNames('recent-activities-list', { scrolled })} ref={listContainer}>
        <List
          loading={loading}
          dataSource={recentReports}
          renderItem={(item) => (
            <List.Item extra={getDateString(item)}>
              <List.Item.Meta
                title={
                  <Button
                    type="link"
                    secondary
                    onClick={() =>
                      history.push(
                        `/industries/${item.industry_id}/report/${
                          underwriter ? 'underwriter' : 'tailored'
                        }-report?tmpl=${item.id}`
                      )
                    }
                  >
                    {item.industry_name}
                  </Button>
                }
                description={item.name}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  recentReports: recentReportsSelector(state),
  loading: loadingUserReportsSelector(state),
});

const mapDispatchToProps = { fetchUserReports };

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(RecentReports);
