import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Layout, Spin } from 'antd';
import Sider from '../../../../components/Sider/Sider';
import Gallery from './components/Gallery/Gallery';
import { fetchMedia } from '../../IndustryActions';
import {
  industryIdSelector,
  mediaSelector,
  loadingIndustrySelector,
  loadingMediaSelector,
} from '../../IndustrySelectors';
import { IconPhoto, IconVideo } from './assets/icons';

const { Content } = Layout;

const Media = ({ industryId, loading, media = {}, fetchMedia }) => {
  const [selectedKey, setSelectedKey] = useState('image');

  useEffect(() => {
    if (industryId) {
      fetchMedia(industryId);
    }
  }, [industryId, fetchMedia]);

  return (
    <Layout>
      {loading ? (
        <Spin className="component-wrapper" />
      ) : (
        <>
          <Sider
            collapsible
            title="Media"
            menuItems={[
              { id: 'image', name: 'Photographs', icon: IconPhoto },
              { id: 'video', name: 'Video Footage', icon: IconVideo },
            ]}
            selectedMenuItem={selectedKey}
            onMenuClick={({ key }) => setSelectedKey(key)}
          />
          <Content className="component-wrapper">
            <Gallery items={media[selectedKey]} />
          </Content>
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  industryId: industryIdSelector(state),
  media: mediaSelector(state),
  loading: loadingIndustrySelector(state) || loadingMediaSelector(state),
});

const mapDispatchToProps = { fetchMedia };

export default connect(mapStateToProps, mapDispatchToProps)(Media);
