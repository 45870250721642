import React from 'react';
import { connect } from 'react-redux';
import { Heading, Content, List, ListItem } from '../ReportComponent/ReportComponent';
import { sectionsSelector, reportSignificantExposuresSelector } from '../../../../ReportSelectors';

const SignificantExposure = ({ sections, significantExposureList = [] }) =>
  significantExposureList.length > 0 && sections && sections['report-significant-exposures'] ? (
    <section>
      <Heading seq={sections['report-significant-exposures'].seq} anchor="report-significant-exposures">
        Significant Exposures
      </Heading>
      <Content>
        <List data={significantExposureList} renderItem={(item) => <ListItem>{item}</ListItem>} />
      </Content>
    </section>
  ) : null;

const mapStateToProps = (state) => ({
  sections: sectionsSelector(state),
  significantExposureList: reportSignificantExposuresSelector(state),
});

export default connect(mapStateToProps)(SignificantExposure);
