import { APP_INITIALIZING, APP_INITIALIZED } from './AppActions';

const initialState = { initialized: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case APP_INITIALIZING:
      return { ...state, initialized: false };
    case APP_INITIALIZED:
      return { ...state, initialized: true };
    default:
      return state;
  }
};
