import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Layout, Icon, Menu } from 'antd';
import './Sider.scss';

const AntSider = Layout.Sider;
const { SubMenu } = Menu;

const getName = (item) => (item ? item.name || item.title : '');

const renderMenuItem = (item, currentPath) =>
  item.route ? (
    <Link to={`${currentPath}/${item.route}`}>
      {item.icon && <Icon component={item.icon} />}
      <span>{getName(item)}</span>
    </Link>
  ) : (
    <>
      {item.icon && <Icon component={item.icon} />}
      <span>{getName(item)}</span>
      {item.action && item.action}
    </>
  );

const Sider = ({
  children,
  width,
  collapsible,
  title,
  icon,
  description,
  className,
  menuItems,
  showAllNavItems,
  selectedMenuItem,
  onMenuClick,
}) => {
  const { url } = useRouteMatch();

  return (
    <AntSider
      width={width}
      collapsible={collapsible}
      trigger={null}
      breakpoint={collapsible ? 'lg' : null}
      className={classNames('sider', className, { collapsible })}
    >
      {title && <h2 className="sider-title">{title}</h2>}
      {icon &&
        (typeof icon === 'object' ? (
          <Icon component={icon} className="sider-icon" />
        ) : (
          <Icon type={icon} className="sider-icon" />
        ))}
      {description && <p className="sider-description">{description}</p>}
      {menuItems && menuItems.length > 0 && (
        <Menu
          mode="inline"
          inlineIndent={15}
          defaultOpenKeys={menuItems.map((item) => item.id)}
          selectedKeys={[selectedMenuItem || menuItems[0].id]}
          className="sider-menu"
          onClick={onMenuClick}
        >
          {menuItems.map((item) =>
            item.submenu ? (
              <SubMenu key={getName(item)} title={renderMenuItem(item)}>
                {item.submenu.map((submenuItem) => (
                  <Menu.Item
                    key={submenuItem.id}
                    title={getName(submenuItem)}
                    className={classNames({ disabled: !submenuItem?.active && !showAllNavItems })}
                  >
                    {renderMenuItem(submenuItem, url)}
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={item.id || item.route} title={getName(item)}>
                {renderMenuItem(item, url)}
              </Menu.Item>
            )
          )}
        </Menu>
      )}
      {children}
    </AntSider>
  );
};

Sider.propTypes = {
  width: PropTypes.number,
  collapsible: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, icon: PropTypes.object, name: PropTypes.string })
  ),
  selectedMenuItem: PropTypes.string,
  onMenuClick: PropTypes.func,
};

Sider.defaultProps = {
  width: 300,
  collapsible: false,
  title: null,
  icon: null,
  description: null,
  menuItems: null,
  selectedMenuItem: undefined,
  onMenuClick: () => {},
};

export default Sider;
