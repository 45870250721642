import axios from 'axios';
import * as HttpStatus from 'http-status-codes';
import { message } from 'antd';
import {
  fetchTaxonomies,
  fetchUser,
  fetchUserSettings,
  fetchUserProfile,
  fetchUserAvatar,
  fetchCompanies,
  fetchThemes,
} from '../../scenes/Account/AccountActions';
import { logout } from '../Login/LoginActions';

export const APP_INITIALIZING = 'app/APP_INITIALIZING';
export const APP_INITIALIZED = 'app/APP_INITIALIZED';

const API_KEY = process.env.REACT_APP_API_KEY;

const isBase64 = (str) => {
  if (!str || !str.trim()) {
    return false;
  }
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
};

/**
 * Different types of URLs with token:
 *
 * 1. `token`: JWT token. If API key is not presented, use the default one.
 * 2. `token`: Base64 encoded LinkGen parameters acquired from LMI authentication service. API key MUST be presented in query string.
 * 3. `key` and `EncryptedData`: Raw LinkGen parameters. Usually sent from LMI services. If API key is not presented, use the default one.
 *
 */
class AccessRequestUrl {
  constructor() {
    this.url = new URL(window.location);
  }

  get apiKey() {
    // If token is a base64 encoded LinkGen token, API key must be presented as a query string parameter
    const token = this.url.searchParams.get('token');
    if (isBase64(token)) {
      return this.url.searchParams.get('apiKey');
    }
    return API_KEY;
  }

  get linkgenToken() {
    // Handle raw LinkGen parameters
    const key = this.url.searchParams.get('key');
    const encryptedData = this.url.searchParams.get('EncryptedData');
    if (key && encryptedData) {
      return btoa(`key=${key}&EncryptedData=${encodeURIComponent(encryptedData)}`);
    }

    // Handle base64 encoded LinkGen parameters
    const token = this.url.searchParams.get('token');
    if (token && isBase64(token)) {
      return token;
    }

    return localStorage.getItem('linkgen_token');
  }

  get token() {
    return this.url.searchParams.get('token') || localStorage.getItem('token');
  }
}

export const initialize = () => async (dispatch) => {
  const url = new AccessRequestUrl();
  dispatch({ type: APP_INITIALIZING });
  axios.interceptors.response.use(
    (res) => res,
    async ({ response }) => {
      const status = response ? response.status : null;
      switch (status) {
        case HttpStatus.UNAUTHORIZED:
          if (response.request.responseURL !== response.config.url) {
            const redirectRes = await axios({ ...response.config, url: response.request.responseURL });
            return redirectRes;
          }
          dispatch(logout());
          return Promise.reject(response);
        default:
          return Promise.reject(response);
      }
    }
  );

  let token = url.linkgenToken;
  if (token) {
    localStorage.setItem('linkgen_token', token);
    axios.defaults.headers.common.Authorization = `LinkGen ${token}`;
  } else {
    token = url.token;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  axios.defaults.headers.common['x-api-key'] = url.apiKey;
  axios.defaults.headers.common.Accept = 'application/json;v=2.0';

  if (token) {
    localStorage.setItem('token', token);
    try {
      await dispatch(fetchUserAvatar());
    } catch (err) {
      console.error(err);
    }

    const promises = [
      dispatch(fetchTaxonomies()),
      dispatch(fetchUser()),
      dispatch(fetchUserSettings()),
      dispatch(fetchUserProfile()),
      dispatch(fetchCompanies()),
      dispatch(fetchThemes()),
    ];
    try {
      await Promise.all(promises);
    } catch (err) {
      message.error(err.message);
    }
  }
  dispatch({ type: APP_INITIALIZED });
};

export const htmlTemplate = (htmlBody) =>
  htmlBody
    ? `
    <html>
      <head>
        <meta charset="utf-8">
        ${
          process.env.NODE_ENV === 'development'
            ? [...document.head.querySelectorAll('style')].map((item) => item.outerHTML).join('')
            : [...document.head.querySelectorAll('link[rel="stylesheet"]')]
                .map((item) => `<link href="${item.href}" rel="stylesheet" />`)
                .join('')
        }
        <title></title>
      </head>
      <body>
        ${htmlBody}
      </body>
    </html>`
    : null;

export const download = (content, cover, params) => () => {
  const url = process.env.REACT_APP_API_EXPORT;
  return axios.post(
    url,
    { content: htmlTemplate(content), frontMatter: htmlTemplate(cover), format: 'A4', ...params },
    { responseType: 'arraybuffer' }
  );
};
