import moment from 'moment';

export default class Cycle {
  constructor(startDate, endDate, formatString = null, slidingWindowInYears = 1) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.formatString = formatString || 'MM/DD/YYYY';
    this.slidingWindowInYears = slidingWindowInYears;
  }

  get current() {
    return {
      startDate: moment(this.startDate).format(this.formatString),
      endDate: moment(this.endDate).format(this.formatString),
    };
  }

  get last() {
    return {
      startDate: moment(this.startDate).add(-this.slidingWindowInYears, 'year').format(this.formatString),
      endDate: moment(this.endDate).add(-this.slidingWindowInYears, 'year').format(this.formatString),
    };
  }
}
