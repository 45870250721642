import { useContext } from 'react';

export const defaultTheme = (theme) => ({
  covers: [
    {
      bgColor: [theme.colorBg1, theme.colorBg2],
    },
  ],
  body: {
    color: 'rgba(0, 0, 0, 0.8)',
    font: "Roboto, 'Liberation Sans', 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif",
    size: '15px',
  },
  link: {
    color: '#f1c400',
    active: '#f1c400',
    hover: '#f1c400',
    visited: '#f1c400',
  },
  headings: {
    h1: {
      color: theme.colorTitle,
      font: "Roboto, 'Liberation Sans', 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif",
      size: '2em',
    },
    h2: {
      color: theme.colorTitle,
      font: "Roboto, 'Liberation Sans', 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif",
      size: '1.5em',
    },
    pageHeading: {
      color: '#fff',
    },
  },
  visuals: {
    divider: {
      color: theme.colorBar,
      width: '2px', // Default width
    },
  },
});

export default function useTheme(ctx) {
  const theme = useContext(ctx);
  return 'colorBg1' in theme ? defaultTheme(theme) : theme;
}
