import React, { useContext } from 'react';
import classNames from 'classnames';
import { Icon } from 'antd';
import { Droppable } from 'react-beautiful-dnd';
import { isEmpty } from 'lodash';
import { TypeContext } from '../../ReportContext';
import './HazardIndexGroup.scss';

const HazardIndexGroup = ({ droppableId, title, description, className, children, placeholderProps }) => {
  const type = useContext(TypeContext);
  return (
    <div className={classNames('hazard-index-group', className)}>
      <div className="hazard-index-group-header">
        <div className="hazard-index-group-title">{title}</div>
        {type === 'Quick Report' && <div className="hazard-index-group-description">{description}</div>}
      </div>
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => {
          return (
            <ul
              className={classNames('hazard-index-group-item-list', {
                'is-dragging-over': snapshot.isDraggingOver,
              })}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {!children.length && !snapshot.isDraggingOver && (
                <li className="hazard-index-group-placeholder empty">
                  <span>
                    <Icon type="plus" /> Drop here
                  </span>
                </li>
              )}
              {children}
              {provided.placeholder}
              {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                <li
                  className="hazard-index-group-placeholder"
                  style={{
                    top: placeholderProps.clientY,
                    left: placeholderProps.clientX,
                    height: placeholderProps.clientHeight,
                    width: placeholderProps.clientWidth,
                  }}
                >
                  <span>
                    <Icon type="plus" /> Drop here
                  </span>
                </li>
              )}
            </ul>
          );
        }}
      </Droppable>
    </div>
  );
};

export default HazardIndexGroup;
