import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { List, Icon, message, Popconfirm } from 'antd';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import Card from '../../components/Card/Card';
import Item from '../../components/Item/Item';
import Avatar from '../../components/Avatar/Avatar';
import CompanyEdit from './components/CompanyEdit/CompanyEdit';
import { createCompany, updateCompany, updateCompanyLogo, deleteCompany } from '../../AccountActions';
import { companiesSelector, loadingCompaniesSelector, userRolesSelector } from '../../AccountSelectors';
import './Company.scss';

const Company = ({
  companies = [],
  userRoles,
  loading,
  createCompany,
  updateCompany,
  updateCompanyLogo,
  deleteCompany,
}) => {
  const [isManager, setIsManager] = useState(false);

  useEffect(() => {
    if (userRoles) {
      userRoles.forEach((role) => {
        if (role === 'CompanyAdmin') {
          setIsManager(true);
        }
      });
    }
  }, [userRoles]);

  const uploadLogo = async (id, { file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const res = await updateCompanyLogo(id, formData);
      onSuccess(null, file);
      message.success(res);
    } catch (err) {
      onError();
      message.error(err.message);
    }
  };

  const updateProfile = async (id, data) => {
    try {
      const res = await updateCompany(id, data);
      message.success(res);
    } catch (err) {
      message.error(err.message);
    }
  };

  const deleteProfile = async (id) => {
    try {
      const res = await deleteCompany(id);
      message.success(res);
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <div className="component-wrapper">
      <List
        grid
        loading={loading}
        dataSource={companies}
        renderItem={(item) => (
          <List.Item>
            <Card
              title={item.name}
              edit={
                <>
                  {item.is_primary ? null : (
                    <Popconfirm
                      title="Are you sure delete this company?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => deleteProfile(item.id)}
                    >
                      <Icon type="delete" className="account-card-header-icon" />
                    </Popconfirm>
                  )}
                  {item.is_primary && !isManager ? null : (
                    <Modal toggle={(show) => <Icon type="edit" onClick={show} className="account-card-header-icon" />}>
                      {(hide) => (
                        <CompanyEdit
                          isPrimary={item.is_primary}
                          companyProfile={item}
                          update={(data) => updateProfile(item.id, data)}
                          close={hide}
                        />
                      )}
                    </Modal>
                  )}
                </>
              }
              footer={
                <>
                  <h3 className="account-card-footer-title">About Us</h3>
                  <div
                    className="account-card-footer-inner"
                    dangerouslySetInnerHTML={{ __html: item.description || 'No Content' }}
                  />
                </>
              }
            >
              <div className="item-container">
                <Avatar
                  icon="home"
                  shape="rectangle"
                  src={item.logo}
                  transparent={!!item.logo}
                  editable={!item.is_primary}
                  upload={(data) => uploadLogo(item.id, data)}
                />

                <Item label="Name">
                  <div>{item.name}</div>
                </Item>
              </div>
            </Card>
          </List.Item>
        )}
      />
      {!loading && companies.length < 2 && (
        <Card title="Create a Company Proﬁle">
          <div className="item-container">
            <Modal
              toggle={(show) => (
                <Button
                  type="primary"
                  size="large"
                  shape="circle"
                  icon="plus"
                  title="Add another company"
                  className="create-button"
                  onClick={show}
                />
              )}
            >
              {(hide) => <CompanyEdit update={createCompany} close={hide} />}
            </Modal>
            Here you can create your own company proﬁle. Your company proﬁle logo and contents will be displayed in your
            reports.
          </div>
        </Card>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  companies: companiesSelector(state),
  loading: loadingCompaniesSelector(state),
  userRoles: userRolesSelector(state),
});

const mapDispatchToProps = { createCompany, updateCompany, updateCompanyLogo, deleteCompany };

export default connect(mapStateToProps, mapDispatchToProps)(Company);
