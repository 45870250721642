import React from 'react';
import { Typography } from 'antd';
import Button from '../../../../components/Button/Button';
import './Content.scss';

const { Title, Text, Paragraph } = Typography;

export default ({ note, editable, onBack }) => (
  <div className="note-content">
    <div className="note-content-back">
      <Button type="link" icon="left" onClick={onBack}>
        Back
      </Button>
    </div>
    {note.title && <Title level={3}>{note.title}</Title>}
    {note.user && <Title level={4}>{note.user}</Title>}
    {note.date && <Text type="secondary">{note.date}</Text>}
    <Paragraph editable={editable}>{note.content}</Paragraph>
  </div>
);
