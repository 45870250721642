/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-return-assign */
import React, { Fragment, useContext, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { intersectionBy } from 'lodash';
import { Heading, Content, Figure, Table } from '../ReportComponent/ReportComponent';
import HazardIndexGroupHeadings from '../../../../components/HazardIndexGrouping/HazardIndexGroupHeadings';
import HazardIndexChart from '../../../../../Industry/scenes/HazardIndex/components/Chart/Chart';
import { setHazardIndexChartDataUrl, setHazardIndexGroupChartDataUrl } from '../../../../ReportActions';
import { hazardIndexSelector } from '../../../../../Industry/IndustrySelectors';
import {
  sectionsSelector,
  reportHazardIndexSelector,
  hazardIndexCustomizationSelector,
  underwriterHazardIndexCustomizationSelector,
} from '../../../../ReportSelectors';
import { isUnderwriterSelector } from '../../../../../Account/AccountSelectors';
import { TypeContext } from '../../../../ReportContext';
import './HazardAssessment.scss';

const HazardAssessment = ({
  sections,
  hazardIndex = [],
  filteredHazardIndex = [],
  figureSeq,
  hazardIndexChartDataUrl,
  hazardIndexGroupChartDataUrl,
  setHazardIndexChartDataUrl,
  setHazardIndexGroupChartDataUrl,
  hazardIndexCustomization,
  underwriterHazardIndexCustomization,
  underwriter,
}) => {
  let subSeq = 0;
  const type = useContext(TypeContext);
  const grouping = ['recommended', 'for review', 'for consideration'];
  const harzardIndexTableWrapperRef = useRef([]);
  const tailoredReportGroupRef = useRef([]);
  const sectionRef = useRef();

  useEffect(() => {
    harzardIndexTableWrapperRef.current = new Array(grouping.length);
    tailoredReportGroupRef.current = new Array(grouping.length);
  }, []);

  const onChartReady = (chart) => {
    setHazardIndexChartDataUrl(chart.getDataURL());
  };

  const onGroupChartReady = (chart) => {
    setHazardIndexGroupChartDataUrl(chart.getDataURL());
  };

  const renderHazardIndexChart = () => {
    if (type === 'Quick Report') {
      if (hazardIndexChartDataUrl) {
        return <img width={680} src={hazardIndexChartDataUrl} alt="hazard-index" />;
      }
      return (
        <HazardIndexChart
          data={[...hazardIndex].reverse()}
          direction="horizontal"
          print
          onChartReady={onChartReady}
          showTitle={false}
        />
      );
    }

    if (underwriter) {
      if (hazardIndexChartDataUrl) {
        return <img width={680} src={hazardIndexChartDataUrl} alt="hazard-index" />;
      }

      const parsedSelected = underwriterHazardIndexCustomization.customizations.map(
        (section) =>
          section.checked && {
            ...section,
            id: `${section.id}`,
          }
      );
      const selectedIndexes = intersectionBy(hazardIndex, parsedSelected, 'id');

      return (
        <HazardIndexChart
          data={[...selectedIndexes].reverse()}
          direction="horizontal"
          print
          onChartReady={onChartReady}
        />
      );
    }

    if (type === 'Tailored Report') {
      if (hazardIndexGroupChartDataUrl) {
        return hazardIndexGroupChartDataUrl.map((url) => <img key={url} width={680} src={url} alt="hazard-index" />);
      }
      return hazardIndexCustomization.grouping
        .filter((g) => g.classes.length)
        .map(({ id, classes }) => {
          const hazardIndexList = classes.map((classId) => filteredHazardIndex.find(({ id }) => id === classId));
          return (
            <HazardIndexChart
              key={id}
              data={[...hazardIndexList].reverse()}
              direction="horizontal"
              print
              onChartReady={onGroupChartReady}
              title={HazardIndexGroupHeadings[id]?.title}
            />
          );
        });
    }
    return null;
  };

  return hazardIndex.length > 0 && sections && sections['report-hazard-assessment'] ? (
    <section ref={sectionRef}>
      <Heading seq={sections['report-hazard-assessment'].seq} anchor="report-hazard-assessment">
        Hazard Assessment
      </Heading>
      <Content>
        <div className="hazard-index-rating">
          <p className="hazard-index-rating-note">
            Identifying hazards in the workplace involves finding things and situations that could potentially cause
            harm to the organisation. The following chart is a graphical representation of the likelihood and severity
            of a loss occurring within any of the classes of insurance listed in the chart.
          </p>

          <Figure seq={figureSeq} caption="Hazard Index">
            {renderHazardIndexChart()}
          </Figure>

          <p className="hazard-index-note">
            <strong>Please note: </strong>
            {type === 'Tailored Report' && (
              <>
                The chart has been divided into a maximum of three (3) groups. These groups should be observed carefully
                as they reflect your Insurance Advisors assessment of your organisations Insurance needs.{' '}
              </>
            )}
            These ratings have been developed using historical claims and loss data for a general business operating in
            this field. It is recommended that additional factors specific to your business be taken into account when
            assessing this information such as location and crime rates.
          </p>
        </div>
        {type === 'Tailored Report' &&
          !underwriter &&
          filteredHazardIndex.length > 0 &&
          hazardIndexCustomization.grouping
            .filter((g) => g.classes.length)
            .map((group, groupIndex) => (
              <div key={group.id} className="report-preview-no-break">
                <div
                  ref={(el) => {
                    tailoredReportGroupRef.current[groupIndex] = el;
                  }}
                >
                  <Heading level={2} seq={`${sections['report-hazard-assessment'].seq}.${(subSeq += 1)}`}>
                    {HazardIndexGroupHeadings[group.id]?.title}
                  </Heading>
                  {HazardIndexGroupHeadings[group.id]?.description &&
                    HazardIndexGroupHeadings[group.id]?.description.map((paragraph) => (
                      <p key={HazardIndexGroupHeadings[group.id]}>{paragraph}</p>
                    ))}
                </div>
                <div
                  className="hazard-assessment-index-table-offset-wrapper"
                  ref={(el) => {
                    harzardIndexTableWrapperRef.current[groupIndex] = el;
                  }}
                >
                  {group.classes.map((itemId, tableIndex) => {
                    const hazardIndex = filteredHazardIndex.find((hazardIndex) => hazardIndex.id === itemId);
                    const advisorComment = hazardIndexCustomization.comments
                      ? hazardIndexCustomization.comments.find((comment) => comment.class === Number(hazardIndex.id))
                          ?.comment
                      : undefined;
                    return (
                      <Fragment key={itemId}>
                        <Table
                          data={hazardIndex}
                          className="hazard-index-table"
                          advisorComment={advisorComment}
                          seq={groupIndex}
                          subSeq={tableIndex}
                          type="Tailored Report"
                          tailoredReportGroupOffsetHeight={tailoredReportGroupRef.current[groupIndex]?.offsetHeight}
                          sectionOffsetHeight={sectionRef.current?.offsetHeight}
                          harzardIndexTableWrapperRef={harzardIndexTableWrapperRef.current[groupIndex]}
                        />
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            ))}
        {type === 'Quick Report' && !underwriter && (
          <div
            className="hazard-assessment-index-table-offset-wrapper"
            ref={(el) => {
              harzardIndexTableWrapperRef.current[0] = el;
            }}
          >
            {filteredHazardIndex.map((item, tableIndex) => (
              <Fragment key={item.id}>
                <Table
                  data={item}
                  className="hazard-index-table"
                  seq={tableIndex}
                  sectionOffsetHeight={sectionRef.current?.offsetHeight}
                  harzardIndexTableWrapperRef={harzardIndexTableWrapperRef.current[0]}
                />
              </Fragment>
            ))}
          </div>
        )}
        {underwriter && (
          <div
            className="hazard-assessment-index-table-offset-wrapper"
            ref={(el) => {
              harzardIndexTableWrapperRef.current[0] = el;
            }}
          >
            {filteredHazardIndex.map((item, tableIndex) => {
              const foundClass = underwriterHazardIndexCustomization?.customizations.find(
                (hazardIndex) => hazardIndex.id === Number(item.id)
              );

              return (
                <Fragment key={item.id}>
                  <Table
                    data={item}
                    className="hazard-index-table"
                    advisorComment={foundClass?.comments}
                    riskChecklist={foundClass?.checklist}
                    seq={tableIndex}
                    underwriter
                    sectionOffsetHeight={sectionRef.current?.offsetHeight}
                    harzardIndexTableWrapperRef={harzardIndexTableWrapperRef.current[0]}
                  />
                </Fragment>
              );
            })}
          </div>
        )}
      </Content>
    </section>
  ) : null;
};

const mapStateToProps = (state) => ({
  sections: sectionsSelector(state),
  hazardIndex: hazardIndexSelector(state),
  filteredHazardIndex: reportHazardIndexSelector(state),
  hazardIndexChartDataUrl: state.report.hazardIndexChartDataUrl,
  hazardIndexGroupChartDataUrl: state.report.hazardIndexGroupChartDataUrl,
  hazardIndexCustomization: hazardIndexCustomizationSelector(state),
  underwriterHazardIndexCustomization: underwriterHazardIndexCustomizationSelector(state),
  underwriter: isUnderwriterSelector(state),
});

const mapDispatchToProps = { setHazardIndexChartDataUrl, setHazardIndexGroupChartDataUrl };

export default connect(mapStateToProps, mapDispatchToProps)(HazardAssessment);
