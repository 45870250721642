export default {
  recommended: {
    title: 'Recommended',
    abstract: 'Classes of insurance you recommend your Client add to their insurance program.',
    description: [
      'The following class(es) of insurance offer critical protection from risks currently faced by your organisation. It is recommended that each should be thoroughly reviewed and considered for inclusion in your insurance program.',
    ],
  },
  for_review: {
    title: 'For Review - Currently Insured',
    abstract:
      'Classes of insurance your Client has included within their current insurance program which should be reviewed for adequacy and scope of cover.',
    description: [
      'The following class(es) of insurance are included within your existing insurance program. It is important that limits and extensions are reviewed to ensure that your insurances remain adequate and continue to protect your organisation as it grows and adapts.',
    ],
  },
  for_consideration: {
    title: 'For Consideration – Not Currently Insured',
    abstract:
      'Classes of insurance that you do not feel need to be included within your Client’s insurance program but should be considered to ensure the risks of not taking out the cover are assessed.',
    description: [
      'The following class(es) of insurance do not form part of your existing insurance program. While not listed as a high priority for inclusion in this year’s insurances, it is recommended that you consider each of them based on the risks they pose to your organisation and your organisation’s risk appetite.',
      'Remember if it is not insured, you self-insure it and leave any potential loss to be financed by you.',
    ],
  },
};
