import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Empty } from 'antd';
import './Table.scss';

const Table = ({ title, columns, dataSource, rowKey, bordered, align, vertical, emptyText, className }) => (
  <table className={classNames('table', align, className, { bordered })}>
    {title && <caption>{title}</caption>}
    <colgroup>
      {columns.map((item) => (
        <col key={item.key} width={item.width} />
      ))}
    </colgroup>
    <thead>
      <tr>
        {columns.map((item) => (
          <th key={item.key}>{item.title}</th>
        ))}
      </tr>
    </thead>
    <tbody style={{ verticalAlign: vertical }}>
      {dataSource && dataSource.length > 0 ? (
        dataSource.map((row) => (
          <tr key={rowKey ? row[rowKey] : row[columns[0].dataIndex]}>
            {columns.map((col) =>
              col.render ? (
                <td key={col.key}>{col.render(row)}</td>
              ) : (
                <td key={col.key} dangerouslySetInnerHTML={{ __html: row[col.dataIndex] }} />
              )
            )}
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={columns.length}>
            <Empty description={emptyText} />
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

Table.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      dataIndex: PropTypes.string,
      render: PropTypes.func,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  dataSource: PropTypes.array,
  rowKey: PropTypes.string,
  bordered: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  vertical: PropTypes.oneOf(['top', 'middle', 'bottom']),
  emptyText: PropTypes.string,
  className: PropTypes.string,
};

Table.defaultProps = {
  title: null,
  columns: [],
  dataSource: [],
  rowKey: null,
  bordered: true,
  align: 'left',
  vertical: 'middle',
  emptyText: 'No Data',
  className: null,
};

export default Table;
