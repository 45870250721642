/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import RichTextEditor from 'react-rte';
import './TextEditor.scss';

const EMPTY_STRING = '<p><br></p>';

class TextEditor extends Component {
  static propTypes = {
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
        ? RichTextEditor.createValueFromString(this.props.value, 'html')
        : RichTextEditor.createEmptyValue(),
    };
  }

  onChange = (value) => {
    this.setState({ value });

    if (this.props.onChange) {
      this.props.onChange(value.toString('html'));
      if (value.toString('html') === EMPTY_STRING) this.props.onChange('');
    }
  };

  render() {
    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading Large', style: 'header-one' },
        { label: 'Heading Medium', style: 'header-two' },
        { label: 'Heading Small', style: 'header-three' },
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' },
      ],
    };
    return (
      <RichTextEditor
        className="text-editor"
        toolbarConfig={toolbarConfig}
        value={this.state.value}
        onChange={this.onChange}
        placeholder={this.props.placeholder}
      />
    );
  }
}

export default TextEditor;
