import { createSelector } from 'reselect';
import _ from 'lodash';
import icons from '../../assets/icons/InsuranceClass';
import { CLASSES_TO_EXCLUDE_FROM_REPORT } from '../Document/DocumentSelectors';
import { countryCodeSelector } from '../Account/AccountSelectors';

export const loadingIndustryCategoriesSelector = (state) => state.industry.loadingIndustryCategories;

export const industryCategoryListSelector = (state) => state.industry.industryCategoryList;

export const loadingIndustrySelector = (state) => state.industry.loadingIndustry;

export const industryInfoSelector = (state) => state.industry.industryInfo;

export const industryIdSelector = createSelector(industryInfoSelector, (industryInfo) =>
  industryInfo ? industryInfo.id : null
);

export const industryNameSelector = createSelector(industryInfoSelector, (industryInfo) =>
  industryInfo ? industryInfo.name : ''
);

export const hasMarketSelector = createSelector(industryInfoSelector, (industryInfo) =>
  industryInfo ? industryInfo.has_market : null
);

export const industryQuizzesSelector = createSelector(industryInfoSelector, (industryInfo) =>
  industryInfo ? industryInfo.quizzes : null
);

export const loadingHazardIndexSelector = (state) => state.industry.loadingHazardIndex;

export const hazardIndexSelector = createSelector(
  (state) => state.industry.hazardIndex,
  countryCodeSelector,
  (hazardIndex, countryCode) =>
    hazardIndex
      ? hazardIndex
          .filter((item) => {
            if (countryCode.toLowerCase() !== 'au') {
              return !CLASSES_TO_EXCLUDE_FROM_REPORT.includes(item.insurance_class.slug);
            }
            return true;
          })
          .map((item) => ({
            id: `${item.insurance_class.id}`,
            name: item.insurance_class.name,
            rating: item.rating,
            comment: item.comment,
            slug: item.insurance_class.slug,
            classes: item.insurance_class.includes,
            icon: icons[item.insurance_class.slug],
          }))
      : undefined
);

export const hazardIndexClassesSelector = createSelector(hazardIndexSelector, (hazardIndex) =>
  hazardIndex
    ? hazardIndex.map((item) => ({ id: item.id, name: item.name, slug: item.slug, icon: item.icon }))
    : undefined
);

export const loadingSignificantExposuresSelector = (state) => state.industry.loadingSignificantExposures;

export const significantExposuresSelector = (state) => state.industry.significantExposureList;

export const loadingRiskChecklistSelector = (state) => state.industry.loadingRiskChecklist;

export const riskChecklistSelector = createSelector(
  (state) => state.industry.riskChecklist,
  (riskChecklist) =>
    riskChecklist
      ? _.chain(riskChecklist)
          .uniqBy('id')
          .groupBy((item) => item.section.name)
          .map((riskChecklist, section) => ({ section, riskChecklist }))
          .value()
      : undefined
);

export const loadingMaterialsEquipmentsSelector = (state) => state.industry.loadingMaterialsEquipments;

export const materialEquipmentListSelector = (state) => state.industry.materialEquipmentList;

export const relatedIndustriesSelector = createSelector(industryInfoSelector, (industryInfo) =>
  industryInfo ? industryInfo.related_industries : undefined
);

const FIRST = 'LMI Group Industry Code';
const SECOND = 'Australian New Zealand Standard Industry Classification ("ANZSIC") Code(s) - 2006';

export const industryCodesSelector = createSelector(industryInfoSelector, (industryInfo) =>
  industryInfo
    ? _.chain(industryInfo.classifications)
        .map((item) => ({ code: item.code, name: item.name, taxonomy: item.taxonomy.name }))
        .groupBy('taxonomy')
        .map((codes, taxonomy) => ({ taxonomy, codes }))
        .value()
        // eslint-disable-next-line no-nested-ternary
        .sort((a, b) => (a.taxonomy === SECOND ? -1 : b.taxonomy === SECOND ? 1 : 0))
        // eslint-disable-next-line no-nested-ternary
        .sort((a, b) => (a.taxonomy === FIRST ? -1 : b.taxonomy === FIRST ? 1 : 0))
    : undefined
);

const COUNTRIES = Object.freeze({ au: 'Australia', nz: 'New Zealand' });

export const indicativeRateSelector = createSelector(industryInfoSelector, (industryInfo) =>
  industryInfo
    ? _.chain(industryInfo.indicative_rates)
        .groupBy('country')
        .map((item, country) => {
          const totalWeight = item.reduce((result, value) => result + value.weight, 0);
          return {
            country: COUNTRIES[country.toLowerCase()],
            value: item.reduce((result, value) => result + (value.avg * value.weight) / totalWeight, 0).toFixed(2),
          };
        })
        .sortBy('country')
        .value()
    : []
);

export const indicativeRateCountryCodeSelector = createSelector(industryInfoSelector, (industryInfo) =>
  industryInfo
    ? _.chain(industryInfo.indicative_rates)
        .groupBy('country')
        .map((item, country) => {
          const totalWeight = item.reduce((result, value) => result + value.weight, 0);
          return {
            country: COUNTRIES[country.toLowerCase()],
            countryCode: country.toLowerCase(),
            value: item.reduce((result, value) => result + (value.avg * value.weight) / totalWeight, 0).toFixed(2),
          };
        })
        .sortBy('country')
        .value()
    : []
);

export const loadingEndorsementsSelector = (state) => state.industry.loadingEndorsements;

export const endorsementsSelector = createSelector(
  (state) => state.industry.endorsementList,
  (endorsementList) =>
    endorsementList
      ? _.chain(endorsementList)
          .groupBy((item) => item.type.name)
          .map((items, type) => ({ type, items }))
          .sortBy('type')
          .value()
      : undefined
);

export const loadingMediaSelector = (state) => state.industry.loadingMedia;

export const mediaSelector = createSelector(
  (state) => state.industry.media,
  (media) =>
    media
      ? _.chain(media)
          .map((item) => ({ ...item, type: item.content_type.split('/')[0] }))
          .groupBy('type')
          .value()
      : undefined
);
