import { createSelector } from 'reselect';
import _ from 'lodash';
import * as icons from './assets/icons';
import covers from './assets/covers';

export const loadingCoursesSelector = (state) => state.course.loadingCourses;

export const coursesSelector = createSelector(
  (state) => state.course.courses,
  (courses) => _.map(courses, (item) => ({ ...item, cover: covers[item.name.toLowerCase()] }))
);

export const loadingCourseSelector = (state) => state.course.loadingCourse;

const courseSelector = (state) => state.course.course;

export const courseIdSelector = createSelector(courseSelector, (course) => (course ? course.id : null));

export const courseNameSelector = createSelector(courseSelector, (course) => (course ? course.name : ''));

export const courseCoverSelector = createSelector(courseNameSelector, (courseName) => covers[courseName.toLowerCase()]);

const getCourseGuideIcon = (title) => {
  switch (title) {
    case 'Subject Outline':
      return icons.IconCourseOverview;
    case 'Learning Objectives':
      return icons.IconCourseObjectives;
    case 'Course Introduction':
      return icons.IconCourseIntro;
    case 'Course Summary':
      return icons.IconCourseSummary;
    default:
      return icons.IconCourseContent;
  }
};

export const courseGuideSelector = createSelector(courseSelector, (course) =>
  course
    ? _.chain(course.contents)
        .filter('content')
        .map((item) => ({ ...item, icon: getCourseGuideIcon(item.title) }))
        .value()
    : undefined
);

export const courseUnitsSelector = createSelector(courseSelector, (course) =>
  course
    ? _.chain(course.units)
        .orderBy('sequence')
        .map((item) => ({ ...item, id: `${item.id}` }))
        .value()
    : undefined
);

export const loadingUnitSelector = (state) => state.course.loadingUnit;

export const unitSelector = (state) => state.course.unit;

export const unitMediaSelector = createSelector(unitSelector, (unit) => (unit ? unit.media : undefined));
