import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spin, Empty } from 'antd';
import _ from 'lodash';
import Icon from '../Icon/Icon';
import './Cascader.scss';

const DEFAULT_FIELD_NAMES = Object.freeze({ label: 'label', children: 'children' });

const Cascader = ({ meta, options, fieldNames, loadData, select }) => {
  const [renderList, setRenderList] = useState([{ title: meta[0], options: [...options] }]);
  const [activeLevel, setActiveLevel] = useState(meta[0]);
  const [activeValues, setActiveValues] = useState([]);

  const getFieldName = (name) => fieldNames[name] || DEFAULT_FIELD_NAMES[name];

  const getKey = (item) => item.code;

  const getName = (item) => item.name || item[getFieldName('label')];

  const getValue = (item) => `${item.code}_${getName(item)}`;

  const onSelect = async (selectedLevelIndex, selectedItem) => {
    if (selectedItem.isLeaf) {
      select(selectedItem.id);
      return;
    }

    const title = meta[selectedLevelIndex + 1] || 'Industries';
    const nextLevelOptions = selectedItem[getFieldName('children')];
    let options = [];

    setRenderList([...renderList.slice(0, selectedLevelIndex + 1), { title, loading: true, options }]);
    setActiveValues([...activeValues.slice(0, selectedLevelIndex), getValue(selectedItem)]);

    if (nextLevelOptions) {
      options = nextLevelOptions;
    } else {
      const { code } = selectedItem;
      const res = await loadData(code);
      options = res.map((item) => ({ ...item, isLeaf: true }));
    }

    setRenderList([...renderList.slice(0, selectedLevelIndex + 1), { title, loading: false, options }]);
    setActiveLevel(title);
  };

  return (
    <div className="cascader">
      {renderList.map((level, index) => (
        <div
          key={level.title}
          className={classNames('cascader-level', 'card', { active: activeLevel === level.title })}
          style={{ minWidth: `${100 / (meta.length + 1)}%` }}
        >
          <h3 className="cascader-title">{level.title}</h3>
          {level.loading ? (
            <Spin className="component-wrapper" />
          ) : (
            <>
              {level.options && level.options.length > 0 ? (
                <ul className="cascader-menu">
                  {level.options.map((item) => (
                    <li
                      key={getKey(item)}
                      title={getName(item)}
                      role="menuitem"
                      className={classNames('cascader-menu-item', {
                        active: _.includes(activeValues, getValue(item)),
                      })}
                      onKeyDown={(e) => e.preventDefault()}
                      onClick={() => onSelect(index, item)}
                    >
                      <div className="cascader-menu-item-inner">
                        {item.icon && <Icon component={item.icon} />}
                        <span>{`${getKey(item)} - ${getName(item)}`}</span>
                        {!item.isLeaf && <Icon type="right" className="cascader-menu-item-icon" />}
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <Empty description="No industry write up" />
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

Cascader.propTypes = {
  meta: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  fieldNames: PropTypes.object,
  loadData: PropTypes.func,
  select: PropTypes.func,
};

Cascader.defaultProps = {
  fieldNames: DEFAULT_FIELD_NAMES,
  loadData: null,
  select: null,
};

export default Cascader;
