import React, { Fragment } from 'react';
import classNames from 'classnames';
import './Table.scss';

const tableHeader = [
  '',
  'Value $million',
  'Documentation Period (weeks)',
  'Construction Period (weeks)',
  'Project Period (weeks)',
];
const tableData = [
  {
    title: 'Simple Project',
    description: 'eg. warehouses, etc.',
    color: '#a3a0fb',
    data: [
      ['1.5 - 3', '4 - 9', '17 - 26', '21 - 34'],
      ['3 - 5', '7 - 10', '26 - 43', '32 - 53'],
    ],
  },
  {
    title: 'Average Projects',
    description: 'eg. multi-storey offices, units, etc.',
    color: '#56d9fe',
    highlight: true,
    data: [
      ['9 - 15', '26 - 34', '43 - 60', '68 - 94'],
      ['25 - 45', '34 - 43', '85 - 128', '119 - 170'],
      ['100 - 125', '51 - 77', '111 - 136', '162 - 221'],
    ],
  },
  {
    title: 'Complex Projects',
    description: 'eg. concert halls, hospitals, art galleries, etc.',
    color: '#ff8373',
    data: [
      ['3', '12', '26', '37'],
      ['4 - 8', '15 - 21', '34 - 51', '49 - 72'],
      ['15 - 25', '43 - 51', '51 - 68', '94 - 119'],
      ['50 - 100', '68 - 85', '128 - 170', '196 - 255'],
    ],
  },
];

export default () => (
  <table className="building-cost-calculator-table card">
    <colgroup>
      {tableHeader.map((item) => (
        <col key={item} />
      ))}
    </colgroup>
    <thead>
      <tr>
        {tableHeader.map((item) => (
          <th key={item}>{item}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {tableData.map((item) => (
        <Fragment key={item.title}>
          <tr className={classNames({ highlight: item.highlight })}>
            <td rowSpan={item.data.length} style={{ borderRight: `10px solid ${item.color}` }}>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </td>
            {item.data[0].map((col) => (
              <td key={col}>{col}</td>
            ))}
          </tr>
          {item.data.slice(1).map((row) => (
            <tr key={row} className={classNames({ highlight: item.highlight })}>
              {row.map((col) => (
                <td key={col}>{col}</td>
              ))}
            </tr>
          ))}
        </Fragment>
      ))}
    </tbody>
  </table>
);
