import React from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import Button from '../../../../components/Button/Button';
import './ThemeSelect.scss';

const { Option } = Select;

const getKey = (item, index) => `${index}_${item}`;

const getOptions = ({ value }) => {
  const colors =
    'colorBg1' in value
      ? value
      : [value.headings.h1.color, value.covers[0].bgColor[0], value.covers[0].bgColor[1], value.visuals.divider.color];

  return _.values(colors).map((color, index) => (
    <div key={getKey(color, index)} style={{ backgroundColor: color }} className="report-theme-select-colors" />
  ));
};

export default ({ themes, selectedTheme, onThemeSelect }) => (
  <div className="report-theme-select">
    <Select size="large" value={selectedTheme.id} onChange={onThemeSelect}>
      {themes.map((item) => (
        <Option key={item.id}>
          <div title={item.label} className="report-theme-select-option">
            {getOptions(item)}
          </div>
        </Option>
      ))}
    </Select>
    <Button
      type="primary"
      href="https://lmigroup.io/product-tag/eservice-customisation/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Custom theme $499
    </Button>
  </div>
);
