import React from 'react';
import classNames from 'classnames';
import { List, Avatar } from 'antd';
import Button from '../../../../components/Button/Button';
import Icon from '../../../../components/Icon/Icon';
import './Navigation.scss';

export default ({ notes, searchable, selectedKey, onSelect }) => (
  <div className="note-navigation">
    <div className="note-navigation-header">
      <h1>Notes</h1>
      {searchable && <Button type="link" shape="circle" icon="search" />}
    </div>
    <List
      itemLayout="horizontal"
      dataSource={notes}
      split={false}
      className="note-navigation-list"
      renderItem={(item, index) => (
        <List.Item
          className={classNames('note-navigation-list-item', { active: selectedKey === index })}
          onClick={() => onSelect(index)}
        >
          <List.Item.Meta
            avatar={
              item.user ? (
                <Avatar shape="square" size={40} className="note-avatar">
                  {item.user.charAt(0).toUpperCase()}
                </Avatar>
              ) : (
                <Icon component={item.icon} size={40} shape="rounded" />
              )
            }
            title={item.menuTitle || item.title}
            description={item.date}
          />
        </List.Item>
      )}
    />
  </div>
);
