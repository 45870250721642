import React from 'react';
import classNames from 'classnames';
import './Print.scss';

export const Article = ({ children, className }) => (
  <article className={classNames('print-article', className)}>{children}</article>
);

export const Page = ({ children, className }) => (
  <section className={classNames('print-page', className)}>{children}</section>
);

export const DynamicPage = ({ children, className }) => (
  <main className={classNames('print-dynamic-page', className)}>{children}</main>
);

export const Paragraph = ({ children, className }) => (
  <div className={classNames('print-paragraph', className)}>{children}</div>
);

export const PageBreak = () => <div className="print-page-break" />;
