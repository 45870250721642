import React from 'react';
import { connect } from 'react-redux';
import { themeSelector } from '../../scenes/Account/AccountSelectors';

const Theme = ({ children, theme }) => (
  <div id="app-root" className={`theme-${theme}`}>
    {children}
  </div>
);

const mapStateToProps = (state) => ({
  theme: themeSelector(state),
});

export default connect(mapStateToProps)(Theme);
