import React from 'react';
import { Empty } from 'antd';

const UnderwriterUnderConstruction = () => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
    <Empty description={<span>Coming soon...</span>} />
  </div>
);

export default UnderwriterUnderConstruction;
