/* eslint-disable no-useless-concat */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import React from 'react';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { graphic } from 'echarts';
import { renderChartColor, getChartOffset, COLOR_RATING_LOW } from '../../../../utils/renderColor';
import './Chart.scss';

export default ({
  data = [],
  direction = 'vertical',
  print = false,
  className,
  onChartReady,
  title,
  showTitle = true,
}) => {
  const hazardClasses = data.map((item) => item.name);
  const hazardRatings = data.map((item) => item.rating);
  const vertical = direction === 'vertical';
  const xAxis = {
    type: 'category',
    data: hazardClasses,
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      width: 10,
      overflow: 'breakAll',
      // interval: 0,
      rotate: vertical ? 45 : 0,
      // align: 'right',
      // verticalAlign: 'middle',
      // backgroundColor: 'red',
      // formatter: function (value) {
      //   const date = new Date();
      //   const yearStart = new Date();
      //   yearStart.setMonth(0);
      //   yearStart.setDate(1);
      //   yearStart.setHours(0);
      //   yearStart.setMinutes(0);
      //   yearStart.setSeconds(0);
      //   yearStart.setMilliseconds(0);
      //   // Whether a tick value is the start of a year
      //   if (date.getTime() === yearStart.getTime()) {
      //     return `{year|${date.getFullYear()}}\n` + `{month|${date.getMonth() + 1}月}`;
      //   }

      //   return `{month|${date.getMonth() + 1}月}`;
      // },
      // rich: {
      //   year: {
      //     color: '#000',
      //     fontWeight: 'bold',
      //   },
      //   month: {
      //     color: '#999',
      //   },
      // },
      // formatter: function (value) {
      //   return `{category|${value}}`;
      // },
      // rich: { category: { color: 'blue', width: 20, overflow: 'break' } },
    },
    splitLine: { show: false },
  };

  const yAxis = {
    type: 'value',
    max: 100,
    min: 0,
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: { formatter: '{value}%' },
    splitLine: { show: false },
  };
  const option = {
    title: {
      show: showTitle,
      text: title || 'HAZARD INDEX RATING',
      left: 'center',
      top: 40,
      textStyle: { color: '#003262', fontSize: 16 },
    },
    grid: { top: showTitle ? 100 : 10, left: vertical ? '10%' : '40%', containLabel: vertical },
    xAxis: vertical ? xAxis : yAxis,
    yAxis: vertical ? yAxis : xAxis,
    series: [
      {
        type: 'bar',
        barGap: '-100%',
        barCategoryGap: '40%',
        animation: false,
        data: hazardRatings.map(() => 100),
        itemStyle: { normal: { color: '#f1f4fb' }, emphasis: { color: '#f1f4fb' } },
        barWidth: vertical ? null : 24,
      },
      {
        type: 'bar',
        z: 3,
        animation: !print,
        data: hazardRatings,
        itemStyle: {
          color: !vertical
            ? ({ value }) =>
                new graphic.LinearGradient(1, 1, 0, 0.6, [
                  {
                    offset: getChartOffset(value, 2),
                    color: renderChartColor(value),
                  },
                  {
                    offset: getChartOffset(value, 1),
                    color: renderChartColor(value, 'medium'),
                  },
                  {
                    offset: getChartOffset(value, 0),
                    color: COLOR_RATING_LOW,
                  },
                ])
            : ({ value }) =>
                new graphic.LinearGradient(0, 0, 0, 0.6, [
                  {
                    offset: getChartOffset(value, 2),
                    color: renderChartColor(value),
                  },
                  {
                    offset: getChartOffset(value, 1),
                    color: renderChartColor(value, 'medium'),
                  },
                  {
                    offset: getChartOffset(value, 0),
                    color: COLOR_RATING_LOW,
                  },
                ]),
        },
        barWidth: vertical ? null : 24,
        label: {
          show: true,
          position: vertical ? 'top' : 'right',
          formatter: ({ value }) => {
            if (value > 60) return `{high|${value}%}`;
            if (value > 25) return `{medium|${value}%}`;
            return `{low|${value}%}`;
          },
          rich: {
            high: { color: '#000' },
            medium: { color: '#000' },
            low: { color: '#000' },
          },
        },
      },
    ],
  };
  return (
    <div
      className={classNames('hazard-index-chart', className)}
      style={vertical ? null : { height: data.length * 120, minHeight: 300 }}
    >
      <ReactEcharts
        option={option}
        notMerge
        // lazyUpdate
        style={vertical ? { height: '100%' } : { height: '80%' }}
        onChartReady={onChartReady}
        onEvents={{
          ...(!print && {
            click: ({ dataIndex }) => {
              document.getElementById(data[dataIndex].slug).scrollIntoView({ behavior: 'smooth' });
            },
          }),
        }}
      />
    </div>
  );
};
