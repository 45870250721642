import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider, Empty } from 'antd';
import 'core-js/stable/array';
import 'core-js/stable/date';
import 'core-js/stable/function';
import 'core-js/stable/map';
import 'core-js/stable/math';
import 'core-js/stable/number';
import 'core-js/stable/object';
import 'core-js/stable/parse-float';
import 'core-js/stable/parse-int';
import 'core-js/stable/set';
import 'core-js/stable/symbol';
import 'core-js/stable/weak-map';

import store from './store';
import Routes from './routes';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import './index.scss';

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider getPopupContainer={() => document.getElementById('app-root')} renderEmpty={() => <Empty />}>
      <App>
        <Routes />
      </App>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
