import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import IndustryNavigation from '../../components/Navigation/Navigation';
import IndustryBrowse from './components/IndustryBrowse/IndustryBrowse';
import { fetchIndustries, fetchIndustryCategories } from '../../IndustryActions';
import { industryCategoryListSelector, loadingIndustryCategoriesSelector } from '../../IndustrySelectors';
import { industryClassificationSelector } from '../../../Account/AccountSelectors';

const AdvancedSearch = ({
  history,
  industryClassification,
  industryCategoryList,
  loading,
  fetchIndustries,
  fetchIndustryCategories,
}) => {
  useEffect(() => {
    fetchIndustryCategories(industryClassification);
  }, [industryClassification, fetchIndustryCategories]);

  const fetchIndustriesByCode = async (code) => {
    const res = await fetchIndustries({ code });
    return _.filter(res.data, (item) => !item.alias);
  };

  const goToIndustry = (id) => {
    history.push(`/industries/${id}`);
  };

  return (
    <>
      <IndustryNavigation />
      <IndustryBrowse
        loading={loading}
        industryCategoryList={industryCategoryList}
        loadData={fetchIndustriesByCode}
        goToIndustry={goToIndustry}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  industryCategoryList: industryCategoryListSelector(state),
  industryClassification: industryClassificationSelector(state),
  loading: loadingIndustryCategoriesSelector(state),
});

const mapDispatchToProps = { fetchIndustries, fetchIndustryCategories };

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSearch);
