import React from 'react';
import { useSelector } from 'react-redux';
import DisclamerTemplates from './Templates';
import './Disclaimer.scss';
import { reportThemeSelector } from '../../../../../Account/AccountSelectors';

export default () => {
  const { Default, AonDisclaimer } = DisclamerTemplates;
  const reportTheme = useSelector(reportThemeSelector);

  if (reportTheme?.label === 'AON theme') {
    return <AonDisclaimer />;
  }

  return <Default />;
};
