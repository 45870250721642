import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
import { taxonomySelector, trialUserOptions } from '../Account/AccountSelectors';
import { parseLinkHeader } from './utils';

export const FETCH_INDUSTRIES = 'industry/FETCH_INDUSTRIES';
export const REQUEST_INDUSTRY = 'industry/REQUEST_INDUSTRY';
export const RECEIVE_INDUSTRY = 'industry/RECEIVE_INDUSTRY';
export const REQUEST_INDUSTRY_CATEGORIES = 'industry/REQUEST_INDUSTRY_CATEGORIES';
export const RECEIVE_INDUSTRY_CATEGORIES = 'industry/RECEIVE_INDUSTRY_CATEGORIES';
export const REQUEST_HAZARD_INDEX = 'industry/REQUEST_HAZARD_INDEX';
export const RECEIVE_HAZARD_INDEX = 'industry/RECEIVE_HAZARD_INDEX';
export const REQUEST_UPDATE_HAZARD_INDEX_GROUPS = 'industry/REQUEST_UPDATE_HAZARD_INDEX_GROUPS';
export const RECEIVE_UPDATE_HAZARD_INDEX_GROUPS = 'industry/RECEIVE_UPDATE_HAZARD_INDEX_GROUPS';
export const REQUEST_SIGNIFICANT_EXPOSURES = 'industry/REQUEST_SIGNIFICANT_EXPOSURES';
export const RECEIVE_SIGNIFICANT_EXPOSURES = 'industry/RECEIVE_SIGNIFICANT_EXPOSURES';
export const REQUEST_MATERIALS_EQUIPMENTS = 'industry/REQUEST_MATERIALS_EQUIPMENTS';
export const RECEIVE_MATERIALS_EQUIPMENTS = 'industry/RECEIVE_MATERIALS_EQUIPMENTS';
export const REQUEST_RISK_CHECKLIST = 'industry/REQUEST_RISK_CHECKLIST';
export const RECEIVE_RISK_CHECKLIST = 'industry/RECEIVE_RISK_CHECKLIST';
export const REQUEST_ENDORSEMENTS = 'industry/REQUEST_ENDORSEMENTS';
export const RECEIVE_ENDORSEMENTS = 'industry/RECEIVE_ENDORSEMENTS';
export const REQUEST_MEDIA = 'industry/REQUEST_MEDIA';
export const RECEIVE_MEDIA = 'industry/RECEIVE_MEDIA';

const API_BASE = process.env.REACT_APP_API_BASE;
const PAGE_SIZE = 100;

const requestIndustry = () => ({ type: REQUEST_INDUSTRY });
const receiveIndustry = (data) => ({ type: RECEIVE_INDUSTRY, industryInfo: data });

const requestIndustryCategories = () => ({ type: REQUEST_INDUSTRY_CATEGORIES });
const receiveIndustryCategories = (data) => ({ type: RECEIVE_INDUSTRY_CATEGORIES, industryCategoryList: data });

const requestHazardIndex = () => ({ type: REQUEST_HAZARD_INDEX });
const receiveHazardIndex = (data) => ({ type: RECEIVE_HAZARD_INDEX, hazardIndex: data });

const requestSignificantExposures = () => ({ type: REQUEST_SIGNIFICANT_EXPOSURES });
const receiveSignificantExposures = (data) => ({ type: RECEIVE_SIGNIFICANT_EXPOSURES, significantExposureList: data });

const requestMaterialsEquipments = () => ({ type: REQUEST_MATERIALS_EQUIPMENTS });
const receiveMaterialsEquipments = (data) => ({ type: RECEIVE_MATERIALS_EQUIPMENTS, materialEquipmentList: data });

const requestRiskChecklist = () => ({ type: REQUEST_RISK_CHECKLIST });
const receiveRiskChecklist = (data) => ({ type: RECEIVE_RISK_CHECKLIST, riskChecklist: data });

const requestEndorsements = () => ({ type: REQUEST_ENDORSEMENTS });
const receiveEndorsements = (data) => ({ type: RECEIVE_ENDORSEMENTS, endorsementList: data });

const requestMedia = () => ({ type: REQUEST_MEDIA });
const receiveMedia = (data) => ({ type: RECEIVE_MEDIA, media: data });

export const fetchIndustries = (params, nextUrl, trial) => async (dispatch, getState) => {
  if (trial) {
    return Promise.resolve({ data: trialUserOptions.industries, headers: { link: '' } });
  }

  try {
    const url = nextUrl || `${API_BASE}industries`;
    const taxonomy = taxonomySelector(getState());
    const res = await axios.get(url, { params: { pagesize: PAGE_SIZE, taxonomy, ...params } });
    const industryList = _.filter(res.data, (item) => !item.alias);
    dispatch({ type: FETCH_INDUSTRIES, industryList });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchIndustry = (industryId) => async (dispatch) => {
  dispatch(requestIndustry());
  const url = `${API_BASE}industries/${industryId}`;
  try {
    const res = await axios.get(url);
    dispatch(receiveIndustry(res.data));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveIndustry());
    return Promise.reject(err);
  }
};

export const fetchIndustryCategories = (industryClassification) => async (dispatch) => {
  dispatch(requestIndustryCategories());
  const url = `${API_BASE}classifications/${industryClassification}`;
  try {
    const res = await axios.get(url);
    dispatch(receiveIndustryCategories(res.data));
  } catch (err) {
    dispatch(receiveIndustryCategories());
  }
};

export const fetchHazardIndex = (industryId, country) => async (dispatch) => {
  dispatch(requestHazardIndex());
  const url = `${API_BASE}industries/${industryId}/hazard_index/${country}`;
  try {
    const res = await axios.get(url);
    dispatch(receiveHazardIndex(res.data));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveHazardIndex());
    return Promise.reject(err);
  }
};

export const fetchSignificantExposures = (industryId, country) => async (dispatch) => {
  dispatch(requestSignificantExposures());
  const url = `${API_BASE}industries/${industryId}/exposures/${country}`;
  try {
    const res = await axios.get(url);
    dispatch(receiveSignificantExposures(res.data));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveSignificantExposures());
    return Promise.reject(err);
  }
};

export const fetchMaterialsEquipments = (industryId, country) => async (dispatch) => {
  dispatch(requestMaterialsEquipments());
  const url = `${API_BASE}industries/${industryId}/materials_equipment/${country}`;
  try {
    const res = await axios.get(url);
    dispatch(receiveMaterialsEquipments(res.data));
  } catch (err) {
    dispatch(receiveMaterialsEquipments());
  }
};

export const fetchRiskChecklist = (industryId, params) => async (dispatch) => {
  dispatch(requestRiskChecklist());
  const endpoint = `${API_BASE}industries/${industryId}/checklist`;
  const queryString = qs.stringify({ page_size: PAGE_SIZE, ...params });
  const url = `${endpoint}?${queryString}`;
  const riskChecklist = [];
  const fetchData = async (result, nextUrl) => {
    const res = await axios.get(nextUrl);
    const { data, headers } = res;
    const parsedLink = parseLinkHeader(headers.link);
    result.push(...data);
    if (parsedLink.next) {
      await fetchData(result, parsedLink.next);
    }
  };
  try {
    await fetchData(riskChecklist, url);
    dispatch(receiveRiskChecklist(riskChecklist));
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchEndorsements = (industryId) => async (dispatch) => {
  dispatch(requestEndorsements());
  const url = `${API_BASE}industries/${industryId}/endorsements`;
  try {
    const res = await axios.get(url);
    dispatch(receiveEndorsements(res.data));
  } catch (err) {
    dispatch(receiveEndorsements());
  }
};

export const fetchMedia = (industryId) => async (dispatch) => {
  dispatch(requestMedia());
  const url = `${API_BASE}industries/${industryId}/media`;
  try {
    const res = await axios.get(url);
    dispatch(receiveMedia(res.data));
  } catch (err) {
    dispatch(receiveMedia());
  }
};
