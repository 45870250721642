import React, { useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import Table from '../../components/Table/Table';
import notes from '../../data/notes';
import lmiLogo from '../../../../../../assets/images/lmi-logo.png';
import andrewnockLogo from '../../assets/andrewnock-b.png';

export default () => {
  useEffect(() => window.print(), []);
  // const { inputs = [], results = [] } = JSON.parse(sessionStorage.getItem('calculator_printing_items')) || {};
  return (
    <article className="calculator-printing">
      <section>
        <div className="calculator-printing-form">
          <div className="calculator-printing-form-title">Building Cost Calculator Results</div>
          <div className="calculator-printing-form-content">
            <h3>Calculation Inputs</h3>
            {/* {inputs.map(item => (
              <div key={item.label} className="calculator-printing-form-item">
                <span>{item.label}</span>
                <span>{item.value}</span>
              </div>
            ))} */}
            <h3>Calculation Results</h3>
            {/* {results.map(item => (
              <div key={item.label} className="calculator-printing-form-item result">
                <span>{item.label}</span>
                <span>{item.value}</span>
              </div>
            ))} */}
          </div>
        </div>
        <h2>Construction period</h2>
        <Table />
      </section>
      <section>
        <h2>Notes</h2>
        {notes.map(
          (item) =>
            !item.webOnly && (
              <>
                <h3>{item.title}</h3>
                <p>{item.content}</p>
              </>
            )
        )}
        <h2>Contact</h2>
        <Row gutter={20} type="flex">
          <Col span={12}>
            <div className="contact-card">
              <img src={lmiLogo} alt="logo" />
              <div>
                <Button type="link" href={`https://${process.env.REACT_APP_DOMAIN}`} target="_blank">
                  {process.env.REACT_APP_DOMAIN}
                </Button>
                <Button type="link" href={`mailto:${process.env.REACT_APP_EMAIL}`}>
                  {process.env.REACT_APP_EMAIL}
                </Button>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="contact-card">
              <img src={andrewnockLogo} alt="logo" />
              <div>
                <Button type="link" href="https://www.andrewnockvaluers.com.au" target="_blank">
                  www.andrewnockvaluers.com.au
                </Button>
                <Button type="link" href="mailto:info@andrewnock.com.au">
                  info@andrewnock.com.au
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </article>
  );
};
