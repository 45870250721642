import React from 'react';
import { Modal, Icon } from 'antd';
import './Modal.scss';

export default ({ visible, risk, closeModal }) => (
  <Modal
    visible={visible}
    footer={null}
    width={720}
    onCancel={closeModal}
    className="hazard-index-modal"
    bodyStyle={{ padding: 0 }}
  >
    <div className="hazard-index-modal-content">
      {risk.icon ? (
        <Icon component={risk.icon} className="hazard-index-modal-icon" />
      ) : (
        <Icon type="setting" theme="filled" className="hazard-index-modal-icon" />
      )}
      <h3>{risk.name}</h3>
      <div>{`${risk.rating || 0}%`}</div>
    </div>
    <div className="hazard-index-modal-summary">
      <h3>RISK SUMMARY</h3>
      <div dangerouslySetInnerHTML={{ __html: risk.comment }} />
    </div>
  </Modal>
);
