/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spin, Empty, Checkbox, Form, message, Icon as AntIcon } from 'antd';
import classNames from 'classnames';
import { debounce } from 'lodash';
import Button from '../../../../components/Button/Button';
import Icon from '../../../../components/Icon/Icon';
import { renderColor } from '../../../Industry/utils';
import TextEditor from '../../../../components/TextEditor/TextEditor';

import { fetchRiskChecklist } from '../../../Industry/IndustryActions';
import { underwriterHazardIndexCustomizationSelector } from '../../ReportSelectors';
import { updateReportCustomizations } from '../../ReportActions';

import './RiskCheckListEdit.scss';

const CheckboxGroup = Checkbox.Group;

const RiskCheckListEdit = ({
  riskChecklist,
  fetchRiskChecklist,
  loadingRiskChecklist,
  hazardIndex,
  customization,
  loadingCustomization,
  form,
  updateReportCustomizations,
  onUnderwriterComments,
}) => {
  const { id: industryId } = useParams();
  const [editable, setEditiable] = useState(true);
  const [checklist, setChecklist] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [checkedAnswerList, setCheckedAnswerList] = useState([]);
  const [loadingSave, setLoadingSave] = useState();

  const { getFieldDecorator } = form;

  const answers =
    customization.customizations?.find((hazardClass) => hazardClass.id === +hazardIndex.id)?.checklist || [];

  const save = async (list) => {
    if (loadingCustomization) return;

    form.validateFields(async (err, values) => {
      if (!err) {
        const questionString = (questionId) => riskChecklist.find((risk) => risk.id === +questionId)?.title;

        const answersForms = Object.entries(values).map(([key, value]) => ({
          questionId: +key,
          answer: value,
          question: questionString(key),
        }));
        const checkedQuestionsList = Array.isArray(list) ? list : checkedList;
        const checkedQuestions = checkedQuestionsList
          .filter((questionId) => values[questionId] === undefined)
          .map((questionId) => ({
            questionId,
            question: questionString(questionId),
          }));

        const questionsToSave = [...answersForms, ...checkedQuestions];

        const hazardIndexToSave = customization.customizations?.find(
          (hazardClass) => hazardClass.id === +hazardIndex.id
        );

        const customizations = customization.customizations?.filter(
          (hazardClass) => hazardClass.id !== +hazardIndex.id
        );

        const customizationToSave = {
          section: 'hazard_index_underwriter',
          customization: JSON.stringify({
            classes: [
              ...customizations,
              {
                ...hazardIndexToSave,
                id: +hazardIndex.id,
                checklist: questionsToSave,
              },
            ],
          }),
        };

        try {
          await updateReportCustomizations(industryId, customization?.id, customization?.name, [customizationToSave]);
          message.success('Saved');
        } catch (err) {
          message.error(err.message);
        } finally {
          setLoadingSave();
        }
      }
    });
  };

  useEffect(() => {
    const insuranceClasses = riskChecklist?.map((checklist) => checklist?.insurance_class.id);
    const noOfInsuranceClasses = [...new Set(insuranceClasses)].length;
    if (noOfInsuranceClasses < 2 && !loadingRiskChecklist) {
      fetchRiskChecklist(industryId, { type: 'Key' });
    }

    if (answers && answers.length > 0) {
      setEditiable(true);
      setCheckedList(answers.map((answer) => answer.questionId));
      setCheckedAnswerList(answers.map((answer) => answer.answer && answer.questionId));
    }
  }, []);

  useEffect(() => {
    const checklistByIndex = riskChecklist.filter((list) => list.insurance_class.id === +hazardIndex.id);
    setChecklist(checklistByIndex);
  }, [riskChecklist]);

  const saveCallback = useCallback(
    debounce(async (list) => {
      await save(list);
    }, 500),
    []
  );

  const onChange = (list) => {
    setCheckedList(list);
    saveCallback(list);
  };

  const onCheckAnswer = (id) => {
    if (checkedAnswerList.includes(id)) {
      const answerList = checkedAnswerList.filter((item) => item !== id);
      setCheckedAnswerList(answerList);
    } else {
      const answerList = [...checkedAnswerList, id];
      setCheckedAnswerList(answerList);
    }
  };

  return (
    <Form name="riskChecklistForm" onSubmit={save}>
      <div className="risk-checklist-container">
        <div className="heading">
          <div className="hazard-index-icon">
            <Icon
              size={40}
              component={hazardIndex.icon}
              theme="filled"
              style={{ backgroundColor: renderColor(hazardIndex.rating) }}
            />
          </div>
          <div className="info">
            <div className="title">
              <h2 className="name">{hazardIndex.name}</h2>
              <div className="rating" style={{ color: renderColor(hazardIndex.rating) }}>
                {`${hazardIndex.rating}%`}
              </div>
            </div>
            <div className="classes">{hazardIndex.classes.join(', ')}</div>
          </div>
        </div>
        <div className="content">
          {loadingRiskChecklist ? (
            <Spin />
          ) : (
            <>
              <h3>
                <Checkbox className="check-box" indeterminate={editable} onChange={() => setEditiable(!editable)} />
                Risk Checklist{' '}
              </h3>
              <CheckboxGroup value={checkedList} onChange={onChange}>
                {checklist.length > 0 &&
                  checklist.map((list, idx) => {
                    const answer = answers.find((answer) => answer.questionId === list.id)?.answer;

                    return (
                      <div
                        className={classNames('check-list-item', {
                          selected: editable && checkedList.includes(list.id),
                        })}
                        key={list.id}
                      >
                        {editable && <Checkbox value={list.id} className="check-box" />}
                        {`${idx + 1}. ${list.title}`}

                        {editable && checkedList.includes(list.id) && (
                          <>
                            <div>
                              <Button
                                className="add-answer link"
                                type="seconday"
                                onClick={() => onCheckAnswer(list.id)}
                              >
                                {!checkedAnswerList.includes(list.id) ? 'Add Answer' : 'Cancel'}
                              </Button>
                            </div>
                            {checkedAnswerList.includes(list.id) && (
                              <>
                                <Form.Item>
                                  {getFieldDecorator(`${list.id}`, {
                                    initialValue: answer,
                                  })(<TextEditor placeholder="Add your answer" />)}
                                </Form.Item>
                                <Form.Item>
                                  <Button
                                    secondary
                                    type="primary"
                                    htmlType="button"
                                    onClick={(checkedList) => {
                                      setLoadingSave(list.id);
                                      save(checkedList);
                                    }}
                                    disabled={loadingCustomization}
                                    loading={loadingSave === list.id}
                                  >
                                    Save
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
              </CheckboxGroup>
              {!loadingRiskChecklist && !checklist.length > 0 && <Empty description="no content" />}
            </>
          )}
        </div>
        <Button secondary htmlType="button" onClick={onUnderwriterComments} className="underwriter-comment-button">
          <AntIcon type="left" />
          Underwriter Comments
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  riskChecklist: state.industry.riskChecklist || [],
  loadingRiskChecklist: state.industry.loadingRiskChecklist,
  customization: underwriterHazardIndexCustomizationSelector(state),
  loadingCustomization: state.report.loadingCustomizations,
});

const mapDispatchToProps = { fetchRiskChecklist, updateReportCustomizations };

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(RiskCheckListEdit));
