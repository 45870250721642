import React from 'react';
import './Card.scss';

export default ({ children, title, description, cover }) => (
  <div className="course-card card">
    <div className="course-card-cover" style={{ backgroundImage: `url(${cover})` }} />
    <div className="course-card-info">
      <h1>{title}</h1>
      <hr />
      {description && <p dangerouslySetInnerHTML={{ __html: description }} className="course-card-description" />}
      <div className="course-card-extra">{children}</div>
    </div>
  </div>
);
