import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Spin, Empty } from 'antd';
import Mode from './components/Mode/Mode';
import List from './components/List/List';
import Chart from './components/Chart/Chart';
import Classification from './components/Classification/Classification';
import Detail from './components/Detail/Detail';
import Modal from './components/Modal/Modal';
import { fetchHazardIndex } from '../../IndustryActions';
import {
  industryIdSelector,
  hazardIndexSelector,
  loadingIndustrySelector,
  loadingHazardIndexSelector,
} from '../../IndustrySelectors';
import { countryCodeSelector } from '../../../Account/AccountSelectors';
import './HazardIndex.scss';

const HazardIndex = ({ loading, industryId, country, hazardIndex, fetchHazardIndex }) => {
  const [mode, setMode] = useState('chart');
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRisk, setSelectedRisk] = useState({});

  useEffect(() => {
    if (industryId && country) {
      fetchHazardIndex(industryId, country);
    }
  }, [industryId, country, fetchHazardIndex]);

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth <= 480) {
        setMode('grid');
      }
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const openModal = (selectedRisk) => {
    setModalVisible(true);
    setSelectedRisk(selectedRisk);
  };

  return (
    <div className="hazard-index component-wrapper">
      {loading ? (
        <Spin />
      ) : (
        <>
          {hazardIndex && hazardIndex.length > 0 ? (
            <>
              <div className="hazard-index-header">
                <h2>Hazard Index</h2>
                <Mode activeMode={mode} setMode={setMode} />
              </div>
              {mode === 'chart' ? (
                <Row gutter={[20, 20]} type="flex">
                  <Col xs={24} lg={16} xl={18}>
                    <Chart data={hazardIndex} className="card" />
                  </Col>
                  <Col xs={24} lg={8} xl={6}>
                    <Classification />
                  </Col>
                  <Col span={24}>
                    <Detail data={hazardIndex} />
                  </Col>
                </Row>
              ) : (
                <List data={hazardIndex} openModal={openModal} />
              )}
              <Modal visible={modalVisible} risk={selectedRisk} closeModal={() => setModalVisible(false)} />
            </>
          ) : (
            <Empty description="No Hazard Index" />
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  industryId: industryIdSelector(state),
  country: countryCodeSelector(state),
  hazardIndex: hazardIndexSelector(state),
  loading: loadingIndustrySelector(state) || loadingHazardIndexSelector(state),
});

const mapDispatchToProps = { fetchHazardIndex };

export default connect(mapStateToProps, mapDispatchToProps)(HazardIndex);
