/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Modal } from 'antd';

export default ({ children, toggle, defaultVisible, ...props }) => {
  const [visible, setVisible] = useState(defaultVisible || false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  return (
    <>
      {toggle(show)}
      <div onClick={(e) => e.stopPropagation()} style={{ display: 'none' }}>
        <Modal
          visible={visible}
          footer={null}
          keyboard={false}
          destroyOnClose
          onCancel={hide}
          bodyStyle={{ paddingTop: '50px' }}
          {...props}
        >
          {children(hide)}
        </Modal>
      </div>
    </>
  );
};
