import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Layout, Icon, Popconfirm } from 'antd';
import Button from '../../../../components/Button/Button';
import Report from '../../components/Report/Report';
import HazardIndexGrouping from '../../components/HazardIndexGrouping/HazardIndexGrouping';
import { clearReportCustomization, fetchReportCustomization } from '../../ReportActions';
import './TailoredReport.scss';

const { Content, Header } = Layout;

const TailoredReport = ({ fullscreen, setFullscreen, clearReportCustomization, fetchReportCustomization }) => {
  const [page, setPage] = useState('tailor');

  const location = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get('tmpl')) {
      fetchReportCustomization(query.get('tmpl'));
    }
  }, [location, fetchReportCustomization]);

  switch (page) {
    case 'tailor': {
      return (
        <Content>
          <Header className="report-header">
            <h3>Tailored Report</h3>
            <Popconfirm
              title="Are you sure to start over?"
              onConfirm={() => clearReportCustomization()}
              okText="Yes"
              cancelText="No"
            >
              <Button secondary style={{ marginLeft: 'auto', marginRight: '20px' }}>
                <Icon type="reload" /> Reset
              </Button>
            </Popconfirm>
            <Button secondary type="primary" onClick={() => setPage('report')}>
              Next <Icon type="right" />
            </Button>
          </Header>
          <main className="tailor-main">
            <h3>REPORT / HAZARD ASSESSMENT</h3>
            <div className="tailor-description">
              To tailor this report for your client, <strong>drag and drop</strong> the insurance classes below to their
              respective categories and add your comments to each.
            </div>
            <div className="drop-container">
              <HazardIndexGrouping appearance="page" />
            </div>
          </main>
        </Content>
      );
    }
    case 'report': {
      return (
        <Report
          handleBack={() => {
            setPage('tailor');
          }}
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
          type="Tailored Report"
        />
      );
    }
    default:
      return <div />;
  }
};

const mapDispatchToProps = { clearReportCustomization, fetchReportCustomization };

export default connect(null, mapDispatchToProps)(TailoredReport);
