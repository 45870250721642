/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import React, { useEffect, useCallback, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Spin, Input, Divider } from 'antd';
import _ from 'lodash';
import ThemeSelect from '../ThemeSelect/ThemeSelect';
import QuickList from '../QuickList/QuickList';
import TailoredList from '../TailoredList/TailoredList';
import UnderwriterList from '../UnderwriterList/UnderwriterList';
import { fetchInsuranceClasses } from '../../../Document/DocumentActions';
import { fetchHazardIndex, fetchSignificantExposures, fetchRiskChecklist } from '../../../Industry/IndustryActions';
import { setSections, updateReportCustomizations, resetHazardIndexChartDataUrl } from '../../ReportActions';
import {
  hazardIndexCustomizationSelector,
  underwriterHazardIndexCustomizationSelector,
  sectionsSelector,
  quickReportSectionsSelector,
  loadingCustomizationsSelector,
} from '../../ReportSelectors';
import { industryIdSelector, indicativeRateSelector } from '../../../Industry/IndustrySelectors';
import { countryCodeSelector } from '../../../Account/AccountSelectors';
import { IconHazardIndex, IconSignificantExposures, IconRiskChecklist } from '../../../../assets/icons/IndustryFeature';
import { TypeContext } from '../../ReportContext';
import './CheckList.scss';

const getChildrenIds = (item) => item?.children?.map((child) => child.id) || [];

const CheckList = ({
  industryId,
  country,
  loading,
  loadingIndustry,
  quickReportSections,
  indicativeRate,
  sections,
  themes = [],
  selectedTheme,
  setTheme,
  fetchInsuranceClasses,
  fetchHazardIndex,
  fetchSignificantExposures,
  fetchRiskChecklist,
  setSections,
  customization,
  underwriterCustomization,
  updateReportCustomizations,
  resetHazardIndexChartDataUrl,
  loadingCustomizations,
  underwriter,
}) => {
  const type = useContext(TypeContext);
  const [hideIndicativeRate, setHideIndicativeRate] = useState(true);

  const CHECKLIST = [
    {
      id: 'user-profile',
      title: underwriter ? 'Underwriter Details' : 'Meet Your Advisor',
      required: true,
      onEdit: '/account/user-profile',
    },
    {
      id: 'company-profile',
      title: 'Company Information',
      required: !underwriter,
      onEdit: '/account/company-information',
      hide: underwriter,
    },
    { id: 'about', title: 'About LMI Group', required: true },
    { id: 'purpose', title: 'Purpose of this Report', required: true },
    {
      id: 'under-insurance',
      title: 'Under Insurance',
      defaultChecked: !underwriter,
      hide: country === 'nz' || underwriter,
    },
    {
      id: 'report-hazard-assessment',
      title: 'Hazard Assessment',
      icon: IconHazardIndex,
      required: true,
    },
    {
      id: 'insurable-gross-profit',
      title: 'Indicative Rate of Insurable Gross Profit',
      defaultChecked: true,
      skipSequencing: true,
      hide: hideIndicativeRate,
    },
    {
      id: 'report-significant-exposures',
      title: 'Significant Exposures',
      icon: IconSignificantExposures,
    },
    { id: 'report-risk-checklist', title: 'Risk Checklist', icon: IconRiskChecklist, hide: underwriter },
  ].filter((item) => !item.hide);

  const location = useLocation();

  const saveReportCustomizationName = useCallback(
    _.debounce(async (name) => {
      const lastSegment = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
      if (lastSegment.toLowerCase() === 'tailored-report') {
        if (!customization.isPristine)
          await updateReportCustomizations(industryId, customization.id, name, customization.customizations);
      }

      if (lastSegment.toLowerCase() === 'underwriter-report') {
        if (!underwriterCustomization?.isPristine)
          await updateReportCustomizations(
            industryId,
            underwriterCustomization.id,
            name,
            underwriterCustomization.customizations
          );
      }
    }, 500),
    [customization, underwriterCustomization]
  );

  useEffect(() => {
    if (indicativeRate && !loadingIndustry) {
      if (indicativeRate.length > 0) setHideIndicativeRate(false);
      else setHideIndicativeRate(true);
    }
  }, [loadingIndustry, indicativeRate]);

  useEffect(() => {
    fetchInsuranceClasses('au');
  }, [fetchInsuranceClasses]);

  useEffect(() => {
    if (industryId && country) {
      fetchHazardIndex(industryId, country);
      fetchSignificantExposures(industryId, country);
    }
  }, [industryId, country, fetchHazardIndex, fetchSignificantExposures]);

  useEffect(() => {
    const initialSections = _.reduce(
      CHECKLIST,
      (result, item) => {
        if (item.required || item.defaultChecked) {
          result[item.id] = {
            checked: true,
            skipSequencing: item.skipSequencing ? item.skipSequencing : false,
            children: getChildrenIds(quickReportSections[item.id]),
          };
        } else {
          result[item.id] = {
            checked: false,
            skipSequencing: item.skipSequencing ? item.skipSequencing : false,
            children: [],
          };
        }
        return result;
      },
      {}
    );
    setSections({ cover: sections?.cover, ...initialSections });
  }, [quickReportSections, fetchRiskChecklist, setSections, industryId]);

  useEffect(() => {
    if (industryId) {
      const categoryIds = getChildrenIds(quickReportSections['report-risk-checklist']);
      fetchRiskChecklist(industryId, { categories: categoryIds, type: 'Key' });
    }
  }, [industryId]);

  if (!sections || loading) {
    return <Spin />;
  }

  const list = CHECKLIST.map((item) => {
    return {
      ...item,
      anchor: item.id,
      checked: sections[item.id] ? sections[item.id].checked : false,
      children: quickReportSections[item.id] ? quickReportSections[item.id].children : [],
      checkedChildren: sections[item.id] ? sections[item.id].children : [],
    };
  });

  const onCheck = (checked, value) => {
    setSections({
      ...sections,
      [value]: {
        ...sections[value],
        checked,
        children: checked ? getChildrenIds(quickReportSections[value]) : [],
      },
    });
  };

  const onGroupCheck = (checkedList, parentId) => {
    setSections({ ...sections, [parentId]: { checked: checkedList.length > 0, children: checkedList } });
  };

  const onNameChange = (e) => {
    setSections({ ...sections, cover: { insuredBusinessName: e.target.value } });
    saveReportCustomizationName(e.target.value);
  };

  return (
    <>
      <h3 className="drawer-content-title">Theme</h3>
      <ThemeSelect themes={themes} selectedTheme={selectedTheme} onThemeSelect={setTheme} />
      <Divider dashed className="drawer-content-divider" />
      <h3 className="drawer-content-title">Insured Business Name</h3>
      <Input
        size="large"
        placeholder="Insured Business Name"
        onChange={onNameChange}
        defaultValue={customization?.name || underwriterCustomization?.name}
      />
      <Divider dashed className="drawer-content-divider" />
      <h3 className="drawer-content-title">Contents</h3>
      {type === 'Quick Report' && <QuickList list={list} onCheck={onCheck} onGroupCheck={onGroupCheck} />}
      {type === 'Tailored Report' && <TailoredList list={list} onCheck={onCheck} onGroupCheck={onGroupCheck} />}
      {underwriter && (
        <UnderwriterList
          list={list}
          onCheck={onCheck}
          onGroupCheck={onGroupCheck}
          loading={loading}
          sections={sections}
          industryId={industryId}
          customization={underwriterCustomization}
          updateReportCustomizations={updateReportCustomizations}
          loadingCustomizations={loadingCustomizations}
          resetHazardIndexChartDataUrl={resetHazardIndexChartDataUrl}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  industryId: industryIdSelector(state),
  country: countryCodeSelector(state),
  loadingIndustry: state.industry.loadingIndustry,
  loadingCustomizations: loadingCustomizationsSelector(state),
  loading:
    state.industry.loadingIndustry ||
    state.industry.loadingHazardIndex ||
    state.industry.loadingSignificantExposures ||
    state.document.loadingInsuranceClasses,
  sections: sectionsSelector(state),
  quickReportSections: quickReportSectionsSelector(state),
  customization: hazardIndexCustomizationSelector(state),
  underwriterCustomization: underwriterHazardIndexCustomizationSelector(state),
  indicativeRate: indicativeRateSelector(state),
  riskChecklist: state.industry.riskChecklist,
});

const mapDispatchToProps = {
  fetchInsuranceClasses,
  fetchHazardIndex,
  fetchSignificantExposures,
  fetchRiskChecklist,
  setSections,
  updateReportCustomizations,
  resetHazardIndexChartDataUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckList);
