import React, { useState } from 'react';
import { Form, Row, Col } from 'antd';
import _ from 'lodash';
import Icon from '../../../../../../components/Icon/Icon';
import Button from '../../../../../../components/Button/Button';
import Location from './components/Location/Location';
import Industry from './components/Industry/Industry';
import Building from './components/Building/Building';
import FencingCarpark from './components/FencingCarpark/FencingCarpark';
import { IconLocation, IconIndustry, IconBuilding, IconFence } from '../../assets/icons';

let buildingId = 0;
let fencingCarparkId = 0;

export default () => {
  const [location, setLocation] = useState(null);
  const [industry, setIndustry] = useState(null);
  const [buildings, setBuildings] = useState([{ buildingId }]);
  const [fencingCarparks, setFencingCarparks] = useState([{ fencingCarparkId }]);

  const addBuilding = () => {
    buildingId += 1;
    setBuildings([...buildings, { buildingId }]);
  };

  const onBuildingsChange = (id, key, value) => {
    const index = _.findIndex(buildings, { buildingId: id });
    buildings[index][key] = value;
    setBuildings([...buildings]);
  };

  const addFencing = () => {
    fencingCarparkId += 1;
    setFencingCarparks([...fencingCarparks, { fencingCarparkId }]);
  };

  const onFencingCarparksChange = (id, key, value) => {
    const index = _.findIndex(fencingCarparks, { fencingCarparkId: id });
    fencingCarparks[index][key] = value;
    setFencingCarparks([...fencingCarparks]);
  };

  const submit = () => {
    console.log(location, industry, buildings, fencingCarparks);
  };

  // const print = () => {
  //   const calculatorPrintingItems = {};
  //   sessionStorage.setItem('calculator_printing_items', JSON.stringify(calculatorPrintingItems));
  //   window.open('/calculator/building-cost/printing', '_blank');
  // };

  return (
    <div className="calculator-form card">
      <h3 className="calculator-form-title">Building Cost Calculator</h3>
      <Form colon={false} labelAlign="left">
        <Row gutter={40} type="flex" justify="center">
          <Col xs={24} md={12} className="calculator-form-section">
            <div className="calculator-form-label">
              <Icon component={IconLocation} />
              <span>Location</span>
            </div>
            <Location onLocationChange={setLocation} />
          </Col>
          <Col xs={24} md={12} className="calculator-form-section">
            <div className="calculator-form-label">
              <Icon component={IconIndustry} />
              <span>Industry</span>
            </div>
            <Industry onIndustryChange={setIndustry} />
          </Col>
          <Col xs={24} lg={12} className="calculator-form-section">
            <div className="calculator-form-label">
              <Icon component={IconBuilding} />
              <span>Building</span>
            </div>
            {buildings.map(({ buildingId }) => (
              <Building key={buildingId} buildingId={buildingId} onBuildingsChange={onBuildingsChange} />
            ))}
            <Form.Item>
              <Button type="primary" size="large" shape="circle" icon="plus" onClick={addBuilding} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12} className="calculator-form-section">
            <div className="calculator-form-label">
              <Icon component={IconFence} />
              <span>Fencing/Carpark</span>
            </div>
            {fencingCarparks.map(({ fencingCarparkId }) => (
              <FencingCarpark
                key={fencingCarparkId}
                fencingCarparkId={fencingCarparkId}
                onFencingCarparksChange={onFencingCarparksChange}
              />
            ))}
            <Form.Item>
              <Button type="primary" size="large" shape="circle" icon="plus" onClick={addFencing} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={16} xl={12} xxl={8}>
            <Button type="primary" size="large" block onClick={submit}>
              Estimate
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
