import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout as AntLayout } from 'antd';
import ErrorBoundary from '../../components/Error/ErrorBoundary';
import Layout from '../../components/Layout/Layout';
import Sider from '../../components/Sider/Sider';
import Profile from './scenes/Profile/Profile';
import Company from './scenes/Company/Company';
import Setting from './scenes/Setting/Setting';
import * as icons from '../../assets/icons/Account';

const { Content } = AntLayout;

const routes = Object.freeze([
  { route: 'user-profile', name: 'User Profile', component: <Profile />, icon: icons.IconProfile },
  { route: 'company-information', name: 'Company Information', component: <Company />, icon: icons.IconCompany },
  { route: 'settings', name: 'Settings', component: <Setting />, icon: icons.IconSetting },
]);

export default ({ match, location }) => {
  const selectedKey = location.pathname.split('/').pop();
  return (
    <Layout showMenu showSearch>
      <AntLayout>
        <Sider collapsible menuItems={routes} selectedMenuItem={selectedKey} />
        <Content>
          <Switch>
            <Route exact path={match.url} render={() => <Redirect to={`${match.url}/${routes[0].route}`} />} />
            {routes.map((item) => (
              <Route
                exact
                key={item.route}
                path={`${match.url}/${item.route}`}
                render={() => <ErrorBoundary>{item.component}</ErrorBoundary>}
              />
            ))}
            <Redirect to="/not-found" />
          </Switch>
        </Content>
      </AntLayout>
    </Layout>
  );
};
