/* eslint-disable consistent-return */
import axios from 'axios';
import _ from 'lodash';
import { taxonomiesSelector } from './AccountSelectors';
import { getBase64 } from './utils';

export const REQUEST_TAXONOMIES = 'account/REQUEST_TAXONOMIES';
export const RECEIVE_TAXONOMIES = 'account/RECEIVE_TAXONOMIES';
export const REQUEST_USER = 'account/REQUEST_USER';
export const RECEIVE_USER = 'account/RECEIVE_USER';
export const REQUEST_USER_SETTINGS = 'account/REQUEST_USER_SETTINGS';
export const RECEIVE_USER_SETTINGS = 'account/RECEIVE_USER_SETTINGS';
export const REQUEST_USER_PROFILE = 'account/REQUEST_USER_PROFILE';
export const RECEIVE_USER_PROFILE = 'account/RECEIVE_USER_PROFILE';
export const REQUEST_USER_REPORTS = 'account/REQUEST_USER_REPORTS';
export const RECEIVE_USER_REPORTS = 'account/RECEIVE_USER_REPORTS';
export const REQUEST_USER_AVATAR = 'account/REQUEST_USER_AVATAR';
export const RECEIVE_USER_AVATAR = 'account/RECEIVE_USER_AVATAR';
export const REQUEST_COMPANIES = 'account/REQUEST_COMPANIES';
export const RECEIVE_COMPANIES = 'account/RECEIVE_COMPANIES';
export const REQUEST_THEMES = 'account/REQUEST_THEMES';
export const RECEIVE_THEMES = 'account/RECEIVE_THEMES';
export const REQUEST_SEARCH_HISTORY = 'account/REQUEST_SEARCH_HISTORY';
export const RECEIVE_SEARCH_HISTORY = 'account/RECEIVE_SEARCH_HISTORY';

const API_BASE = process.env.REACT_APP_API_BASE;

const requestTaxonomies = () => ({ type: REQUEST_TAXONOMIES });
const receiveTaxonomies = (data) => ({ type: RECEIVE_TAXONOMIES, taxonomies: data });

const requestUser = () => ({ type: REQUEST_USER });
const receiveUser = (data) => ({ type: RECEIVE_USER, user: data });

const requestUserSettings = () => ({ type: REQUEST_USER_SETTINGS });
const receiveUserSettings = (data) => ({ type: RECEIVE_USER_SETTINGS, userSettings: data });

const requestUserProfile = () => ({ type: REQUEST_USER_PROFILE });
const receiveUserProfile = (data) => ({ type: RECEIVE_USER_PROFILE, userProfile: data });

const requestUserReports = () => ({ type: REQUEST_USER_REPORTS });
const receiveUserReports = (data) => ({ type: RECEIVE_USER_REPORTS, userReports: data });

const requestUserAvatar = () => ({ type: REQUEST_USER_AVATAR });
const receiveUserAvatar = (data) => ({ type: RECEIVE_USER_AVATAR, userAvatar: data });

const requestCompanies = () => ({ type: REQUEST_COMPANIES });
const receiveCompanies = (data) => ({ type: RECEIVE_COMPANIES, companies: data });

const requestThemes = () => ({ type: REQUEST_THEMES });
const receiveThemes = (data) => ({ type: RECEIVE_THEMES, themes: data });

const requestSearchHistory = () => ({ type: REQUEST_SEARCH_HISTORY });
const receiveSearchHistory = (data) => ({ type: RECEIVE_SEARCH_HISTORY, searchHistory: data });

export const fetchTaxonomies = () => async (dispatch, getState) => {
  if (taxonomiesSelector(getState())) {
    return;
  }
  dispatch(requestTaxonomies());
  const url = `${API_BASE}taxonomies`;
  try {
    const res = await axios.get(url);
    dispatch(receiveTaxonomies(res.data));
  } catch (err) {
    dispatch(receiveTaxonomies());
    // eslint-disable-next-line consistent-return
    return Promise.reject(new Error('Failed to load taxonomies'));
  }
};

export const fetchUser = () => async (dispatch) => {
  dispatch(requestUser());
  const url = `${API_BASE}user`;
  try {
    const res = await axios.get(url);
    dispatch(receiveUser(res.data));
  } catch (err) {
    dispatch(receiveUser());
    return Promise.reject(new Error('Failed to load user'));
  }
};

export const fetchUserSettings = () => async (dispatch) => {
  dispatch(requestUserSettings());
  const url = `${API_BASE}user/settings`;
  try {
    const res = await axios.get(url);
    dispatch(receiveUserSettings(res.data));
  } catch (err) {
    dispatch(receiveUserSettings());
    return Promise.reject(new Error('Failed to load user settings'));
  }
};

export const updateUserSettings = (data) => async (dispatch) => {
  dispatch(requestUserSettings());
  const url = `${API_BASE}user/settings`;
  try {
    await axios.put(url, data);
    dispatch(fetchUserSettings());
    return Promise.resolve('User settings has been successfully updated');
  } catch (err) {
    dispatch(receiveUserSettings());
    return Promise.reject(new Error('User settings is not updated'));
  }
};

export const fetchUserProfile = () => async (dispatch) => {
  dispatch(requestUserProfile());
  const url = `${API_BASE}user/profile`;
  try {
    const res = await axios.get(url);
    dispatch(receiveUserProfile(res.data));
  } catch (err) {
    dispatch(receiveUserProfile());
    return Promise.reject(new Error('Failed to load user profile'));
  }
};

export const updateUserProfile = (data) => async (dispatch) => {
  dispatch(requestUserProfile());
  const url = `${API_BASE}user/profile`;
  try {
    await axios.put(url, data);
    dispatch(fetchUserProfile());
    return Promise.resolve('User profile has been successfully updated');
  } catch (err) {
    dispatch(receiveUserProfile());
    return Promise.reject(new Error('User profile is not updated'));
  }
};

export const fetchUserReports = () => async (dispatch) => {
  dispatch(requestUserReports());
  const url = `${API_BASE}user/reports`;
  try {
    const res = await axios.get(url);
    dispatch(receiveUserReports(res.data));
  } catch (err) {
    dispatch(receiveUserReports());
    return Promise.reject(new Error('Failed to load user reports'));
  }
};

export const fetchUserAvatar = () => async (dispatch) => {
  dispatch(requestUserAvatar());
  const url = `${API_BASE}user/avatar`;
  try {
    const res = await axios.get(url, { responseType: 'blob' });
    const userAvatar = await getBase64(res.data);
    dispatch(receiveUserAvatar(userAvatar));
  } catch (err) {
    dispatch(receiveUserAvatar());
    return Promise.reject(new Error('Failed to load user avatar'));
  }
};

export const updateUserAvatar = (data) => async (dispatch) => {
  dispatch(requestUserAvatar());
  const url = `${API_BASE}user/avatar`;
  try {
    await axios.put(url, data);
    dispatch(fetchUserAvatar());
    return Promise.resolve('User avatar has been successfully uploaded');
  } catch (err) {
    dispatch(receiveUserAvatar());
    return Promise.reject(new Error('User avatar is not uploaded'));
  }
};

export const fetchCompanies = () => async (dispatch) => {
  dispatch(requestCompanies());
  const url = `${API_BASE}user/companies`;
  try {
    const res = await axios.get(url);
    const companies = await Promise.all(
      _.chain(res.data)
        // eslint-disable-next-line no-nested-ternary
        .sort((a, b) => (a.is_primary ? -1 : b.is_primary ? 1 : 0))
        .map(async (item) => {
          if (!item.is_primary && item.logo) {
            try {
              const res = await axios.get(item.logo, { responseType: 'blob' });
              const companyLogo = await getBase64(res.data);
              return { ...item, logo: companyLogo };
            } catch (err) {
              return { ...item, logo: null };
            }
          }
          return item;
        })
        .value()
    );
    dispatch(receiveCompanies(companies));
  } catch (err) {
    dispatch(receiveCompanies());
    return Promise.reject(new Error('Failed to load companies'));
  }
};

export const createCompany = (data) => async (dispatch) => {
  dispatch(requestCompanies());
  const url = `${API_BASE}user/companies`;
  try {
    await axios.post(url, data);
    dispatch(fetchCompanies());
    return Promise.resolve('Company has been successfully created');
  } catch (err) {
    dispatch(receiveCompanies());
    return Promise.reject(new Error('Company is not created'));
  }
};

export const updateCompany = (id, data) => async (dispatch) => {
  dispatch(requestCompanies());
  const url = `${API_BASE}user/companies/${id}`;
  try {
    await axios.patch(url, data);
    dispatch(fetchCompanies());
    return Promise.resolve('Company profile has been successfully updated');
  } catch (err) {
    dispatch(receiveCompanies());
    return Promise.reject(new Error('Company profile is not updated'));
  }
};

export const deleteCompany = (id) => async (dispatch) => {
  dispatch(requestCompanies());
  const url = `${API_BASE}user/companies/${id}`;
  try {
    await axios.delete(url);
    dispatch(fetchCompanies());
    return Promise.resolve('Company profile has been successfully deleted');
  } catch (err) {
    dispatch(receiveCompanies());
    return Promise.reject(new Error('Company profile is not deleted'));
  }
};

export const updateCompanyLogo = (id, data) => async (dispatch) => {
  dispatch(requestCompanies());
  const url = `${API_BASE}user/companies/${id}/logo`;
  try {
    await axios.put(url, data);
    dispatch(fetchCompanies());
    return Promise.resolve('Company logo has been successfully uploaded');
  } catch (err) {
    dispatch(receiveCompanies());
    return Promise.reject(new Error('Company logo is not uploaded'));
  }
};

export const fetchThemes = () => async (dispatch) => {
  dispatch(requestThemes());
  const url = `${API_BASE}user/themes`;
  try {
    const res = await axios.get(url);
    dispatch(receiveThemes(res.data));
  } catch (err) {
    dispatch(receiveThemes());
    return Promise.reject(new Error('Failed to load themes'));
  }
};

export const fetchSearchHistory = (params) => async (dispatch) => {
  dispatch(requestSearchHistory());
  const url = `${API_BASE}user/history/industries`;
  try {
    const res = await axios.get(url, { params });
    dispatch(receiveSearchHistory(res.data));
  } catch (err) {
    dispatch(receiveSearchHistory());
    return Promise.reject(new Error('Failed to load search history'));
  }
};
