import React from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import RecentSearch from './RecentSearch';
import RecentReports from './RecentReports';
import './RecentActivities.scss';
import { isUnderwriterSelector } from '../../../Account/AccountSelectors';

const { TabPane } = Tabs;

const RecentActivities = () => {
  const underwirter = useSelector(isUnderwriterSelector);

  return (
    <Tabs defaultActiveKey="1" className="recent-activities card">
      <TabPane tab="RECENTLY VIEWED" key="1">
        <RecentSearch />
      </TabPane>
      <TabPane tab={underwirter ? 'MY UNDERWRITER REPORTS' : 'MY TAILORED REPORTS'} key="2">
        <RecentReports underwriter={underwirter} />
      </TabPane>
    </Tabs>
  );
};

export default RecentActivities;
