import React from 'react';
import { Icon } from 'antd';
import Button from '../../../../components/Button/Button';
import './Feedback.scss';

export default () => (
  <div className="dashboard-feedback card">
    <h2>Your feedback is important to us</h2>
    <Icon type="smile" className="dashboard-feedback-icon" />
    <div className="dashboard-feedback-intro">
      <p>
        As always, we would welcome your participation by notifying us of any content that you would like to see
        included:
      </p>
      <ul>
        <li>Industries you would like us to prioritise;</li>
        <li>Additional information you would like added;</li>
        <li>Ideas for improvement;</li>
        <li>Problems/difficulties using the site or</li>
        <li>Any other comments</li>
      </ul>
    </div>
    <Button
      type="primary"
      size="large"
      shape="circle"
      icon="mail"
      href={`mailto:${process.env.REACT_APP_EMAIL}`}
      className="dashboard-feedback-btn"
    />
  </div>
);
