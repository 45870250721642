import React from 'react';
import classNames from 'classnames';
import './Item.scss';

export default ({ children, label, size, layout }) => (
  <div className={classNames('account-item', size, layout)}>
    {label && <div className="account-item-label">{label}</div>}
    <div className="account-item-control">{children}</div>
  </div>
);
