import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Layout, Spin, Tabs, Empty } from 'antd';
import _ from 'lodash';
import { useAnalyticsEventTracker } from '../../../../components/Tracker/Tracker';
import Sider from '../../../../components/Sider/Sider';
import Table from '../../../../components/Table/Table';
import { fetchRiskChecklist } from '../../IndustryActions';
import { fetchInsuranceClasses } from '../../../Document/DocumentActions';
import {
  industryIdSelector,
  riskChecklistSelector,
  loadingIndustrySelector,
  loadingRiskChecklistSelector,
} from '../../IndustrySelectors';
import { riskChecklistNavItemsSelector, loadingInsuranceClassesSelector } from '../../../Document/DocumentSelectors';
import { countryCodeSelector } from '../../../Account/AccountSelectors';

const { Content } = Layout;
const { TabPane } = Tabs;
const QUESTION_TYPES = Object.freeze(['Core', 'General', 'Key']);
const DEFAULT_TYPE = QUESTION_TYPES[2];

const RiskChecklist = ({
  industryId,
  riskChecklistNavItems = [],
  riskChecklist = [],
  loading,
  loadingInsuranceClasses,
  fetchInsuranceClasses,
  fetchRiskChecklist,
  userCountryCode,
}) => {
  const [activeCategory, setActiveCategory] = useState();
  const [activeType, setActiveType] = useState(DEFAULT_TYPE);
  const { pathname } = useLocation();
  const eventTracker = useAnalyticsEventTracker('user');

  useEffect(() => {
    fetchInsuranceClasses(userCountryCode);
  }, [fetchInsuranceClasses, userCountryCode]);

  useEffect(() => {
    if (riskChecklistNavItems && riskChecklistNavItems.length > 0) {
      const lastSegment = pathname.split('/').pop();

      // When the last segment of the path is not "risk-checklist", a section should be
      // selected. Otherwise, the first section should be selected.
      if (lastSegment !== 'risk-checklist') {
        const selectedClass = _.find(riskChecklistNavItems, { route: lastSegment });
        if (selectedClass) {
          setActiveCategory(selectedClass);
        }
      } else {
        setActiveCategory(riskChecklistNavItems[0]);
      }
    }
  }, [riskChecklistNavItems, pathname]);

  useEffect(() => {
    if (industryId && activeCategory && activeType) {
      fetchRiskChecklist(industryId, {
        categories: activeCategory.fallbackId ? activeCategory.fallbackId : activeCategory.id,
        type: activeType,
      });
    }
  }, [industryId, activeCategory, activeType, fetchRiskChecklist]);

  const onCategorySelect = ({ key }) => {
    const seletedClass = _.find(riskChecklistNavItems, { id: key });
    setActiveCategory(seletedClass);
    if (seletedClass) {
      eventTracker('selected insurance class of risk checklist', seletedClass.name);
    }
  };

  const onTypeSelect = (key) => {
    setActiveType(key);
  };

  return (
    <Layout>
      {loadingInsuranceClasses ? (
        <Spin className="component-wrapper" />
      ) : (
        <>
          <Sider
            collapsible
            title="Risk Checklist"
            menuItems={riskChecklistNavItems}
            selectedMenuItem={activeCategory?.id}
            onMenuClick={onCategorySelect}
          />
          <Content className="component-wrapper">
            <Tabs defaultActiveKey={DEFAULT_TYPE} onChange={onTypeSelect} className="risk-checklist-tabs">
              {QUESTION_TYPES.map((item) => (
                <TabPane key={item} tab={item}>
                  {loading ? (
                    <Spin />
                  ) : (
                    <>
                      {riskChecklist.length > 0 ? (
                        riskChecklist.map((item) => (
                          <Table
                            key={item.section}
                            columns={[{ key: 'title', title: item.section, dataIndex: 'title' }]}
                            dataSource={item.riskChecklist}
                            className="card"
                          />
                        ))
                      ) : (
                        <Empty description="No Risk Checklist" />
                      )}
                    </>
                  )}
                </TabPane>
              ))}
            </Tabs>
          </Content>
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  userCountryCode: countryCodeSelector(state),
  industryId: industryIdSelector(state),
  riskChecklist: riskChecklistSelector(state),
  riskChecklistNavItems: riskChecklistNavItemsSelector(state),
  loading: loadingIndustrySelector(state) || loadingRiskChecklistSelector(state),
  loadingInsuranceClasses: loadingInsuranceClassesSelector(state),
});

const mapDispatchToProps = { fetchRiskChecklist, fetchInsuranceClasses };

export default connect(mapStateToProps, mapDispatchToProps)(RiskChecklist);
