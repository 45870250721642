import React, { useState } from 'react';
import { Form, Row, Col, InputNumber, Select, Radio, Typography } from 'antd';
import Icon from '../../../../../../components/Icon/Icon';
import Button from '../../../../../../components/Button/Button';
import { IconSumPolicy, IconLimitPolicy } from '../../assets/icons';

const FormItem = Form.Item;
const { Option } = Select;
const { Text } = Typography;

const formItemLayout = {
  labelCol: { xs: 24, sm: 9, md: 7, lg: 6, xl: 5 },
  wrapperCol: { xs: 24, sm: 15, md: 17, lg: 18, xl: 19 },
};
const CALCULATION_TYPES = Object.freeze(['Sum Insured', 'Limit of Liability']);
const PERCENTAGE_OPTIONS = Object.freeze([0.8, 0.85, 0.9, 0.95, 1]);

const CurrencyInput = (props) => (
  <InputNumber
    {...props}
    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
    style={{ width: '100%' }}
  />
);

const formatCurrency = (value) => {
  if (value === null || typeof value === 'undefined') {
    return '';
  }
  const list = value.toFixed(0).split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `$${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
};

export default () => {
  const [type, setType] = useState(CALCULATION_TYPES[0]);
  const [limit, setLimit] = useState(0);
  const [sum, setSum] = useState(0);
  const [riskValue, setRiskValue] = useState(0);
  const [percentage, setPercentage] = useState(PERCENTAGE_OPTIONS[0]);
  const [loss, setLoss] = useState(0);
  const [insurerPays, setInsurerPays] = useState(NaN);

  const valid = sum && riskValue && loss;
  const resultOk = !Number.isNaN(insurerPays) && Number.isFinite(insurerPays);

  const submit = () => {
    const result = (sum / (percentage * riskValue)) * loss;
    const insurerPays = type === CALCULATION_TYPES[0] ? result : Math.min(result, limit);
    setInsurerPays(insurerPays);
  };

  const print = () => {
    const calculatorPrintingItems = {
      type,
      inputs: [
        type === CALCULATION_TYPES ? { label: 'Limit of Liability', value: formatCurrency(limit) } : undefined,
        {
          label: type === CALCULATION_TYPES[0] ? 'Sum Insured for the item' : 'Declared Value',
          value: formatCurrency(sum),
        },
        { label: 'Estimated Value at Risk', value: formatCurrency(riskValue) },
        { label: 'Co-Insurance Percentage', value: `${percentage * 100}%` },
        { label: 'Loss', value: formatCurrency(loss) },
      ].filter((item) => item !== undefined),
      results: [
        { label: 'Insurer Pays', value: formatCurrency(insurerPays) },
        { label: 'Uninsured Portion', value: formatCurrency(loss - insurerPays) },
      ],
    };
    sessionStorage.setItem('calculator_printing_items', JSON.stringify(calculatorPrintingItems));
    window.open('/calculator/under-insurance/printing', '_blank');
  };

  return (
    <div className="calculator-form card">
      <h3 className="calculator-form-title">Under-insurance Calculator</h3>
      <Form colon={false} labelAlign="left" {...formItemLayout}>
        <Row type="flex" justify="center">
          <Col span={24} className="calculator-form-section">
            <div className="calculator-form-label">
              <span>Does the policy have a Sum Insured or Limit of Liability?</span>
            </div>
            <FormItem>
              <Radio.Group
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                  setInsurerPays(NaN);
                }}
              >
                {CALCULATION_TYPES.map((item) => (
                  <Radio key={item} value={item}>
                    {item}
                  </Radio>
                ))}
              </Radio.Group>
            </FormItem>
          </Col>
          <Col span={24} className="calculator-form-section">
            <div className="calculator-form-label">
              <Icon size={30} component={type === CALCULATION_TYPES[0] ? IconSumPolicy : IconLimitPolicy} />
              <span>Policy with a {type}</span>
            </div>
            {type === CALCULATION_TYPES[1] && (
              <FormItem label="Limit of Liability">
                <CurrencyInput size="large" value={limit} onChange={setLimit} />
              </FormItem>
            )}
            <FormItem label={type === CALCULATION_TYPES[0] ? 'Sum Insured for the item' : 'Declared Value'}>
              <CurrencyInput size="large" value={sum} onChange={setSum} />
            </FormItem>
            <FormItem label="Estimated Value at Risk">
              <CurrencyInput size="large" value={riskValue} onChange={setRiskValue} />
            </FormItem>
            <FormItem label="Co-Insurance Percentage">
              <Select size="large" defaultValue={PERCENTAGE_OPTIONS[0]} onChange={(value) => setPercentage(+value)}>
                {PERCENTAGE_OPTIONS.map((item) => (
                  <Option key={item} value={item}>{`${item * 100}%`}</Option>
                ))}
              </Select>
            </FormItem>
            <FormItem label="Loss">
              <CurrencyInput size="large" value={loss} onChange={setLoss} />
            </FormItem>
          </Col>
          {resultOk && (
            <Col span={24} className="calculator-form-section">
              <div className="calculator-form-label calculator-form-result">
                <span>
                  Insurer Pays = <strong>{formatCurrency(insurerPays)}</strong> (less deductible),{' '}
                  <Text type="danger">
                    Uninsured Portion = <strong>{formatCurrency(loss - insurerPays)}</strong>
                  </Text>{' '}
                  (plus deductible)
                </span>
              </div>
            </Col>
          )}
          <Col xs={24} lg={16} xl={12} xxl={8}>
            <>
              <Button
                type="primary"
                size="large"
                block
                disabled={!valid}
                className="calculator-form-btn"
                onClick={submit}
              >
                Calculate
              </Button>
              {resultOk && (
                <Button ghost size="large" block onClick={print}>
                  Print
                </Button>
              )}
            </>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
