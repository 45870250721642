import React from 'react';
import { connect } from 'react-redux';
import { Select, message } from 'antd';
import _ from 'lodash';
import Card from '../../components/Card/Card';
import Item from '../../components/Item/Item';
import { updateUserSettings } from '../../AccountActions';
import {
  taxonomiesSelector,
  themeSelector,
  industryClassificationSelector,
  countrySelector,
  currencySelector,
  dateFormatSelector,
  loadingUserSettingsSelector,
  loadingTaxonomiesSelector,
} from '../../AccountSelectors';
import './Setting.scss';
import countryJson from '../../data/country.json';
import currencyJson from '../../data/currency.json';
import dateFormatJson from '../../data/dateFormat.json';

const { Option } = Select;

const getRegion = (value) => _.find(countryJson, { code: value });

const Setting = ({
  loading,
  taxonomies = [],
  industryClassification,
  country,
  currency,
  dateFormat,
  updateUserSettings,
}) => {
  const update = async (data) => {
    try {
      const res = await updateUserSettings(data);
      message.success(res);
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <div className="component-wrapper">
      <Card title="Settings" loading={loading}>
        {/* <Item label="Color Theme" size="large" layout="vertical">
          <div className="account-setting-colors">
            {themeJson.map(item => (
              <ColorTile
                key={item.id}
                id={item.id}
                colors={item.colorList}
                selected={item.id === theme}
                onClick={value => update({ theme: value })}
              />
            ))}
          </div>
        </Item> */}
        <Item label="Industry Classification" size="large">
          <Select
            value={industryClassification}
            className="account-setting-select"
            onChange={(value) => update({ industry_classification: value })}
          >
            {taxonomies.map((item) => (
              <Option key={item.route} title={item.name}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item label="Country or Region" size="large">
          <Select
            value={country}
            className="account-setting-select"
            onChange={(value) =>
              update({
                country_region: value,
                currency: getRegion(value).currency,
                date_format: getRegion(value).dateFormat,
              })
            }
          >
            {countryJson.map((item) => (
              <Option key={item.code}>{item.name}</Option>
            ))}
          </Select>
        </Item>
        {country === 'others' && (
          <>
            <Item label="Currency" size="large">
              <Select
                value={currency}
                className="account-setting-select"
                onChange={(value) => update({ currency: value })}
              >
                {currencyJson.map((item) => (
                  <Option key={item.code}>{item.code}</Option>
                ))}
              </Select>
            </Item>
            <Item label="Date Format" size="large">
              <Select
                value={dateFormat}
                className="account-setting-select"
                onChange={(value) => update({ date_format: value })}
              >
                {dateFormatJson.map((item) => (
                  <Option key={item.format}>{item.format}</Option>
                ))}
              </Select>
            </Item>
          </>
        )}
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  taxonomies: taxonomiesSelector(state),
  theme: themeSelector(state),
  industryClassification: industryClassificationSelector(state),
  country: countrySelector(state),
  currency: currencySelector(state),
  dateFormat: dateFormatSelector(state),
  loading: loadingUserSettingsSelector(state) || loadingTaxonomiesSelector(state),
});

const mapDispatchToProps = { updateUserSettings };

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
