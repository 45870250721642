import React from 'react';
import Example from '../components/Example/Example';
import { IconSiren, IconLightBulb } from '../assets/icons';

export default [
  {
    menuTitle: 'When a claim occurs',
    title: 'What happens in the event of a claim',
    icon: IconSiren,
    content: (
      <>
        <p>
          Please Note that this calculator has been built for the Australian market. If you wish to use this calculator
          to illustrate the effects of under insurance outside of Australia you will need to ensure that the loss amount
          selected is equal to or greater than the Sum Insured.
        </p>
        <p>
          Many people incorrectly believe that if they under insure the only penalty that they are exposed to is if the
          claim exceeds the sum insured. This is simply not correct.
        </p>
        <p>
          Insurance premiums are based on the assumption that the assests or in the case of business interuption, the
          insurable gross profit are insured for their full value at the inception date of the policy. If the assets or
          insurable gross profit are not insured for their full value a business insurance policy will typically treat
          the policy holder as being a co-insurer for all claims over 5% or 10% of the declared value. Many insurance
          policies allow a percentage of tolerance in getting it right. This is typically 15% (85% co-insurance) or 20%
          (80% co-insurance). There is no tolerance with some business interruption cover such as a standard Industrial
          Special Risks policy.
        </p>
        <p>
          There are two tests done when a claim occurs. The first is the test for under insurance and the second is a
          test against the policy limit.
        </p>
        <p>
          The following calculation is designed to highlight the penalty that will be imposed should the sum insured or
          declared value be under the true value at risk at the start date of the policy.
        </p>
        <p>
          Please remember that the test for Under Insurance is the first of two tests. An insurer will not pay more than
          the Sum Insured/Limit of Liability regardless of the amount arrived at by the Under Insurance Test.
        </p>
      </>
    ),
  },
  {
    menuTitle: 'Example',
    icon: IconLightBulb,
    content: <Example />,
    webOnly: true,
  },
];
