import React from 'react';
import { connect } from 'react-redux';
import { Heading, Content } from '../ReportComponent/ReportComponent';
import { sectionsSelector, reportCompanyProfileSelector } from '../../../../ReportSelectors';
import './CompanyProfile.scss';

const CompanyProfile = ({ sections, companyInfo }) =>
  companyInfo ? (
    <section className="no-page-break">
      <Heading seq={sections['company-profile'].seq} anchor="company-profile">
        Why Partner with {companyInfo.name}
      </Heading>
      <Content printGap="small">
        {companyInfo.description && <div dangerouslySetInnerHTML={{ __html: companyInfo.description }} />}
      </Content>
    </section>
  ) : null;

const mapStateToProps = (state) => ({
  sections: sectionsSelector(state),
  companyInfo: reportCompanyProfileSelector(state),
});

export default connect(mapStateToProps)(CompanyProfile);
