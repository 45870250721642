/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { message } from 'antd';
import Item from '../Item/Item';
import Groups from '../Groups/Groups';

const UnderwriterList = ({
  list = [],
  onCheck,
  onGroupCheck,
  loading,
  sections,
  customization,
  updateReportCustomizations,
  loadingCustomizations,
  industryId,
  resetHazardIndexChartDataUrl,
}) => {
  const hazardIndexChildren = sections['report-hazard-assessment']?.children;
  const selectedClasses = customization?.customizations
    .filter((hazardIndex) => hazardIndex?.checked)
    .map((selected) => `${selected.id}`);

  useEffect(() => {
    setTimeout(() => {
      if (!loading) onGroupCheck(selectedClasses, 'report-hazard-assessment');
    }, 0);
  }, [loading, loadingCustomizations]);

  // eslint-disable-next-line consistent-return
  const save = async (checkedList) => {
    if (loadingCustomizations) return null;

    const selectedClassesToSave = checkedList.map((selectedClassId) => {
      const classFound = customization.customizations.find((hazardClass) => hazardClass.id === +selectedClassId);
      return {
        ...classFound,
        id: +selectedClassId,
        checked: true,
      };
    });

    const hazardIndexWithComments = customization.customizations
      .filter(
        (hazardIndex) =>
          !selectedClasses.includes(hazardIndex?.id) && (hazardIndex.comments || hazardIndex.checklist?.length)
      )
      .map((hazardIndex) => ({
        ...hazardIndex,
        checked: false,
      }));

    try {
      await updateReportCustomizations(industryId, customization?.id, customization?.name, [
        {
          section: 'hazard_index_underwriter',
          customization: JSON.stringify({ classes: [...selectedClassesToSave, ...hazardIndexWithComments] }),
        },
      ]);
      resetHazardIndexChartDataUrl();
      message.success('Saved');
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <div className="report-list">
      <ul>
        {list.map((item) => {
          if (item?.children?.length) {
            return (
              <Groups
                key={item.id}
                item={item}
                onCheck={onCheck}
                onGroupCheck={onGroupCheck}
                underwriter
                save={save}
                selectedIndexes={hazardIndexChildren}
              />
            );
          }

          return (
            <Item
              key={item.id}
              item={item}
              checked={list
                .filter((item) => item.checked)
                .map((item) => item.id)
                .includes(item.id)}
              onCheck={(e) => onCheck(e.target.checked, item.id)}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default UnderwriterList;
