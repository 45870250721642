import React, { useState } from 'react';
import classNames from 'classnames';
import Navigation from '../Navigation/Navigation';
import Content from '../Content/Content';
import './Notepad.scss';

export default ({ notes, searchable = false, editable = false }) => {
  const [selectedKey, setSelectedKey] = useState(0);
  const [current, setCurrent] = useState('left');

  return (
    <div className="notepad card">
      <div className={classNames('notepad-slider', current)}>
        <div className="notepad-slider-slide left">
          <Navigation
            notes={notes}
            searchable={searchable}
            selectedKey={selectedKey}
            onSelect={(key) => {
              setSelectedKey(key);
              setCurrent('right');
            }}
          />
        </div>
        <div className="notepad-slider-slide right">
          <Content note={notes[selectedKey]} editable={editable} onBack={() => setCurrent('left')} />
        </div>
      </div>
    </div>
  );
};
