import React, { useState } from 'react';
import { Icon } from 'antd';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';
import './IntroTailoredReport.scss';
import { ReactComponent as QuestionIcon } from './assets/question_icon.svg';

const INTRO_TAILORED_VIEWED = 'intro_tailored_report_viewed';

export default ({ icon, onButtonClick, mode = 'report' }) => {
  const visited = localStorage.getItem(INTRO_TAILORED_VIEWED);
  const [url, setUrl] = useState();

  return (
    <Modal
      defaultVisible={mode === 'dashboard' ? false : !visited}
      width="auto"
      destroyOnClose
      centered
      closable={mode === 'dashboard'}
      bodyStyle={{ width: '90vw', maxWidth: '1180px' }}
      toggle={(show) => {
        if (mode === 'dashboard') {
          return (
            <Button
              size="large"
              type="primary"
              className="play-video-button"
              icon="play-circle"
              onClick={(e) => {
                e.stopPropagation();
                show();
              }}
            >
              Play Video
            </Button>
          );
        }
        return (
          <button
            type="button"
            className="toggle-button"
            onClick={(e) => {
              e.stopPropagation();
              show();
            }}
          >
            <Icon component={QuestionIcon} style={{ fontSize: '16px', marginRight: '0px' }} />
          </button>
        );
      }}
      afterClose={() => {
        if (!visited) {
          localStorage.setItem(INTRO_TAILORED_VIEWED, true);
        }
        if (onButtonClick && url) onButtonClick(url);
      }}
    >
      {(hide) => (
        <>
          <div className="corner-ribbon">NEW</div>
          <div className="intro-inner-wrapper">
            <div className="intro-title">
              {icon && <Icon component={icon} style={{ fontSize: '32px', color: '#019cae', marginRight: '14px' }} />}
              Tailored Report
            </div>
            <div className="intro-content">
              Personalise your industry report for your client by making insurance class recommendations and adding your
              comments to each.
            </div>
            <div className="video-responsive-container">
              <iframe
                src="https://www.youtube.com/embed/QuQnlKg0j8M?rel=0"
                title="RiskCoach - Tailored Report"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            {mode === 'report' && (
              <>
                <div>
                  <Button
                    type="link"
                    size="large"
                    className="tailored-report-btn"
                    onClick={() => {
                      setUrl({ key: 'tailored-report' });
                      hide();
                    }}
                  >
                    Start Your Tailored Report
                  </Button>
                </div>
                <div>
                  <Button
                    type="link"
                    size="large"
                    className="quick-report-btn"
                    onClick={() => {
                      setUrl({ key: 'quick-report' });
                      hide();
                    }}
                  >
                    Thank you, I prefer a quick report
                  </Button>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};
