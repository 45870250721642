import axios from 'axios';
import { fetchUserProfile } from '../Account/AccountActions';

export const REQUEST_POINTS = 'point/REQUEST_POINTS';
export const RECEIVE_POINTS = 'point/RECEIVE_POINTS';
export const RECEIVE_PERIOD = 'point/RECEIVE_PERIOD';

const API_BASE = process.env.REACT_APP_API_BASE;

const requestPoints = () => ({ type: REQUEST_POINTS });
const receivePoints = (data) => ({ type: RECEIVE_POINTS, points: data });
const receivePeriod = (data) => ({ type: RECEIVE_PERIOD, period: data });

export const fetchCurrentPeriodPoints = () => async (dispatch) => {
  dispatch(requestPoints());
  const url = `${API_BASE}user/points`;

  try {
    const res = await axios.get(url);
    dispatch(receivePoints(res.data));
  } catch (err) {
    dispatch(receivePoints());
  }
};

export const fetchLastPeriodPoints =
  ({ cipEnddate, cpdEnddate }) =>
  async (dispatch) => {
    dispatch(requestPoints());
    const cipUrl = `${API_BASE}user/points/cip`;
    const cpdUrl = `${API_BASE}user/points/cpd`;

    try {
      axios
        .all([
          axios.get(cipUrl, { params: { until: cipEnddate } }),
          axios.get(cpdUrl, { params: { until: cpdEnddate } }),
        ])
        .then(([cipRes, cpdRes]) => {
          const lastPeriodPoints = {
            cip: cipRes.data,
            cpd: cpdRes.data,
          };
          dispatch(receivePoints(lastPeriodPoints));
        });
    } catch (err) {
      dispatch(receivePoints());
    }
  };

export const updatePoints = (data) => async (dispatch) => {
  const url = `${API_BASE}user/stats`;
  try {
    await axios.post(url, data);
    dispatch(fetchUserProfile());
  } catch (err) {
    console.error(err);
  }
};

export const updatePeriod = (data) => (dispatch) => {
  dispatch(receivePeriod(data));
};
