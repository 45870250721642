import React from 'react';
import { Spin } from 'antd';
import Cascader from '../../../../../../components/Cascader/Cascader';

export default ({ loading, industryCategoryList, loadData, goToIndustry }) => (
  <div>
    {loading ? (
      <Spin className="component-wrapper" />
    ) : (
      industryCategoryList && (
        <Cascader
          options={industryCategoryList.data}
          meta={industryCategoryList.meta.structure.split(',')}
          fieldNames={{ label: 'title' }}
          loadData={loadData}
          select={goToIndustry}
        />
      )
    )}
  </div>
);
