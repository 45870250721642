import React from 'react';
import { Layout, Row, Col, Button, Divider } from 'antd';
import './Footer.scss';

const { Footer } = Layout;
const socialLinks = [
  { icon: 'fa-linkedin', url: 'https://www.linkedin.com/company/lmi-group/mycompany/' },
  { icon: 'fa-facebook-square', url: 'https://www.facebook.com/LMIGroupPtyLtd' },
  { icon: 'fa-youtube', url: 'https://www.youtube.com/user/LMIGROUP' },
];

export default () => (
  <Footer className="footer">
    <Row type="flex" justify="space-between" align="middle">
      <Col xs={24} sm={18}>
        <div className="footer-links">
          <Button type="link" href="https://lmigroup.io/privacy/" target="_blank">
            Privacy Policy
          </Button>
          <Button type="link" href="https://lmigroup.io/terms-conditions/" target="_blank">
            Terms and Conditions
          </Button>
          <Button type="link" href="mailto: riskcoach@lmigroup.com" target="_blank">
            Contact Us
          </Button>
        </div>
      </Col>
      <Col xs={24} sm={6}>
        <div className="footer-social-links">
          {socialLinks.map((item) => (
            <Button
              key={item.icon}
              shape="circle"
              type="primary"
              size="small"
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className={`fab ${item.icon}`} />
            </Button>
          ))}
        </div>
      </Col>
    </Row>
    <div className="footer-copyright">
      <Divider className="divider" />
      &copy; 1999 - {new Date().getFullYear()} <strong>LMI Group</strong>. All Rights Reserved.
    </div>
  </Footer>
);
