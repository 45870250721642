import React, { useState, useEffect } from 'react';
import { Empty, Icon, Typography } from 'antd';
import QueueAnim from 'rc-queue-anim';
import TweenOne, { TweenOneGroup } from 'rc-tween-one';
import _ from 'lodash';
import ResizeObserver from 'resize-observer-polyfill';
import Button from '../../../../../../components/Button/Button';
import Card from '../Card/Card';
import './Gallery.scss';

const { Paragraph } = Typography;

const ratio = 0.67;

export default ({ items = [], gutter = 20 }) => {
  const [layout, setLayout] = useState();
  const [picOpen, setPicOpen] = useState({});

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const containerWidth = entry.contentRect.width;
        const col = Math.max(Math.floor(containerWidth / 220), 2);
        const imgContainerWidth = (containerWidth + gutter) / col;
        const imgContainerHeight = imgContainerWidth * ratio;
        const containerHeight = (imgContainerHeight * items.length) / col;
        setLayout({ col, containerWidth, containerHeight, imgContainerWidth, imgContainerHeight });
      });
    });
    resizeObserver.observe(document.getElementById('industry-media-container'));
  }, [gutter, items.length]);

  const onImgClick = (e, i) => {
    const newPicOpen = { ...picOpen };
    e.stopPropagation();
    _.keys(newPicOpen).forEach((key) => {
      if (key !== i && newPicOpen[key]) {
        newPicOpen[key] = false;
      }
    });
    newPicOpen[i] = true;
    setPicOpen(newPicOpen);
  };

  const onClose = (i) => {
    const newPicOpen = { ...picOpen };
    newPicOpen[i] = false;
    setPicOpen(newPicOpen);
  };

  const prev = (e, index) => {
    onClose(index);
    onImgClick(e, index - 1);
  };

  const next = (e, index) => {
    onClose(index);
    onImgClick(e, index + 1);
  };

  const onTweenEnd = (i) => {
    const newPicOpen = { ...picOpen };
    delete newPicOpen[i];
    setPicOpen(newPicOpen);
  };

  const getDelay = ({ index }) => {
    const { col } = layout;
    return (index % col) * 100 + Math.floor(index / col) * 100;
  };

  const getChildren = () => {
    const { col, imgContainerWidth, imgContainerHeight } = layout;
    const imgWidth = imgContainerWidth - gutter;
    const imgHeight = imgContainerHeight - gutter;

    return items.map((item, index) => {
      const isEnter = typeof picOpen[index] === 'boolean';
      const isOpen = picOpen[index];

      const left = isEnter ? 0 : imgContainerWidth * (index % col);
      const imgLeft = isEnter ? imgContainerWidth * (index % col) : 0;
      const isRight = Math.floor((index % col) / (col / 2));
      const isTop = Math.floor(index / col);
      let top = isTop ? (isTop - 1) * imgContainerHeight : 0;
      top = isEnter ? top : imgContainerHeight * isTop;
      let imgTop = isTop ? imgContainerHeight : 0;
      imgTop = isEnter ? imgTop : 0;

      const liStyle = isEnter
        ? { width: '100%', height: imgContainerHeight * Math.max(Math.floor(col / 2), 2) - gutter, zIndex: 1 }
        : null;
      const liAnimation = isOpen
        ? { boxShadow: '0 2px 8px rgba(140, 140, 140, .35)' }
        : { boxShadow: '0 0px 0px rgba(140, 140, 140, 0)' };
      let aAnimation = isEnter
        ? {
            delay: 400,
            ease: 'easeInOutCubic',
            top: isTop ? imgContainerHeight : 0,
            left: imgContainerWidth * (index % col),
            width: imgWidth,
            height: imgHeight,
            onComplete: onTweenEnd.bind(this, index),
          }
        : null;
      aAnimation = isOpen
        ? {
            ease: 'easeInOutCubic',
            top: 0,
            left: isRight && col > 2 ? (imgContainerWidth * col - gutter) / 2 : 0,
            width: col > 2 ? '50%' : '100%',
            height: '100%',
          }
        : aAnimation;

      return (
        <TweenOne
          key={item.id}
          component="li"
          componentProps={{ className: 'gallery-item' }}
          style={{ top, left, ...liStyle }}
          animation={liAnimation}
        >
          <TweenOne
            component="a"
            onClick={(e) => onImgClick(e, index)}
            style={{ top: imgTop, left: imgLeft, width: imgWidth, height: imgHeight }}
            animation={aAnimation}
          >
            <Card type={item.type} open={isOpen} thumbnail={item.thumbnail_url} content={item.content_url} />
          </TweenOne>
          <TweenOneGroup
            component=""
            enter={[
              { opacity: 0, duration: 0, type: 'from', delay: 400 },
              { ease: 'easeOutCubic', type: 'from', ...(col > 2 ? { left: isRight ? '50%' : '0%' } : { top: '0%' }) },
            ]}
            leave={{ ease: 'easeInOutCubic', ...(col > 2 ? { left: isRight ? '50%' : '0%' } : { top: '0%' }) }}
          >
            {isOpen && (
              <div
                className="gallery-item-info"
                key="text"
                style={
                  col > 2
                    ? { left: isRight ? '0%' : '50%', width: '50%', height: '100%' }
                    : { top: isTop ? '100%' : '100%', width: '100%' }
                }
              >
                <Icon type="close" className="gallery-item-info-close" onClick={() => onClose(index)} />
                <h2>{item.description}</h2>
                <hr />
                <Paragraph ellipsis={{ rows: 4 }}>
                  <em>Source: {item.source}</em>
                </Paragraph>
                <div className="gallery-item-info-btns">
                  <Button type="link" secondary onClick={(e) => prev(e, index)}>
                    <Icon type="left" />
                    Prev
                  </Button>
                  <Button type="link" secondary onClick={(e) => next(e, index)}>
                    Next
                    <Icon type="right" />
                  </Button>
                </div>
              </div>
            )}
          </TweenOneGroup>
        </TweenOne>
      );
    });
  };

  return (
    <div className="gallery" id="industry-media-container">
      {layout && items.length > 0 ? (
        <QueueAnim
          type="bottom"
          component="ul"
          delay={getDelay}
          interval={0}
          className="gallery-list"
          style={{ height: layout.containerHeight }}
        >
          {getChildren()}
        </QueueAnim>
      ) : (
        <Empty />
      )}
    </div>
  );
};
