import { createSelector } from 'reselect';
import { userProfileSelector } from '../Account/AccountSelectors';

export const pointsSelector = createSelector(userProfileSelector, (userProfile) =>
  userProfile ? userProfile.points : undefined
);

export const loadingPointsSelector = (state) => state.point.loadingPoints;

export const periodPointsSelector = (state) => state.point.points;
