import React from 'react';
import { Checkbox } from 'antd';
import Item from '../Item/Item';
import HazardIndexItem from '../HazardIndexItem/HazardIndexItem';
import './Groups.scss';

const CheckboxGroup = Checkbox.Group;
const Groups = ({ item, onCheck, onGroupCheck, underwriter, selectedIndexes, save }) => {
  const list = item.children.map((item) => item.id);
  const checkedList = item.checkedChildren || [];
  const indeterminate = !!checkedList.length && checkedList.length < list.length;

  return (
    <Item
      item={item}
      extra={
        <Checkbox
          value={item.id}
          checked={item.checked}
          indeterminate={indeterminate}
          onChange={(e) => onCheck(e.target.checked, item.id)}
          disabled={item.required}
        />
      }
    >
      {!item.required && (
        <CheckboxGroup
          value={checkedList}
          onChange={(checkedList) => onGroupCheck(checkedList, item.id)}
          disabled={item.required}
        >
          <ul>
            {item.children.map((child) => {
              return <Item key={child.id} item={child} size="small" />;
            })}
          </ul>
        </CheckboxGroup>
      )}

      {underwriter && (
        <CheckboxGroup
          checked
          value={selectedIndexes}
          onChange={(checkedList) => {
            save(checkedList);
          }}
        >
          <div>
            {item.children.map((child, index) => {
              return (
                <div key={child.id} className="hazard-index-item-container appearance-row underwriter-list">
                  <HazardIndexItem
                    key={child.id}
                    data={child}
                    size="small"
                    appearance="row"
                    index={index}
                    underwriter
                  />
                  <Checkbox value={child.id} className="check-box" />
                </div>
              );
            })}
          </div>
        </CheckboxGroup>
      )}
    </Item>
  );
};

export default Groups;
