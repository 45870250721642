import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { CountryContext } from '../../contexts';
import { countrySelector } from '../../scenes/Account/AccountSelectors';
import Theme from '../Theme/Theme';
import Spinner from '../Spinner/Spinner';
import { initialize } from './AppActions';

const App = ({ children, initialized, initialize, country }) => {
  useEffect(() => {
    initialize();
  }, [initialize]);

  return initialized ? (
    <CountryContext.Provider value={{ country }}>
      <Theme>{children}</Theme>
    </CountryContext.Provider>
  ) : (
    <Spinner />
  );
};

const mapStateToProps = (state) => ({
  initialized: state.app.initialized,
  country: countrySelector(state),
});

const mapDispatchToProps = { initialize };

export default connect(mapStateToProps, mapDispatchToProps)(App);
