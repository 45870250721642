import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import Button from '../../../../../../components/Button/Button';
import { unitSelector, loadingUnitSelector } from '../../../../CourseSelectors';

const CourseContent = ({ loading, content = {}, prev, next, showPrev, showNext, onTest }) =>
  loading ? (
    <Spin />
  ) : (
    <div className="course-detail-content card">
      <div className="course-detail-title">
        <h2>{content.title}</h2>
      </div>
      <div dangerouslySetInnerHTML={{ __html: content.text }} />
      <div className="course-detail-btns">
        {showPrev && <Button shape="circle" icon="left" onClick={prev} />}
        {showNext ? (
          <Button type="primary" shape="circle" icon="right" onClick={next} />
        ) : (
          <Button type="primary" shape="round" onClick={onTest}>
            Test your knowledge
          </Button>
        )}
      </div>
    </div>
  );

const mapStateToProps = (state) => ({
  content: unitSelector(state),
  loading: loadingUnitSelector(state),
});

export default connect(mapStateToProps)(CourseContent);
