import axios from 'axios';
import { trialUserOptions } from '../Account/AccountSelectors';

export const REQUEST_COURSES = 'course/REQUEST_COURSES';
export const RECEIVE_COURSES = 'course/RECEIVE_COURSES';
export const REQUEST_COURSE = 'course/REQUEST_COURSE';
export const RECEIVE_COURSE = 'course/RECEIVE_COURSE';
export const REQUEST_UNIT = 'course/REQUEST_UNIT';
export const RECEIVE_UNIT = 'course/RECEIVE_UNIT';

const API_BASE = process.env.REACT_APP_API_BASE;

const requestCourses = () => ({ type: REQUEST_COURSES });
const receiveCourses = (data) => ({ type: RECEIVE_COURSES, courses: data });

const requestCourse = () => ({ type: REQUEST_COURSE });
const receiveCourse = (data) => ({ type: RECEIVE_COURSE, course: data });

const requestUnit = () => ({ type: REQUEST_UNIT });
const receiveUnit = (data) => ({ type: RECEIVE_UNIT, unit: data });

export const fetchCourses = (trial) => async (dispatch) => {
  dispatch(requestCourses());
  if (trial) {
    dispatch(receiveCourses(trialUserOptions.courses));
  } else {
    try {
      const url = `${API_BASE}courses`;
      const res = await axios.get(url);
      dispatch(receiveCourses(res.data));
    } catch (err) {
      dispatch(receiveCourses());
    }
  }
};

export const fetchCourse = (courseId) => async (dispatch) => {
  dispatch(requestCourse());
  const url = `${API_BASE}courses/${courseId}`;
  try {
    const res = await axios.get(url);
    dispatch(receiveCourse(res.data));
  } catch (err) {
    dispatch(receiveCourse());
  }
};

export const fetchUnit = (courseId, unitId) => async (dispatch) => {
  dispatch(requestUnit());
  const url = `${API_BASE}courses/${courseId}/units/${unitId}`;
  try {
    const res = await axios.get(url);
    dispatch(receiveUnit(res.data));
  } catch (err) {
    dispatch(receiveUnit());
  }
};
