import React from 'react';
import Icon from '../../../../components/Icon/Icon';
import IntroTailoredReport from '../../../Report/components/IntroTailoredReport/IntroTailoredReport';
import { IconTailoredReport } from './asset';
import './TailoredReport.scss';

const TailorReport = () => {
  return (
    <div className="tailor-report card">
      <h2>CREATE YOUR TAILORED REPORT</h2>
      <Icon
        component={IconTailoredReport}
        size={140}
        style={{ backgroundColor: 'white' }}
        className="tailor-report-icon"
      />
      <p className="tailor-report-description">
        Personalise your industry report for your client by making insurance class recommendations and adding your
        comments to each.
      </p>
      <IntroTailoredReport mode="dashboard" />
    </div>
  );
};
export default TailorReport;
