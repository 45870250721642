import React from 'react';
import classNames from 'classnames';
import { Icon } from 'antd';
import './Mode.scss';
import { ReactComponent as IconBar } from './assets/icon-bar.svg';
import { ReactComponent as IconGrid } from './assets/icon-grid.svg';

export default ({ activeMode, setMode }) => (
  <div className="hazard-index-mode">
    <Icon
      component={IconBar}
      className={classNames('hazard-index-mode-icon', { active: activeMode === 'chart' })}
      onClick={() => setMode('chart')}
    />
    <Icon
      component={IconGrid}
      className={classNames('hazard-index-mode-icon', { active: activeMode === 'grid' })}
      onClick={() => setMode('grid')}
    />
  </div>
);
