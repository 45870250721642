import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Heading, Content, List, ListItem } from '../ReportComponent/ReportComponent';
import { sectionsSelector, reportRiskChecklistSelector } from '../../../../ReportSelectors';

const RiskChecklist = ({ sections, filteredRiskChecklist = [] }) => {
  let subSeq = 0;
  return filteredRiskChecklist.length > 0 && sections && sections['report-risk-checklist'] ? (
    <section>
      <Heading seq={sections['report-risk-checklist'].seq} anchor="report-risk-checklist">
        Risk Checklist
      </Heading>
      <Content>
        {/* eslint-disable-next-line no-return-assign */}
        {filteredRiskChecklist.map((section) => (
          <Fragment key={section.id}>
            <Heading
              level={2}
              seq={`${sections['report-risk-checklist'].seq}.${(subSeq += 1)}`}
              anchor={section.anchor}
            >
              {`Risk Checklist: ${section.name}`}
            </Heading>
            <List data={section.items} ordered rowKey="id" renderItem={(item) => <ListItem>{item.name}</ListItem>} />
          </Fragment>
        ))}
      </Content>
    </section>
  ) : null;
};

const mapStateToProps = (state) => ({
  sections: sectionsSelector(state),
  filteredRiskChecklist: reportRiskChecklistSelector(state),
});

export default connect(mapStateToProps)(RiskChecklist);
