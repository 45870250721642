import React from 'react';
import classNames from 'classnames';
import { Avatar, Upload, message } from 'antd';
import Button from '../../../../components/Button/Button';
import './Avatar.scss';

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Only files with the following extensions are allowed: png jpg jpeg.');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('The image that you are trying to use is too large (over 2MB)');
  }
  return isJpgOrPng && isLt2M;
};

export default ({ size = 160, icon, src, editable, shape = 'circle', transparent = false, upload }) => (
  <div className="account-avatar">
    <Avatar
      size={size}
      icon={icon}
      src={src}
      shape={shape === 'rectangle' ? 'square' : shape}
      className={classNames({ transparent })}
      style={shape === 'rectangle' ? { height: size / 3, lineHeight: `${size / 3}px`, fontSize: size / 6 } : {}}
    />
    {editable && (
      <Upload showUploadList={false} beforeUpload={beforeUpload} customRequest={upload}>
        <Button type="primary" size="small" secondary>
          Choose File
        </Button>
      </Upload>
    )}
  </div>
);
