import React, { useState, useEffect } from 'react';
import { List } from 'antd';
import { Transition } from 'react-transition-group';
import classNames from 'classnames';
import Card from '../Card/Card';
import './List.scss';

export default ({ data, openModal }) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  return (
    <List
      grid={{ gutter: [40, 20], xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 6 }}
      dataSource={data}
      className="hazard-index-list"
      renderItem={(item, i) => (
        <Transition in={mounted} timeout={40 * i} mountOnEnter unmountOnExit>
          {(state) => (
            <List.Item key={item.id} className={classNames('hazard-index-list-item', state)}>
              <Card
                rating={item.rating}
                name={item.name}
                icon={item.icon}
                comment={item.comment}
                openModal={openModal}
              />
            </List.Item>
          )}
        </Transition>
      )}
    />
  );
};
