import React from 'react';
import moment from 'moment';
import { Page } from '../../../../../../../../components/Print/Print';
import './MarshCover.scss';
import poweredByLogo from '../../../../assets/powered-by-lmi.png';

const MarshCover = ({ theme, insuredBusinessName, industryName, date, dateFormat }) => {
  return (
    <Page>
      <div className="marsh-report-preview-cover">
        <div className="marsh-report-preview-cover-header">
          <div className="company-logo" />
        </div>
        <div className="marsh-report-preview-cover-center" style={{ backgroundColor: theme.covers[0].bgColor[0] }}>
          <h1 className="marsh-report-title" style={{ fontFamily: theme.headings.h1.font }}>
            Insurance Risk Report
          </h1>
          <h2
            className="insured-business-name"
            style={{ color: theme.visuals.divider.color, fontFamily: theme.headings.h2.font }}
          >
            {insuredBusinessName}
          </h2>
          <h2 className="marsh-report-title" style={{ fontFamily: theme.headings.h2.font }}>
            {industryName}
          </h2>
          <div className="date-issued" style={{ fontFamily: theme.body.font }}>
            <i>{`Date issued ${moment(date).format(dateFormat)}`}</i>
          </div>
        </div>
        <div className="marsh-report-preview-cover-footer">
          <div className="powered-by-container">
            <img src={poweredByLogo} height={60} alt="lmi-logo" />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default MarshCover;
