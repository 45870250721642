/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Form, Row, Col, Input, Icon, message } from 'antd';
import Button from '../../../../../../components/Button/Button';
import AddressSearch from '../../../../components/AddressSearch/AddressSearch';
import TextEditor from '../../../../../../components/TextEditor/TextEditor';
import { getCountryCode } from '../../../../utils';

const FormItem = Form.Item;
const MAX_COUNT = 300;

const getWordCount = (str) => {
  if (!str) {
    return 0;
  }
  // remove all tags and replace them with spaces
  let content = str.replace(/<[^>]*>/g, ' ');
  if (content.trim() === '') {
    return 0;
  }
  // remove (trim) all left and right spaces
  content = content.replace(/\s+/g, ' ');
  content = content.trim();
  return content.split(' ').length;
};

const ProfileEdit = ({ form, userProfile = {}, update, close }) => {
  const [wordCount, setWordCount] = useState(getWordCount(userProfile.description));

  const { getFieldDecorator } = form;
  const { suite, street, postcode, suburb, state, country } = userProfile.address || {};

  const submit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const {
          suite = '',
          street = '',
          suburb = '',
          postcode = '',
          state = '',
          country = '',
          description = null,
          email = null,
          job_title = null,
          mobile = null,
          person_name = null,
          phone = null,
        } = values;
        const countryCode = getCountryCode(country);
        if (!countryCode) {
          if (suite || street || suburb || postcode || state) {
            message.error('Address is invalid.');
          } else {
            const data = {
              description,
              email,
              job_title,
              mobile,
              person_name,
              phone,
              address: null,
            };
            update(data);
            close();
          }
        } else {
          const data = {
            description,
            email,
            job_title,
            mobile,
            person_name,
            phone,
            address: { suite, street, suburb, postcode, state, country: countryCode },
          };
          update(data);
          close();
        }
      }
    });
  };

  const onAddressChange = (address) => {
    if (address) {
      const { street, suburb, state, postcode, country } = address;
      form.setFieldsValue({ street, suburb, state, postcode, country });
    } else {
      form.setFieldsValue({ street: '', suburb: '', state: '', postcode: '', country: '' });
    }
  };

  const onAboutMeChange = (value) => {
    const count = getWordCount(value);
    setWordCount(count);
  };

  const validateAboutMe = (rule, value, callback) => {
    if (wordCount > MAX_COUNT) {
      callback(`The maximum number of words is ${MAX_COUNT}`);
    }
    callback();
  };

  return (
    <Form>
      <FormItem>
        {getFieldDecorator('person_name', { initialValue: userProfile.person_name })(
          <Input placeholder="Name" prefix={<Icon type="user" />} spellCheck={false} />
        )}
      </FormItem>
      <Row gutter={20}>
        <Col xs={24} sm={8}>
          <FormItem>
            {getFieldDecorator('suite', { initialValue: suite })(
              <Input placeholder="Suite/Level" prefix={<Icon type="bank" />} />
            )}
          </FormItem>
        </Col>
        <Col xs={24} sm={16}>
          <FormItem>
            {getFieldDecorator('street', {
              initialValue: street,
              normalize: (value) => (typeof value === 'object' ? value.street : value),
            })(
              <AddressSearch
                placeholder="Address"
                onChange={onAddressChange}
                prefix={<Icon type="environment" />}
                initialValue={street}
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={24} sm={6}>
          <FormItem>
            {getFieldDecorator('postcode', { initialValue: postcode })(<Input disabled placeholder="Postcode" />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={6}>
          <FormItem>
            {getFieldDecorator('suburb', { initialValue: suburb })(<Input disabled placeholder="City/Suburb" />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={6}>
          <FormItem>
            {getFieldDecorator('state', { initialValue: state })(<Input disabled placeholder="State" />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={6}>
          <FormItem>
            {getFieldDecorator('country', { initialValue: country ? country.name : undefined })(
              <Input disabled placeholder="Country (e.g., AU)" />
            )}
          </FormItem>
        </Col>
      </Row>
      <FormItem>
        {getFieldDecorator('email', { initialValue: userProfile.email })(
          <Input placeholder="Email" prefix={<Icon type="mail" />} spellCheck={false} />
        )}
      </FormItem>
      <Row gutter={20}>
        <Col xs={24} sm={12}>
          <FormItem>
            {getFieldDecorator('phone', { initialValue: userProfile.phone })(
              <Input placeholder="Phone" prefix={<Icon type="phone" />} />
            )}
          </FormItem>
        </Col>
        <Col xs={24} sm={12}>
          <FormItem>
            {getFieldDecorator('mobile', { initialValue: userProfile.mobile })(
              <Input placeholder="Mobile" prefix={<Icon type="mobile" />} />
            )}
          </FormItem>
        </Col>
      </Row>
      <FormItem>
        {getFieldDecorator('job_title', { initialValue: userProfile.job_title })(<Input placeholder="Job Title" />)}
      </FormItem>
      <FormItem extra={`${wordCount}/${MAX_COUNT}`}>
        {getFieldDecorator('description', {
          initialValue: userProfile.description,
          validateTrigger: ['onChange', 'onBlur', 'onFocus'],
          rules: [{ validator: validateAboutMe }],
        })(<TextEditor placeholder="About Me" autoSize={{ minRows: 2, maxRows: 6 }} onChange={onAboutMeChange} />)}
      </FormItem>
      <FormItem>
        <Button block type="primary" onClick={submit}>
          Save
        </Button>
      </FormItem>
    </Form>
  );
};

export default Form.create()(ProfileEdit);
