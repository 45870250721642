import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Radio, Spin } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import Button from '../../components/Button/Button';
import { submitAnswers } from './QuizActions';
import { countryCodeSelector } from '../Account/AccountSelectors';
import {
  quizIdSelector,
  quizQuestionsSelector,
  quizAnswersSelector,
  loadingQuizQuestionsSelector,
} from './QuizSelectors';
import { ReactComponent as PoweredByLmiCollege } from '../../assets/images/lmi-college.svg';
import './Quiz.scss';

const Quiz = ({ form, quizId, loading, questions = [], answers, countryCode, fetchQuestions, submitAnswers }) => {
  const [testing, setTesting] = useState(false);

  const start = () => {
    fetchQuestions();
    setTesting(true);
  };

  const done = () => {
    setTesting(false);
  };

  const submit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const data = _.map(values, (answerId, questionId) => ({
          question_id: Number(questionId),
          answer_id: answerId,
        }));
        submitAnswers(quizId, { answers: data, country: countryCode });
      } else {
        form.validateFieldsAndScroll({ scroll: { offsetTop: 115 } });
      }
    });
  };

  return (
    <div className="quiz">
      {testing ? (
        <>
          {loading ? (
            <Spin />
          ) : (
            <>
              <div className="powered-by">
                <PoweredByLmiCollege className="lmi-college-logo" />
              </div>
              <Form colon={false} hideRequiredMark>
                {(answers || questions).map(({ id, question, answers, comment }, index) => (
                  <div key={id} className="quiz-item">
                    <Form.Item label={`${index + 1}. ${question}`}>
                      {form.getFieldDecorator(`${id}`, {
                        rules: [{ required: true, message: 'Question must be answered' }],
                      })(
                        <Radio.Group className="quiz-item-options">
                          {answers.map((item) => (
                            <Radio
                              key={item.id}
                              value={item.id}
                              className={classNames('quiz-item-option', {
                                answered: item.user_answered,
                                correct: item.is_correct,
                                incorrect: !item.is_correct,
                              })}
                            >
                              {item.answer}
                            </Radio>
                          ))}
                        </Radio.Group>
                      )}
                    </Form.Item>
                    {comment && <div className="quiz-item-comment">{`Comment: ${comment}`}</div>}
                  </div>
                ))}
                {questions.length > 0 &&
                  (answers ? (
                    <Button type="primary" onClick={done} className="quiz-btn">
                      Done
                    </Button>
                  ) : (
                    <Button type="primary" onClick={submit} className="quiz-btn">
                      Check your answers
                    </Button>
                  ))}
              </Form>
            </>
          )}
        </>
      ) : (
        <Button type="primary" onClick={start} className="quiz-btn">
          Start test
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  quizId: quizIdSelector(state),
  questions: quizQuestionsSelector(state),
  answers: quizAnswersSelector(state),
  loading: loadingQuizQuestionsSelector(state),
  countryCode: countryCodeSelector(state),
});

const mapDispatchToProps = { submitAnswers };

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Quiz));
