import React from 'react';
import { Row, Col } from 'antd';
import Form from './components/Form/Form';
import Table from './components/Table/Table';
import Notepad from '../../../Note/components/Notepad/Notepad';
import Contact from './components/Contact/Contact';
import notes from './data/notes';

export default () => (
  <div className="calculator component-wrapper">
    <Row gutter={20} type="flex">
      <Col span={24} className="calculator-item">
        <Form />
      </Col>
      <Col xs={24} xl={24} className="calculator-item">
        <Table />
      </Col>
      <Col xs={24} lg={16} xl={18} className="calculator-item">
        <Notepad notes={notes} />
      </Col>
      <Col xs={24} lg={8} xl={6} className="calculator-item">
        <Contact />
      </Col>
    </Row>
  </div>
);
