import feature1 from '../assets/tailoredReport/tailoredFeature1.svg';
import feature2 from '../assets/tailoredReport/tailoredFeature2.svg';
import feature3 from '../assets/tailoredReport/tailoredFeature3.svg';
import feature4 from '../assets/tailoredReport/tailoredFeature4.svg';
import no1 from '../assets/tailoredReport/no1.svg';
import no2 from '../assets/tailoredReport/no2.svg';
import no3 from '../assets/tailoredReport/no3.svg';
import no4 from '../assets/tailoredReport/no4.svg';

export default [{
        key: 1,
        stepImage: no1,
        heading: 'Classify the risks',
        image: feature1,
        description: 'Move the classes easily by drag and drop and categorise them for recommendation, further review or consideration.',
    },
    {
        key: 2,
        stepImage: no2,
        heading: 'Add Comments',
        image: feature2,
        description: 'Leave your own commentary to further tailor your report to your clients needs.',
    },
    {
        key: 3,
        stepImage: no3,
        heading: 'Customise it',
        image: feature3,
        description: "Change logo or contact information and theme. Ask us for custom theme and brand your report under your company's style.",
    },
    {
        key: 4,
        stepImage: no4,
        heading: 'Preview, Print or Save',
        image: feature4,
        description: 'Preview and email to your client or print it out for your next client meeting.',
    },
];