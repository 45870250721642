import React from 'react';
import { Carousel, Typography } from 'antd';
import Icon from '../../../../components/Icon/Icon';
import features from '../../data/features';

const { Paragraph } = Typography;

const props = {
  dots: true,
  speed: 400,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default () => (
  <Carousel {...props} className="home-features-carousel">
    {features.map((item) => (
      <div key={item.title} className="home-features-carousel-item">
        <Icon component={item.icon} size={70} />
        <h4>{item.title}</h4>
        <Paragraph ellipsis={{ rows: 10 }}>{item.description}</Paragraph>
      </div>
    ))}
  </Carousel>
);
