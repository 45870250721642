import React from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import './Hamburger.scss';

export default ({ active, onClick, className }) => (
  <Button
    type="link"
    className={classNames('hamburger', 'hamburger--slider', className, { 'is-active': active })}
    onClick={onClick}
  >
    <span className="hamburger-box">
      <span className="hamburger-inner" />
    </span>
  </Button>
);
