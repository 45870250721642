import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { renderColor } from '../../../../utils';
import './Classification.scss';

const classification = [
  { value: 25, name: 'LOW RISK', min: 0, max: 25 },
  { value: 35, name: 'MEDIUM RISK', min: 26, max: 60 },
  { value: 40, name: 'HIGH RISK', min: 61, max: 100 },
];

export default () => {
  const option = {
    title: {
      show: true,
      text: 'RISK GRADE CLASSIFICATION',
      textStyle: { color: '#003262', fontSize: 16 },
    },
    series: [
      {
        type: 'pie',
        radius: ['30%', '50%'],
        label: {
          color: '#003262',
          fontSize: 10,
          lineHeight: 14,
          formatter: ({ data }) => `${data.name}\n${data.min}-${data.max}%`,
        },
        labelLine: { show: false, length: 4, length2: 4 },
        itemStyle: { color: ({ data }) => renderColor(data.min) },
        data: classification,
      },
    ],
  };
  return (
    <div className="hazard-index-classification card">
      <ReactEcharts option={option} notMerge lazyUpdate />
      <p>
        For each class of insurance, a risk grade known as the LMI Hazard Index is provided as an indication of hazard
        for that industry.
      </p>
      <p>The grading classification used is:</p>
      <ul>
        {classification.map((item) => (
          <li key={item.name}>
            <i style={{ backgroundColor: renderColor(item.min) }} />
            <span>{item.name}</span>
            <span>{`${item.min} - ${item.max}%`}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
