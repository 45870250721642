import React from 'react';
import { Icon } from 'antd';
import './Card.scss';

export default ({ type, open, thumbnail, content }) => (
  <div className="industry-media-card">
    {open ? (
      <>
        {type === 'image' ? (
          <div className="industry-media-card-cover" style={{ backgroundImage: `url('${content}')` }} />
        ) : (
          <video controls autoPlay width="100%" height="100%">
            <source src={content} />
            <track kind="captions" />
          </video>
        )}
      </>
    ) : (
      <>
        <div
          className="industry-media-card-cover"
          style={{ backgroundImage: `url('${type === 'image' ? content : thumbnail}')` }}
        />
        <div className="industry-media-card-mask">
          {type === 'image' ? (
            <Icon type="search" className="industry-media-card-mask-icon" />
          ) : (
            <Icon type="play-circle" className="industry-media-card-mask-icon" />
          )}
        </div>
      </>
    )}
  </div>
);
