import React from 'react';
import classNames from 'classnames';
import './Card.scss';
import logoAnziif from '../../assets/logo-anziif.png';
import logoNiba from '../../assets/logo-niba.png';
import logoLmi from '../../../../assets/images/lmi-logo.png';

const Card = ({ color, textColor, logo, point = 0, description, active, mask = false, onClick }) => (
  <div
    role="menuitem"
    tabIndex="-1"
    className={classNames('card point-card', { active, clickable: !!onClick })}
    style={{ backgroundColor: color }}
    onClick={onClick}
    onKeyDown={(e) => e.preventDefault()}
  >
    <img src={logo} alt="logo" height={50} className="point-card-logo" />
    <h1 style={{ color: textColor }}>{point}</h1>
    <p style={{ color: textColor }}>{description}</p>
    {!!onClick && mask && <div className="point-card-mask" />}
  </div>
);

export const PointCardAnziif = ({ point, active, mask, onClick }) => (
  <Card
    color="#005883"
    textColor="#fff"
    logo={logoAnziif}
    point={point}
    description="CIP Credit Points"
    active={active}
    mask={mask}
    onClick={onClick}
  />
);

export const PointCardNiba = ({ point, active, mask, onClick }) => (
  <Card
    color="#002a5b"
    textColor="#fff"
    logo={logoNiba}
    point={point}
    description="CPD Credit Points"
    active={active}
    mask={mask}
    onClick={onClick}
  />
);

export const PointCardLmi = ({ point }) => (
  <Card color="#fff" logo={logoLmi} point={point} description="LMI Credit Points" />
);
