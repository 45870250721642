import React from 'react';
import { IconFullScreen, IconDollar, IconDemolition } from '../assets/icons';

export default [
  {
    menuTitle: 'Floor Area',
    title: 'The area is based on Gross Floor Area',
    icon: IconFullScreen,
    content:
      'This building cost calculator is based on single building rates provided by Andrew Nock Valuers for the gross floor area of a building. The gross floor area is the total of the fully enclosed covered floor areas of a building over all floor levels measured. Measurements should be based on the external measurements of the building that is including the thickness of all walls. In this building cost-calculator unenclosed covered areas are not included in the gross floor area.',
  },
  {
    menuTitle: 'Cost Rates',
    title: 'Building cost rates per square metre - a disclaimer',
    icon: IconDollar,
    content:
      'Estimated building costs only provide an indicative value of the likely cost of rebuilding a building within that category. While a single rate does provide a reasonable guide in many instances it should not be relied on to set a declared value or sum insured for a building. It should be regarded as a reasonable test only. There are a vast many factors which can influence the true replacement value of building.',
  },
  {
    menuTitle: 'Cost Rates Exceptions',
    title: 'Please note that the building cost rates do not include allowance for the following',
    icon: IconDollar,
    content: (
      <ul>
        <li>Access</li>
        <li>Demolition</li>
        <li>External services outside 3 metres,</li>
        <li>External structures, i.e. balconies, verandahs, covered ways, carports, garages</li>
        <li>Furniture and furnishings</li>
        <li>Goods and Services Tax (GST)</li>
        <li>Heritage issues</li>
        <li>Hoardings</li>
        <li>Lead time for demolition, design, approvals, tender and rebuilding</li>
        <li>Legal and professional fees</li>
        <li>Loose or special equipment</li>
        <li>Plant and equipment not an integral part of the building</li>
        <li>Regional locations</li>
        <li>Site conditions</li>
        <li>Special design features</li>
        <li>Special fixtures, fittings, finishes</li>
        <li>Zoning restrictions</li>
      </ul>
    ),
  },
  {
    menuTitle: 'Estimated Demolition Costs',
    title: 'Demolition Costs',
    icon: IconDemolition,
    webOnly: true,
    content: (
      <p>
        View a table of{' '}
        <a
          href="https://www.lmigroup.com/riskcoach/documents/estimated_demolition_cost.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          estimated demolition costs
        </a>
        .
      </p>
    ),
  },
  {
    title: 'Obtaining an accurate valuation',
    icon: IconDollar,
    content:
      'Contact Andrew Nock Valuers for any obligation and cost free quotations and assistance throughout Australia.',
  },
];
