import {
  REQUEST_QUIZZES,
  RECEIVE_QUIZZES,
  REQUEST_QUIZ_QUESTIONS,
  RECEIVE_QUIZ_QUESTIONS,
  REQUEST_QUIZ_ANSWERS,
  RECEIVE_QUIZ_ANSWERS,
} from './QuizActions';

const initialState = { loadingQuizzes: false, loadingQuizQuestions: false, loadingQuizAnswers: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_QUIZZES:
      return { ...state, loadingQuizzes: true };
    case RECEIVE_QUIZZES:
      return { ...state, loadingQuizzes: false, quizzes: action.quizzes };
    case REQUEST_QUIZ_QUESTIONS:
      return { ...state, loadingQuizQuestions: true, answer: undefined };
    case RECEIVE_QUIZ_QUESTIONS:
      return { ...state, loadingQuizQuestions: false, quiz: action.quiz };
    case REQUEST_QUIZ_ANSWERS:
      return { ...state, loadingQuizAnswers: true };
    case RECEIVE_QUIZ_ANSWERS:
      return { ...state, loadingQuizAnswers: false, answer: action.answer };
    default:
      return state;
  }
};
