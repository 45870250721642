import React, { useState } from 'react';
import { notification, Spin } from 'antd';
import FileSaver from 'file-saver';

export default ({ children, fileName = 'download.pdf', download }) => {
  const [downloading, setDownloading] = useState(false);

  const onDownload = async () => {
    if (downloading) {
      return;
    }
    setDownloading(true);
    notification.open({
      key: 'downloading',
      message: 'Downloading',
      description: 'Thanks for your patience.',
      duration: 0,
      icon: <Spin />,
    });
    try {
      const res = await download();
      const blob = new Blob([res.data], { type: res.headers['content-type'] });
      FileSaver.saveAs(blob, fileName);
      notification.close('downloading');
      notification.success({ key: 'download_success', message: 'Download successfully' });
    } catch (err) {
      notification.close('downloading');
      notification.error({ key: 'download_fail', message: 'Download failed' });
    }
    setDownloading(false);
  };

  return children(onDownload, downloading);
};
